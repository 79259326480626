import React from "react";
import LogoINE from '../../assets/img/logoINE.png';

const Navigation = (props) => {
    return (
        <nav className="navbar navbar-ine">
            <div className="container">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <a className="navbar-brand" href="https://portal.ine.mx/" target="_blank">
                        <img src={LogoINE} alt="Logo INE" />
                    </a>
                </div>
                <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <div className="ligas-nav">
                        <ul className="nav navbar-nav">
                            <li><a className="inicio" href="https://portal.ine.mx/" target="_blank">Inicio</a></li>
                            <li><a href="https://portal.ine.mx/sobre-el-ine/" target="_blank">Sobre el INE</a></li>
                            <li><a href="https://portal.ine.mx/credencial/" target="_blank">Credencial para votar</a></li>
                            <li><a href="https://portal.ine.mx/cultura-civica/" target="_blank">Cultura Cívica</a></li>
                            <li><a href="https://portal.ine.mx/voto-y-elecciones/" target="_blank">Voto y Elecciones</a></li>
                            <li><a href="https://portal.ine.mx/servicios-ine/" target="_blank">Servicios</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
}
export default Navigation;