import React, {useEffect, useState, useRef} from 'react';
import {useParams} from 'react-router';
import {/*Icon,*/ LeftOutlined} from '@ant-design/icons';
import {Table, Row, Col, Button, Card} from 'antd';

import {Link} from 'react-router-dom';

import useBusquedaContext from '../../context/useBusquedaContext';
import Spin from './Spin';
import useDataContext from '../../context/useDataContext';

const ConsultaPresidente = (props) => {
	const [dataCandidatosPresidente, setDataCandidatosPresidente] = useState([]);
	const [consultar, setConsultar] = useState(true);

	const {actoresPoliticos, candidatosPresidente, jsonCargados, actoresPoliticosPresidente} = useDataContext();

	let {idAsociacion, tipoAsociacion} = useParams();
	//console.log('asociaciaciones', idAsociacion, tipoAsociacion, actoresPoliticos);

	const {tipoCandidatosBusqueda} = useBusquedaContext();

	useEffect(() => {
		buscar('1', idAsociacion, tipoAsociacion);
	}, [candidatosPresidente]);

	const buscar = (tipoCandidatura, asociacion, tipoAsociacion) => {
		let candidatosFiltrado;
		if (tipoCandidatura === '1') {
			candidatosFiltrado = candidatosPresidente;
		}

		candidatosFiltrado = candidatosFiltrado.filter((candidato) => candidato.tipoCandidato === tipoCandidatosBusqueda);

		if (asociacion != null && asociacion != undefined && tipoAsociacion != null && tipoAsociacion != undefined) {
			candidatosFiltrado = candidatosFiltrado.filter((candidato) => candidato.idAsociacion == asociacion && candidato.tipoAsociacion == tipoAsociacion);
		} else {
			candidatosFiltrado = [];
		}

		if (tipoCandidatura === '1') {
			setDataCandidatosPresidente(candidatosFiltrado);
		}
		setConsultar(false);
	};

	const columnsBase = [
		{
			title: 'Actor Político',
			align: 'center',
			dataIndex: 'idAsociacion',
			key: 'idAsociacion',
			render: (_, record) => {
				let actorPolitico = actoresPoliticosPresidente.find(
					(actorPolitico) => actorPolitico.idAsociacion == record.idAsociacion && actorPolitico.idTipoAsociacion == record.tipoAsociacion,
				);
				if (actorPolitico != null)
					//console.log("ruta emblema",`${process.env.REACT_APP_API_URL}img/${actorPolitico.emblema}`)
					return (
						<img
							key={`emb-${record.idCandidato}-${actorPolitico.idAsociacion}-${actorPolitico.idTipoAsociacion}`}
							src={`${process.env.REACT_APP_API_URL}img/${actorPolitico.emblema}`}
							className={actorPolitico.idTipoAsociacion == 3 || actorPolitico.idTipoAsociacion == 4 ? 'coalicion' : ''}
							style={
								actorPolitico.idTipoAsociacion == 3 || actorPolitico.idTipoAsociacion == 4
									? {width: '105px', textAlign: 'center', margin: 'auto', display: 'block'}
									: {width: '35px', textAlign: 'center', margin: 'auto', display: 'block'}
							}
						/>
					);
				return <p></p>;
			},
			width: '15%',
		},
		{
			render: (_, record) => {
				return (
					<div /*style={{ textAlign: "left" }}*/>
						<div className="div-FConsulta">
							<div className="FConsulta">¡Consulta!</div>
						</div>
						<Link
							key={record.idCandidato}
							to={{
								pathname: `/detalleCandidato/${record.idCandidato}/${record.idTipoCandidatura}`,
								state: {idCandidato: record.idCandidato, idTipoCandidatura: record.idTipoCandidatura},
							}}
							target="_blank"
							//onClick={() => setConsultar(true)}
						>
							{_}
						</Link>
					</div>
				);
			},

			title: tipoCandidatosBusqueda === 'P' ? 'Propietaria/o' : '',
			align: 'center',
			dataIndex: 'nombreCandidato',
			key: 'nombreCandidato',
			width: '28%',
			//sorter: (a, b) => (a.nombreCandidato.first > b.nombreCandidato.first ? 1 : -1),
		},
	];

	const columnsBasePresidente = [
		/*{
			title: 'Municipio',
			dataIndex: 'nombreMunicipio',
			align: 'center',
			key: 'nombreMunicipio',
			//sorter: (a, b) => (a.idEstadoEleccion.first > b.idEstadoEleccion.first ? 1 : -1),
			//fixed: 'left',
			width: '15%',
		},*/
		...columnsBase,
	];

	const columnSuplente = {
		render: (_, record) => {
			return (
				<div /*style={{ textAlign: "left" }}*/>
					<div className="div-FConsulta">
						<div className="FConsulta">¡Consulta!</div>
					</div>
					<Link
						key={record.idCandidato}
						to={{
							pathname: `/detalleCandidato/${record.idCandidatoSup}/${record.idTipoCandidatura}`,
							state: {idCandidato: record.idCandidatoSup, idTipoCandidatura: record.idTipoCandidatura},
						}}
						target="_blank"
						//onClick={() => setConsultar(true)}
					>
						{_}
					</Link>
				</div>
			);
		},
		title: tipoCandidatosBusqueda === 'P' ? 'Suplente' : 'Propietaria/o',
		align: 'center',
		dataIndex: 'nombreSuplente',
		key: 'nombreSuplente',
		width: '28%',
	};

	const columnsPresidente = [...columnsBasePresidente];

	return (
		<>
			<div className="container">
				<div className="clearfix ">&nbsp;</div>
				<div className="row">
					<div className=" col-xs-12 ">
						<div className="breadcrumb" style={{backgroundColor: 'white'}}>
							<li>Inicio</li>
							<li> Elecciones </li>
							<li>Candidatas y Candidatos, Conóceles</li>
							<li className="active">Ficha candidatura</li>
							<div className="divider"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<Card className="cardResponsabilidadUTTyPDP">
						<Row>
							<Col>
								<span className="alineacion-justificada">
									<span className="gris2-mensaje-consulta">
										{' '}
										Consulta la información
										<span className="negro2-mensaje-consulta"> proporcionada de manera obligatoria</span> por las y los candidatos que
										participan en las contiendas electorales 2024.
										<span className="negro2-mensaje-consulta"> La información es responsabilidad de los actores políticos. </span>
										El Instituto únicamente apoya para su difusión.
									</span>
								</span>
							</Col>
						</Row>
					</Card>
				</div>
			</div>
			<div className="container">
				<div className="clearfix ">&nbsp;</div>
				<Row>
					<Col xs={{span: 12}}>
						<div style={{display: 'flex', justifyContent: 'flex-start'}}>
							<Link to="/">
								<Button key="regresar" type="primary" className="secondary-button">
									<LeftOutlined />
									Nueva búsqueda
								</Button>
							</Link>
						</div>
					</Col>
					<Col xs={{span: 12}}>
						<div style={{display: 'flex', justifyContent: 'flex-end'}}>
							<Link to="/preguntasFrecuentes">
								<Button key="regresar" type="primary" className="descarga-button">
									Preguntas frecuentes
								</Button>
							</Link>
						</div>
					</Col>
				</Row>
			</div>
			{jsonCargados ? (
				<div>
					<div className="container">
						<div id="contenedor-resultados" className="col-xs-12 espaciador-top">
							<div className="row">
								<div className="col-xs-12">
									<p id="titulo-resultados" className="info">
										Resultados para Presidente de la República:
									</p>
								</div>
							</div>
							<Table
								rowKey={(record) => record.idCandidato}
								columns={columnsPresidente}
								dataSource={dataCandidatosPresidente}
								pagination={false}
								scroll={{x: 900}}
								loading={{
									spinning: consultar,
									indicator: <Spin spinning={consultar} />,
								}}
							/>
						</div>
					</div>
				</div>
			) : (
				<div>
					<div id="imprimir" className="container candidatos">
						<div className="clearfix">&nbsp;</div>
						<div id="contenedor-cargando" className="col-xs-12 text-center">
							<Spin spinning={true} />
						</div>
						<div className="clearfix">&nbsp;</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ConsultaPresidente;
