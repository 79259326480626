import React, {useEffect, useState, useRef} from 'react';
import Highcharts from 'highcharts';
import {NodeExpandOutlined, RightOutlined} from '@ant-design/icons';
import {Row, Col, Menu, Divider, Tooltip} from 'antd';
import useDataContext from '../../context/useDataContext';
import moment from 'moment';
import 'moment/locale/es';

const Graphics = (props) => {
	const {
		actoresPoliticos,
		jsonGubernaturaGraficaPorcentaje,
		jsonEstadosGraficaPorcentaje,
		jsonPresidentesGrafica,
		jsonGraficaGradoAcademico,
		jsonGraficaNivelSE,
		jsonGraficaRangoEdad,
		jsonGraficaSexo,
		jsonEtiquetas,
		jsonGraficasCandidatos,
		jsonGraficasPartidos,
		jsonGraficasAccionesAfirmativas,
		jsonDiputadosGraficaPorcentaje,
		jsonSenadoresGraficaPorcentaje,
		jsonPresidenteGraficaPorcentaje,
	} = useDataContext();
	//console.log ("dip",jsonDiputadosGraficaPorcentaje.desglose,
	// "gob",jsonGubernaturaGraficaPorcentaje.desglose,"presi", jsonPresidentesGrafica)

	let totalG = jsonGubernaturaGraficaPorcentaje;
	let totalPM = jsonPresidentesGrafica;
	var fechaCreacion = moment().format('DD-MM-YYYY');
	var fechaActualizacion = moment('04-13-2022').format('DD-MM-YYYY');
	var fechaActualizacion1 = moment('04-18-2022').format('DD-MM-YYYY');
	// console.log ("dip1",totalG, totalPM );

	//    const graficaPresidentes=jsonPresidentesGrafica;
	//  const graficaGubernaturas=jsonGubernaturaGraficaPorcentaje;
	const [anchoDip, setAnchoDip] = useState(1);
	const [anchoDipSen, setAnchoDipSen] = useState(1);
	//const [tabSeleccionada, setTabSeleccionada] = useState('10');
	const [tabSeleccionada, setTabSeleccionada] = useState('17');
	const [actoresPoliticosSMR, setActoresPoliticosSMR] = useState([]);
	const [conteoTotalIdentidad, setConteoTotalIdentidad] = useState(null);
	const [conteoTotal, setConteoTotal] = useState('0');
	const [estado, setEstado] = useState(props.entidad);
	let i = 0;
	var rutaTablasDiputados = 'diputados';
	var rutaTablasPresidentes = 'presidenteMunicipal';
	var rutaTablasGobernadores = 'gubernatura';
	var rutaTablasSenadores = 'senadores';
	var rutaTablasPresidente = 'presidente';

	//console.log("PROPS", props.entidad);

	useEffect(() => {
		/* 	if (props.entidad !== '10' && props.entidad !== '23' && (tabSeleccionada === '1' || tabSeleccionada === '11')) setTabSeleccionada('10');
		if (props.entidad === '10' && tabSeleccionada === '1') setTabSeleccionada('10');
		if (props.entidad === '23' && tabSeleccionada === '11') setTabSeleccionada('10'); */
		//      console.log("ESTADO", props.entidad);
		//console.log(" ---------------- jsonDiputadosGraficaPorcentaje: " + jsonDiputadosGraficaPorcentaje);
		//console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);

		//console.log('jsonPresidenteGraficaPorcentaje:::: ', jsonPresidenteGraficaPorcentaje);
		//console.log('jsonDiputadosGraficaPorcentaje:::: ', jsonDiputadosGraficaPorcentaje);
		//console.log('jsonSenadoresGraficaPorcentaje:::: ', jsonSenadoresGraficaPorcentaje);

		if (
			//((jsonDiputadosGraficaPorcentaje && jsonPresidentesGrafica && jsonEstadosGraficaPorcentaje && jsonGubernaturaGraficaPorcentaje) ||
			//	jsonSenadoresGraficaPorcentaje)
			jsonDiputadosGraficaPorcentaje !== undefined &&
			jsonSenadoresGraficaPorcentaje !== undefined &&
			jsonPresidenteGraficaPorcentaje !== undefined && //tabSeleccionada === '1' ||
			(tabSeleccionada === '10' || tabSeleccionada === '11' || tabSeleccionada === '15' || tabSeleccionada === '16' || tabSeleccionada === '17')
		) {
			//console.log(" ---------------- jsonDiputadosGraficaPorcentajeDIP1: " + jsonDiputadosGraficaPorcentaje.porcentaje.length);
			//console.log(" ---------------- tabSeleccionadaDIP1: " + tabSeleccionada);
			if (tabSeleccionada === '1') {
				setAnchoDip(100 / (jsonDiputadosGraficaPorcentaje.porcentaje.length + 1));
			}
			if (tabSeleccionada === '10') {
				if (props.entidad === null) setAnchoDip(100 / (jsonGubernaturaGraficaPorcentaje.porcentaje.length + 1));
				if (props.entidad === '13') {
					//    console.log("entidad +13" )
					setAnchoDip(100 / (jsonEstadosGraficaPorcentaje.hidalgo.porcentaje.length + 1));
				}
				if (props.entidad === '1') setAnchoDip(100 / (jsonEstadosGraficaPorcentaje.aguascalientes.porcentaje.length + 1));
				if (props.entidad === '10') setAnchoDip(100 / (jsonEstadosGraficaPorcentaje.durango.porcentaje.length + 1));
				if (props.entidad === '20') setAnchoDip(100 / (jsonEstadosGraficaPorcentaje.oaxaca.porcentaje.length + 1));
				if (props.entidad === '23') setAnchoDip(100 / (jsonEstadosGraficaPorcentaje.quintanaroo.porcentaje.length + 1));
				if (props.entidad === '28') setAnchoDip(100 / (jsonEstadosGraficaPorcentaje.tamaulipas.porcentaje.length + 1));
			}
			if (tabSeleccionada === '11') {
				setAnchoDip(100 / (jsonPresidentesGrafica.porcentaje.length + 1));
			}
			if (tabSeleccionada === '15') {
				setAnchoDip(90 / jsonSenadoresGraficaPorcentaje.porcentaje.length);
			}
			if (tabSeleccionada === '16') {
				setAnchoDip(90 / jsonDiputadosGraficaPorcentaje.porcentaje.length);
			}
			if (tabSeleccionada === '17') {
				setAnchoDip(90 / jsonPresidenteGraficaPorcentaje.porcentaje.length);
			}
			{
				//console.log("ancho", anchoDip)
			}
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-rc-diputados',
					type: 'column',
				},
				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					text:
						tabSeleccionada === '1'
							? 'Registro curricular de diputaciones'
							: tabSeleccionada === '10'
							? props.entidad === null
								? 'Registro curricular de gubernaturas'
								: props.entidad === '1'
								? 'Registro curricular gubernatura de Aguascalientes'
								: props.entidad === '10'
								? 'Registro curricular gubernatura de Durango'
								: props.entidad === '13'
								? 'Registro curricular gubernatura de Hidalgo'
								: props.entidad === '20'
								? 'Registro curricular gubernatura de Oaxaca'
								: props.entidad === '23'
								? 'Registro curricular gubernatura de Quintana Roo'
								: props.entidad === '28'
								? 'Registro curricular gubernatura de Tamaulipas'
								: ''
							: tabSeleccionada === '11'
							? 'Registro curricular de presidencias municipales'
							: tabSeleccionada === '15'
							? 'Registro curricular de senadurías'
							: tabSeleccionada === '16'
							? 'Registro curricular de diputaciones'
							: tabSeleccionada === '17'
							? 'Registro curricular de presidencia'
							: '',
				},
				xAxis: {
					type: 'category',
					title: {
						text: props.entidad === '13' || props.entidad === '20' ? 'Actores políticos' : 'Actores políticos',
					},
					labels: {
						enabled: false,
					},
				},
				yAxis: {
					title: {
						text: null,
					},
					min: 0,
					max: 100,
				},
				legend: {
					enabled: false,
				},
				plotOptions: {
					series: {
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							format: '{point.y}',
						},
						point: {
							events: {
								click: function (e) {
									if (this.idEstadoEleccion == undefined) {
										this.idEstadoEleccion = props.entidad;
									}
									if (tabSeleccionada === '1') {
										window.open(rutaTablasDiputados + '/' + this.idAsociacion + '/' + this.idTipoAsociacion);
									}
									if (tabSeleccionada === '10') {
										window.open(
											rutaTablasGobernadores + '/' + this.idAsociacion + '/' + this.idTipoAsociacion + '/' + this.idEstadoEleccion,
										);
									}
									if (tabSeleccionada === '11') {
										window.open(rutaTablasPresidentes + '/' + this.idAsociacion + '/' + this.idTipoAsociacion);
									}
									if (tabSeleccionada === '15') {
										window.open(rutaTablasSenadores + '/' + this.idAsociacion + '/' + this.idTipoAsociacion);
									}
									if (tabSeleccionada === '16') {
										window.open(rutaTablasDiputados + '/' + this.idAsociacion + '/' + this.idTipoAsociacion);
									}
									if (tabSeleccionada === '17') {
										window.open(rutaTablasPresidente + '/' + this.idAsociacion + '/' + this.idTipoAsociacion);
									}
								},
							},
						},
					},
				},
				tooltip: {
					headerFormat: null,
					borderColor: '#A0005F',
					borderRadius: 2,
					pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name:
							tabSeleccionada === '1' && props.entidad === '23'
								? 'Registro curricular de diputaciones'
								: tabSeleccionada === '10'
								? 'Registro curricular de gubernaturas'
								: tabSeleccionada === '11' && props.entidad === '10'
								? 'Registro curricular de presidencias municipales'
								: tabSeleccionada === '15'
								? 'Registro curricular de senadurías'
								: tabSeleccionada === '16'
								? 'Registro curricular de diputaciones'
								: tabSeleccionada === '17'
								? 'Registro curricular de presidente'
								: '',
						colorByPoint: true,
						data:
							tabSeleccionada === '1'
								? jsonDiputadosGraficaPorcentaje.porcentaje
								: tabSeleccionada === '10'
								? props.entidad === null
									? jsonGubernaturaGraficaPorcentaje.porcentaje
									: props.entidad === '1'
									? jsonEstadosGraficaPorcentaje.aguascalientes.porcentaje
									: props.entidad === '10'
									? jsonEstadosGraficaPorcentaje.durango.porcentaje
									: props.entidad === '13'
									? jsonEstadosGraficaPorcentaje.hidalgo.porcentaje
									: props.entidad === '20'
									? jsonEstadosGraficaPorcentaje.oaxaca.porcentaje
									: props.entidad === '23'
									? jsonEstadosGraficaPorcentaje.quintanaroo.porcentaje
									: props.entidad === '28'
									? jsonEstadosGraficaPorcentaje.tamaulipas.porcentaje
									: ''
								: tabSeleccionada === '11'
								? jsonPresidentesGrafica.porcentaje
								: tabSeleccionada === '15'
								? jsonSenadoresGraficaPorcentaje.porcentaje
								: tabSeleccionada === '16'
								? jsonDiputadosGraficaPorcentaje.porcentaje
								: tabSeleccionada === '17'
								? jsonPresidenteGraficaPorcentaje.porcentaje
								: '',
						dataLabels: {
							color: '#000000',
						},
					},
				],
			});
		}
	}, [
		jsonDiputadosGraficaPorcentaje,
		jsonGubernaturaGraficaPorcentaje,
		jsonPresidentesGrafica,
		jsonEstadosGraficaPorcentaje,
		jsonSenadoresGraficaPorcentaje,
		jsonPresidenteGraficaPorcentaje,
		tabSeleccionada,
		props.entidad,
	]);

	// useEffect(() => {
	//     if (jsonSenadoresGraficaPorcentaje && tabSeleccionada === '2') {
	//         let actoresPoliticosSMRTmp = [];
	//         jsonSenadoresGraficaPorcentaje.porcentaje.forEach(element => {
	//             actoresPoliticosSMRTmp = [...actoresPoliticosSMRTmp, element.name];
	//         });
	//         setActoresPoliticosSMR(actoresPoliticosSMRTmp);
	//         setAnchoDipSen(92 / (jsonSenadoresGraficaPorcentaje.porcentaje.length));
	//         Highcharts.chart({
	//             chart: {
	//                 renderTo: 'grafica-rc-senadores',
	//                 type: 'column'
	//             },
	//             title: {
	//                 text: 'Registro curricular de candidaturas para Senaduría Elección Extraordinaria Nayarit'
	//             },
	//             xAxis: {
	//                 type: 'category',
	//                 title: {
	//                     text: 'Partidos Políticos'
	//                 },
	//                 labels: {
	//                     enabled: false
	//                 }
	//             },
	//             yAxis: {
	//                 title: {
	//                     text: null
	//                 },
	//                 min: 0,
	//                 max: 100
	//             },
	//             legend: {
	//                 enabled: false
	//             },
	//             plotOptions: {
	//                 series: {
	//                     borderWidth: 0,
	//                     dataLabels: {
	//                         enabled: true,
	//                         format: '{point.y}'
	//                     },
	//                     point: {
	//                         events: {
	//                             click: function (e) {
	//                                 window.open(rutaTablasSenadores + '/'
	//                                     + this.idAsociacion + '/' + this.idTipoAsociacion);
	//                             }
	//                         }
	//                     }
	//                 }
	//             },
	//             tooltip: {
	//                 headerFormat: null,
	//                 borderColor: '#A0005F',
	//                 borderRadius: 2,
	//                 pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>'
	//             },
	//             credits: {
	//                 enabled: false
	//             },
	//             series: [{
	//                 name: 'Registro curricular de candidaturas para Senaduría Elección Extraordinaria Nayarit',
	//                 colorByPoint: true,
	//                 data: jsonSenadoresGraficaPorcentaje.porcentaje,
	//                 dataLabels: {
	//                     color: '#000000'
	//                 }
	//             }]
	//         });
	//     }
	// }, [jsonSenadoresGraficaPorcentaje, tabSeleccionada]);

	useEffect(() => {
		//console.log(" ---------------- jsonGraficaGradoAcademico: " + jsonGraficaGradoAcademico);
		//console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
		if (jsonGraficaGradoAcademico && tabSeleccionada === '2') {
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-grado-academico',
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie',
				},
				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					text: 'Registro de candidaturas por grado académico',
				},
				tooltip: {
					pointFormat: '<b>{point.y}</b>',
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
					},
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: 'Registro de candidaturas por grado académico',
						colorByPoint: true,
						colors: ['#e76db6', '#a867ee', '#26acb8', '#db2491', '#ff8c8c', '#f3b6da', '#c192f3', '#a8e6eb', '#fcedf6', '#ffdede'],
						data: jsonGraficaGradoAcademico,
					},
				],
			});
		}
	}, [jsonGraficaGradoAcademico, tabSeleccionada]);

	/*  useEffect(() => {
          //console.log(" ---------------- jsonGraficaRangoEdad: " + jsonGraficaRangoEdad);
          //console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
          if (jsonGraficaRangoEdad && tabSeleccionada === '3') {
              Highcharts.chart({
                  chart: {
                      renderTo: 'grafica-rango-edad',
                      plotBackgroundColor: null,
                      plotBorderWidth: null,
                      plotShadow: false,
                      type: 'pie'
                  },
                  title: {
                      text: 'Registro de candidaturas por Rangos de Edad'
                  },
                  tooltip: {
                      pointFormat: '<b>{point.y}</b>'
                  },
                  plotOptions: {
                      pie: {
                          allowPointSelect: true,
                          cursor: 'pointer',
                          dataLabels: {
                              enabled: false
                          },
                          showInLegend: true
                      }
                  },
                  credits: {
                      enabled: false
                  },
                  series: [{
                      name: 'Registro de candidaturas por Grado académico',
                      colorByPoint: true,
                      colors: ['#E782CC', '#D5007F', '#CCCCCC', '#2CAF79', '#36DD99'],
                      data: jsonGraficaRangoEdad,
  
                  }]
              });
          }
      }, [jsonGraficaRangoEdad, tabSeleccionada]);
  */
	useEffect(() => {
		//console.log(" ---------------- jsonGraficaRangoEdad: " + jsonGraficaRangoEdad);
		//console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
		if (jsonGraficaRangoEdad && tabSeleccionada === '3') {
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-rango-edad',
					type: 'column',
				},
				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					text: 'Registro de candidaturas por rangos de edad',
				},
				xAxis: {
					type: 'category',
					title: {
						style: {
							fontFamily: 'Roboto, sans-serif',
							fontSize: 19,
							fontWeight: 500,
							textAlign: 'center',
						},
						text: 'Rangos de edad',
					},
				},
				yAxis: {
					title: {
						text: null,
					},
				},
				legend: {
					enabled: false,
				},
				plotOptions: {
					series: {
						borderWidth: 0,
						dataLabels: {
							enabled: true,
						},
					},
					column: {
						dataLabels: {
							crop: false,
							overflow: 'none',
						},
					},
				},
				tooltip: {
					headerFormat: null,
					pointFormat: '<span style="color:#d5007f">{point.name}</span>: <b>{point.y}</b><br/>',
				},

				credits: {
					enabled: false,
				},
				series: [
					{
						name: 'Registro de candidaturas por rangos de edad',
						colorByPoint: true,
						data: jsonGraficaRangoEdad,
						colors: ['#8FDEE7', '#E782CC', '#D5007F', '#CCCCCC', '#2CAF79', '#36DD99'],
					},
				],
			});
		}
	}, [jsonGraficaRangoEdad, tabSeleccionada]);

	/*useEffect(() => {

        //console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
        if (jsonGraficaSexo && tabSeleccionada === '4') {
            let suma = 0;
            let sumaHM = [];
            let tempH = [];
            let tempM = [];
            let porcentajeH = []
            let porcentajeM = []

            let i = 0
            jsonGraficaSexo.series.map(element => {
                // console.log( " ---------------- elementos: ", i, "+" , element.data.length)
                if (i == 0) {
                    tempH = element.data
                    if (tempH.length < 6)
                        tempH.push(0)
                }

                if (i == 1) {
                    tempM = element.data
                    if (tempM.length < 6)
                        tempM.push(0)
                }

                i = i + 1
            })
            for (let x = 0; x < 6; ++x) {
                sumaHM.push(tempH[x] + tempM[x])
                let pTempH = (tempH[x] * 100 / sumaHM[x]).toFixed(2)
                let pTempM = (tempM[x] * 100 / sumaHM[x]).toFixed(2)

                //   console.log("truncado", pTempH, pTempM)
                porcentajeH.push(parseFloat(pTempH))
                porcentajeM.push(parseFloat(pTempM))
            }

            // console.log(" ---------------- TOTALES: " , sumaHM, " HOMBRES ", porcentajeH, " MUJERES ", porcentajeM )
            i = 0;
            jsonGraficaSexo.series.map(element => {
                //        console.log( " ---------------- elementos1: ", i, "+" , element.data)
                if (i == 0)
                    element.data = porcentajeH
                if (i == 1)
                    element.data = porcentajeM
                i = i + 1
                //        console.log(" ---------------- TOTALES1", i, "**",  element.data)
            })
            Highcharts.chart({
                chart: {
                    renderTo: 'grafica-partidos-politicos',
                    type: 'column'
                },

                title: {
                    style: {

                        fontFamily: "Roboto, sans-serif",
                        fontSize: 19,
                        fontWeight: 500,
                        textAlign: 'center',

                    },
                    text: 'Registro de candidaturas por sexo'
                },

                xAxis: {
                    categories: jsonGraficaSexo.categories
                },

                yAxis: {
                    title: {
                        text: null
                    },
                    min: 0,
                    max: 100

                },


                credits: {
                    enabled: false
                },


                plotOptions: {

                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            format: '{y} %',

                            color: '#000000'


                        },
                    }
                },



                series: jsonGraficaSexo.series,
                colors: ['#D5007F', '#36DD99'],

            });
        }
    }, [jsonGraficaSexo, tabSeleccionada]);
*/
	useEffect(() => {
		if (jsonGraficaSexo && tabSeleccionada === '4') {
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-partidos-politicos',
					type: 'column',
				},

				/* 				title: {
					text: 'Registro de candidaturas por sexo',
				}, */

				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					text: 'Registro de candidaturas por sexo',
				},

				xAxis: {
					categories: jsonGraficaSexo.categories,
				},

				yAxis: {
					title: {
						text: null,
					},
				},

				credits: {
					enabled: false,
				},

				series: jsonGraficaSexo.series,
				colors: ['#FF8C8C', '#DB2491', '#228F99'],
			});
		}
	}, [jsonGraficaSexo, tabSeleccionada]);

	useEffect(() => {
		//console.log(" ---------------- jsonGraficaGradoAcademico: " + jsonGraficaGradoAcademico);
		//console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
		if (jsonGraficaNivelSE && tabSeleccionada === '12') {
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-nivel-ingreso',
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie',
				},
				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					text: 'Del total de candidaturas que respondieron el cuestionario de identidad, las mismas refieren que su nivel de ingresos es:',
				},
				tooltip: {
					pointFormat: '<b></b>',
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
					},
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: 'Registro de candidaturas por nivel de ingreso mensual',
						colorByPoint: true,
						colors: ['#e76db6', '#a867ee', '#26acb8', '#db2491', '#ff8c8c', '#f3b6da', '#c192f3', '#a8e6eb', '#fcedf6', '#ffdede'],
						data: jsonGraficaNivelSE,
					},
				],
			});
		}
	}, [jsonGraficaNivelSE, tabSeleccionada]);

	/*   useEffect(() => {
           //console.log(" ---------------- jsonGraficasCandidatos: " + jsonGraficasCandidatos);
           //console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
           if (jsonGraficasCandidatos && tabSeleccionada === '9') {
               var seriesAcomodo = [];
               jsonGraficasCandidatos.descMigrante.forEach(item => {
                   seriesAcomodo = [
                       ...seriesAcomodo,
                       {
                           name: item.descripcionRespuesta,
                           y: item.porcentaje,
                           conteoTotal: item.conteoTotal,
                           conteoRespuestas: item.conteo
                       }
                   ]
                   if (!conteoTotalIdentidad)
                       setConteoTotalIdentidad(item.conteoTotal);
               })
               Highcharts.chart({
                   chart: {
                       renderTo: 'grafica-candidatura-migrante',
                       plotBackgroundColor: null,
                       plotBorderWidth: null,
                       plotShadow: false,
                       type: 'pie'
                   },
                   title: {
                       //text: 'Candidaturas que se identifican como migrantes'
                       text: 'Del total de las candidaturas que respondieron el cuestionario identidad, se identifican como migrantes:'
                   },
   
                   plotOptions: {
                       pie: {
                           
                           allowPointSelect: true,
                           cursor: 'pointer',
                           dataLabels: {
                               enabled: true,
                               format: '{point.y}%'
                           },
                           showInLegend: true
                       }
                   },
                   
                 
                   tooltip: {
                       headerFormat: null,
                       borderColor: '#A0005F',
                       borderRadius: 2,
                       //pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
                       pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} de {point.conteoTotal}</b><br/>'
                   },
                   credits: {
                       enabled: false
                   },
                   series: [{
                       name: 'Registro de candidaturas MIGRANTES',
                   colorByPoint: true,
                   colors: ['#E782CC', '#CCCCCC', '#36DD99', '#A0005F', '#2CAF79', '#D5007F', '#8140A1'],
                       data: seriesAcomodo
                   }]
               });
           }
       }, [jsonGraficasCandidatos, tabSeleccionada]);
   
   */
	/*migrante*/
	useEffect(() => {
		//console.log(" ---------------- jsonGraficasCandidatos: ",  jsonGraficasCandidatos);
		// //console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);

		if (jsonGraficasCandidatos && tabSeleccionada === '9' && jsonGraficasCandidatos.descMigrante) {
			var seriesAcomodo = [];
			var tempTotal = 0;
			jsonGraficasCandidatos.descMigrante.forEach((item) => {
				seriesAcomodo = [
					...seriesAcomodo,
					{
						name: item.descripcionRespuesta,
						y: item.porcentaje,
						conteoTotal: item.conteoTotal,
						conteoRespuestas: item.conteo,
					},
				];

				if (jsonGraficasCandidatos.descDiscapacidad.length <= 3) {
					if (item.descripcionRespuesta === 'Sí') tempTotal = item.conteo;
				}

				if (tempTotal > 0) setConteoTotal(tempTotal);
				else setConteoTotal('0');

				if (!conteoTotalIdentidad || conteoTotalIdentidad != item.conteoTotal) setConteoTotalIdentidad(item.conteoTotal);
			});
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-candidatura-migrante',
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie',
				},
				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					//text: ' Del total de las candidaturas que respondieron el cuestionario identidad, se identifican como migrantes:',
					text: 'Candidaturas que se autoidentifican como personas migrantes',
				},

				tooltip: {
					headerFormat: null,
					borderColor: '#A0005F',
					borderRadius: 2,
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} de {point.conteoTotal}</b><br/>',
					pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} </b><br/>',
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
					},
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: 'Candidaturas migrantes',
						//colorByPoint: true,
						//colors: ['#E782CC', '#CCCCCC', '#36DD99'],
						colors: ['#7CB5EC', '#5254bf', '#09df6f'],
						data: seriesAcomodo,
					},
				],
			});
		}
	}, [jsonGraficasCandidatos, tabSeleccionada, conteoTotalIdentidad, conteoTotal]);

	useEffect(() => {
		//console.log(" ---------------- jsonGraficasPartidos:++ " + jsonGraficasPartidos);
		//console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
		if (jsonGraficasPartidos && tabSeleccionada === '9' && jsonGraficasPartidos.descMigrante) {
			var seriesAcomodo = [];
			var conteoTotal = null;
			jsonGraficasPartidos.descMigrante.forEach((item) => {
				//let porcentaje=parseFloat(item.porcentaje.toFixed(2));
				//console.log("porcentaje", item.porcentaje.toFixed(2));
				seriesAcomodo = [
					...seriesAcomodo,
					{
						name: item.siglas,
						//      y: porcentaje,
						y: item.porcentaje,
						conteoTotal: item.conteoTotal,
						conteoRespuestas: item.conteoRespuestas,
						nombreAsociacion: item.nombreAsociacion,
					},
				];
				if (!conteoTotal) conteoTotal = item.conteoTotal;
				if (conteoTotal == undefined) conteoTotal = 0;
			});

			Highcharts.chart({
				chart: {
					renderTo: 'grafica-barras-migrante',
					type: 'bar',
				},
				colors: ['#D5007F'],
				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					//text: 'Candidaturas que se identifican como migrantes por partido'
					//text: 'Del total de las candidaturas que se identificaron como migrantes, se presentan los siguientes porcentajes:'
					//text: `De las ${conteoTotalIdentidad} (100%) candidaturas que respondieron el cuestionario de identidad, ${conteoTotal} se identificaron como migrantes, se presentan los siguientes porcentajes:`
					text: `Desagregado por actor político.`,
				},
				xAxis: {
					type: 'category',
					title: {
						text:
							/* 							props.entidad === '13' || props.entidad === '20' || props.entidad === '28'
								? 'Partidos Políticos, Coaliciones, Candidaturas Independientes y Candidaturas Comunes'
								: 'Partidos Políticos, Coaliciones y Candidaturas Independientes', */
							'Partidos Políticos',
					},
					labels: {
						enabled: false,
					},
					labels: {
						useHTML: true,
						formatter: function (item) {
							if (item.value === 'INDEPENDIENTE') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/CI.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PAN') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PAN.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PRI') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PRI.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PRD') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PRD.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PT') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PT.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'MORENA') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/MORENA.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PVEM') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PVEM.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'MOVIMIENTO CIUDADANO') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/MV.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'FUERZA Y CORAZON POR MEXICO') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/FYCM.png" height="20px" width="50px"></img>`;
							} else if (item.value === 'SIGAMOS HACIENDO HISTORIA') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/SHH.png" height="20px" width="50px"></img>`;
							}
						},
					},
				},
				yAxis: {
					title: {
						//text: 'Porcentaje de captura',
						text: '',
					},
					/* 					min: 0,
					max: 100, */
					min: null,
					max: null,
					labels: {
						enabled: false,
					},
				},
				legend: {
					enabled: false,
				},
				plotOptions: {
					series: {
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							//format: '{point.conteoRespuestas} de {point.conteoTotal}',
							format: '{point.conteoRespuestas}',
							//format: '{point.y}%',
						},
					},
				},
				tooltip: {
					headerFormat: null,
					borderColor: '#A0005F',
					borderRadius: 2,
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.y}%</b><br/>',
					pointFormat:
						//'<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.conteoRespuestas} de {point.conteoTotal}</b><br/>',
						'<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.conteoRespuestas}</b><br/>',
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: 'Candidaturas que se identifican como migrantes por partido',
						colorByPoint: true,
						data: seriesAcomodo,
					},
				],
			});
		}
	}, [jsonGraficasPartidos, tabSeleccionada, conteoTotalIdentidad, props.entidad]);

	/* useEffect(() => {
         //console.log(" ---------------- jsonGraficasCandidatos: " + jsonGraficasCandidatos);
         //console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
         if (jsonGraficasCandidatos && tabSeleccionada === '6') {
             var seriesAcomodo = [];
             jsonGraficasCandidatos.descDiscapacidad.forEach(item => {
                 seriesAcomodo = [
                     ...seriesAcomodo,
                     {
                         name: item.descripcionRespuesta,
                         y: item.porcentaje,
                         conteoTotal: item.conteoTotal,
                         conteoRespuestas: item.conteo
                     }
                 ]
                 if (!conteoTotalIdentidad)
                     setConteoTotalIdentidad(item.conteoTotal);
             })
             Highcharts.chart({
                 chart: {
                     renderTo: 'grafica-candidatura-discapacidad',
                     plotBackgroundColor: null,
                     plotBorderWidth: null,
                     plotShadow: false,
                     type: 'pie'
                 },
                 title: {
                     //text: 'Candidaturas que se identifican con alguna discapacidad'
                     text: 'Del total de las candidaturas que respondieron el cuestionario identidad, se identifican con alguna discapacidad:'
                 },
 
                 plotOptions: {
                     pie: {
                         innerSize: 150,
                         allowPointSelect: true,
                         //cursor: 'pointer',
                         dataLabels: {
                             enabled: true,
                             format: '{point.y}%'
                         },
                         showInLegend: true
                     }
                 },
                 tooltip: {
                     headerFormat: null,
                     borderColor: '#A0005F',
                     borderRadius: 2,
                     //pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
                     pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} de {point.conteoTotal}</b><br/>'
                 },
                 credits: {
                     enabled: false
                 },
                 series: [{
                     name: '',
                     data: seriesAcomodo
                 }]
             });
         }
     }, [jsonGraficasCandidatos, tabSeleccionada]);
 */

	/*discapacidad*/
	useEffect(() => {
		//console.log(" ---------------- jsonGraficasCandidatos: " + jsonGraficasCandidatos);
		//console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
		if (jsonGraficasCandidatos && tabSeleccionada === '6' && jsonGraficasCandidatos.descDiscapacidad) {
			var seriesAcomodo = [];
			var tempTotal = 0;
			jsonGraficasCandidatos.descDiscapacidad.forEach((item) => {
				seriesAcomodo = [
					...seriesAcomodo,
					{
						name: item.descripcionRespuesta,
						y: item.porcentaje,
						conteoTotal: item.conteoTotal,
						conteoRespuestas: item.conteo,
					},
				];
				//console.log("descDiscapacidad.length", jsonGraficasCandidatos.descDiscapacidad.length)

				if (jsonGraficasCandidatos.descDiscapacidad.length <= 3) {
					if (item.descripcionRespuesta === 'Sí') tempTotal = item.conteo;
				}

				if (tempTotal > 0) setConteoTotal(tempTotal);
				else setConteoTotal('0');

				if (!conteoTotalIdentidad || conteoTotalIdentidad != item.conteoTotal) setConteoTotalIdentidad(item.conteoTotal);
			});
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-candidatura-discapacidad',
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie',
				},
				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					//text: 'Del total de las candidaturas que respondieron el cuestionario identidad, se identifican con alguna discapacidad:',
					text: 'Candidaturas que se autoidentifican como personas con discapacidad',
				},

				tooltip: {
					headerFormat: null,
					borderColor: '#A0005F',
					borderRadius: 2,
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} de {point.conteoTotal}</b><br/>',
					pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} </b><br/>',
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
					},
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: 'Candidaturas por discapacidad ',
						//colorByPoint: true,
						//colors: ['#E782CC', '#CCCCCC', '#36DD99'],
						colors: ['#7CB5EC', '#5254bf', '#09df6f'],
						data: seriesAcomodo,
					},
				],
			});
		}
	}, [jsonGraficasCandidatos, tabSeleccionada, conteoTotalIdentidad, conteoTotal]);

	useEffect(() => {
		//console.log(" ---------------- jsonDiputadosGraficaPorcentaje: " + jsonDiputadosGraficaPorcentaje);
		//console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
		if (jsonGraficasPartidos && tabSeleccionada === '6' && jsonGraficasPartidos.descDiscapacidad) {
			var seriesAcomodo = [];
			var conteoTotal = null;
			jsonGraficasPartidos.descDiscapacidad.forEach((item) => {
				//let porcentaje=parseFloat(item.porcentaje.toFixed(2));
				seriesAcomodo = [
					...seriesAcomodo,
					{
						name: item.siglas,
						// y: porcentaje,
						y: item.porcentaje,
						conteoTotal: item.conteoTotal,
						conteoRespuestas: item.conteoRespuestas,
						nombreAsociacion: item.nombreAsociacion,
					},
				];
				if (!conteoTotal) conteoTotal = item.conteoTotal;
				if (conteoTotal == undefined) conteoTotal = 0;
			});
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-barras-discapacidad',
					type: 'bar',
				},
				colors: ['#D5007F'],
				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					//text: 'Candidaturas que se identifican con alguna discapacidad por partido'
					//text: 'Del total de las candidaturas que se identificaron con alguna discapacidad, se presentan los siguientes porcentajes:'
					//text: `De las ${conteoTotalIdentidad} (100%) candidaturas que respondieron el cuestionario de identidad, ${conteoTotal} se identificaron con alguna discapacidad, se presentan los siguientes porcentajes:`
					text: `Desagregado por actor político.`,
				},
				xAxis: {
					type: 'category',
					title: {
						text:
							/* 							props.entidad === '13' || props.entidad === '20' || props.entidad === '28'
								? 'Partidos Políticos, Coaliciones, Candidaturas Independientes y Candidaturas Comunes'
								: 'Partidos Políticos, Coaliciones y Candidaturas Independientes', */
							'Partidos Políticos',
					},
					labels: {
						enabled: false,
					},
					labels: {
						useHTML: true,
						formatter: function (item) {
							if (item.value === 'INDEPENDIENTE') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/CI.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PAN') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PAN.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PRI') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PRI.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PRD') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PRD.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PT') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PT.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'MORENA') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/MORENA.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PVEM') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PVEM.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'MOVIMIENTO CIUDADANO') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/MV.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'FUERZA Y CORAZON POR MEXICO') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/FYCM.png" height="20px" width="50px"></img>`;
							} else if (item.value === 'SIGAMOS HACIENDO HISTORIA') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/SHH.png" height="20px" width="50px"></img>`;
							}
						},
					},
				},
				yAxis: {
					title: {
						//text: 'Porcentaje de captura',
						text: '',
					},
					/* 					min: 0,
					max: 100, */
					min: null,
					max: null,
					labels: {
						enabled: false,
					},
				},
				legend: {
					enabled: false,
				},
				plotOptions: {
					series: {
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							//format: '{point.conteoRespuestas} de {point.conteoTotal}',
							//format: '{point.y}%',
							format: '{point.conteoRespuestas}',
						},
					},
				},
				tooltip: {
					headerFormat: null,
					borderColor: '#A0005F',
					borderRadius: 2,
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.y}%</b><br/>',
					pointFormat:
						//'<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.conteoRespuestas} de {point.conteoTotal}</b><br/>',
						'<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.conteoRespuestas}</b><br/>',
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: 'Candidaturas que se identifican con alguna discapacidad por partido',
						colorByPoint: true,
						data: seriesAcomodo,
					},
				],
			});
		}
	}, [jsonGraficasPartidos, tabSeleccionada, conteoTotalIdentidad, props.entidad]);

	/*    useEffect(() => {
            //console.log(" ---------------- jsonGraficasCandidatos: " + jsonGraficasCandidatos);
            //console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
            if (jsonGraficasCandidatos && tabSeleccionada === '7') {
                var seriesAcomodo = [];
                jsonGraficasCandidatos.descAfromexicana.forEach(item => {
                    seriesAcomodo = [
                        ...seriesAcomodo,
                        {
                            name: item.descripcionRespuesta,
                            y: item.porcentaje,
                            conteoTotal: item.conteoTotal,
                            conteoRespuestas: item.conteo
                        }
                    ]
                    if (!conteoTotalIdentidad)
                        setConteoTotalIdentidad(item.conteoTotal);
                })
                Highcharts.chart({
                    chart: {
                        renderTo: 'grafica-candidatura-afromexicanas',
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie'
                    },
                    title: {
                        //text: 'Candidaturas que se identifican como afromexicanas'
                        text: 'Del total de las candidaturas que respondieron el cuestionario identidad, se identifican como afromexicanas:'
                    },
    
                    plotOptions: {
                        pie: {
                            innerSize: 150,
                            allowPointSelect: true,
                            //cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '{point.y}%'
                            },
                            showInLegend: true
                        }
                    },
                    tooltip: {
                        headerFormat: null,
                        borderColor: '#A0005F',
                        borderRadius: 2,
                        //pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
                        pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} de {point.conteoTotal}</b><br/>'
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: '',
                        data: seriesAcomodo
                    }]
                });
            }
        }, [jsonGraficasCandidatos, tabSeleccionada]);
    */
	/*afromexicanas*/
	useEffect(() => {
		//console.log(" ---------------- jsonGraficasCandidatos: " + jsonGraficasCandidatos);
		//console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
		if (jsonGraficasCandidatos && tabSeleccionada === '7' && jsonGraficasCandidatos.descAfromexicana) {
			var seriesAcomodo = [];
			var tempTotal = 0;
			//   console.log("tamaño",jsonGraficasCandidatos.descAfromexicana.length)
			jsonGraficasCandidatos.descAfromexicana.forEach((item) => {
				seriesAcomodo = [
					...seriesAcomodo,
					{
						name: item.descripcionRespuesta,
						y: item.porcentaje,
						conteoTotal: item.conteoTotal,
						conteoRespuestas: item.conteo,
					},
				];

				if (jsonGraficasCandidatos.descDiscapacidad.length <= 3) {
					if (item.descripcionRespuesta === 'Sí') tempTotal = item.conteo;
				}

				if (tempTotal > 0) setConteoTotal(tempTotal);
				else setConteoTotal('0');

				if (!conteoTotalIdentidad || conteoTotalIdentidad != item.conteoTotal) setConteoTotalIdentidad(item.conteoTotal);
			});
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-candidatura-afromexicanas',
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie',
				},
				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					//text: 'Del total de las candidaturas que respondieron el cuestionario identidad, se identifican como afromexicanas:',
					text: 'Candidaturas que se autoidentifican como personas afromexicanas',
				},
				tooltip: {
					headerFormat: null,
					borderColor: '#A0005F',
					borderRadius: 2,
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} de {point.conteoTotal}</b><br/>',
					pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} </b><br/>',
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
					},
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: 'Candidaturas afromexicanas',
						//colorByPoint: true,
						//colors: ['#E782CC', '#CCCCCC', '#36DD99'],
						colors: ['#7CB5EC', '#5254bf', '#09df6f'],
						data: seriesAcomodo,
					},
				],
			});
		}
	}, [jsonGraficasCandidatos, tabSeleccionada, conteoTotalIdentidad, conteoTotal]);

	useEffect(() => {
		//console.log(" ---------------- jsonGraficasPartidos: " + jsonGraficasPartidos);
		//console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
		if (jsonGraficasPartidos && tabSeleccionada === '7' && jsonGraficasCandidatos.descAfromexicana) {
			var seriesAcomodo = [];
			var conteoTotal = null;
			jsonGraficasPartidos.descAfromexicana.forEach((item) => {
				seriesAcomodo = [
					...seriesAcomodo,
					{
						name: item.siglas,
						y: item.porcentaje,
						conteoTotal: item.conteoTotal,
						conteoRespuestas: item.conteoRespuestas,
						nombreAsociacion: item.nombreAsociacion,
					},
				];
				if (!conteoTotal) conteoTotal = item.conteoTotal;
				if (conteoTotal == undefined) conteoTotal = 0;
			});
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-barras-afromexicanas',
					type: 'bar',
				},
				colors: ['#D5007F'],
				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					//text: 'Candidaturas que se identifican como afromexicanas por partido'
					//text: 'Del total de las candidaturas que se identificaron como afromexicanas, se presentan los siguientes porcentajes:'
					//text: `De las ${conteoTotalIdentidad} (100%) candidaturas que respondieron el cuestionario de identidad, ${conteoTotal} se identificaron como afromexicanas, se presentan los siguientes porcentajes:`
					text: `Desagregado por actor político.`,
				},
				xAxis: {
					type: 'category',
					title: {
						text:
							/* 							props.entidad === '13' || props.entidad === '20' || props.entidad === '28'
								? 'Partidos Políticos, Coaliciones, Candidaturas Independientes y Candidaturas Comunes'
								: 'Partidos Políticos, Coaliciones y Candidaturas Independientes', */
							'Partidos Políticos',
					},
					labels: {
						enabled: false,
					},
					labels: {
						useHTML: true,
						formatter: function (item) {
							if (item.value === 'INDEPENDIENTE') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/CI.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PAN') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PAN.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PRI') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PRI.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PRD') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PRD.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PT') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PT.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'MORENA') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/MORENA.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PVEM') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PVEM.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'MOVIMIENTO CIUDADANO') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/MV.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'FUERZA Y CORAZON POR MEXICO') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/FYCM.png" height="20px" width="50px"></img>`;
							} else if (item.value === 'SIGAMOS HACIENDO HISTORIA') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/SHH.png" height="20px" width="50px"></img>`;
							}
						},
					},
				},
				yAxis: {
					title: {
						//text: 'Porcentaje de captura',
						text: '',
					},
					/* 					min: 0,
					max: 100, */
					min: null,
					max: null,
					labels: {
						enabled: false,
					},
				},
				legend: {
					enabled: false,
				},
				plotOptions: {
					series: {
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							//format: '{point.conteoRespuestas} de {point.conteoTotal}',
							//format: '{point.y}%',
							format: '{point.conteoRespuestas}',
						},
					},
				},
				tooltip: {
					headerFormat: null,
					borderColor: '#A0005F',
					borderRadius: 2,
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.y}%</b><br/>',
					pointFormat:
						//'<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.conteoRespuestas} de {point.conteoTotal}</b><br/>',
						'<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.conteoRespuestas}</b><br/>',
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: 'Candidaturas que se identifican como afromexicanas por partido',
						colorByPoint: true,
						data: seriesAcomodo,
					},
				],
			});
		}
	}, [jsonGraficasPartidos, tabSeleccionada, conteoTotalIdentidad, props.entidad]);

	/*indígenas*/
	useEffect(() => {
		//console.log(" ---------------- jsonGraficasCandidatos************: " + jsonGraficasCandidatos);
		//console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
		if (jsonGraficasCandidatos && tabSeleccionada === '5' && jsonGraficasCandidatos.descIndigena) {
			var seriesAcomodo = [];
			var tempTotal = 0;
			jsonGraficasCandidatos.descIndigena.forEach((item) => {
				seriesAcomodo = [
					...seriesAcomodo,
					{
						name: item.descripcionRespuesta,
						y: item.porcentaje,
						conteoTotal: item.conteoTotal,
						conteoRespuestas: item.conteo,
					},
				];
				if (jsonGraficasCandidatos.descDiscapacidad.length <= 3) {
					if (item.descripcionRespuesta === 'Sí') tempTotal = item.conteo;
				}

				if (tempTotal > 0) setConteoTotal(tempTotal);
				else setConteoTotal('0');

				if (!conteoTotalIdentidad || conteoTotalIdentidad != item.conteoTotal) setConteoTotalIdentidad(item.conteoTotal);
			});
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-candidatura-indigenas',
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie',
				},

				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					text: 'Candidaturas que se autoidentifican como personas indígenas',
					//text: 'Del total de las candidaturas que respondieron el cuestionario identidad, se identifican como personas indígenas:',
				},

				tooltip: {
					headerFormat: null,
					borderColor: '#A0005F',
					borderRadius: 2,
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} de {point.conteoTotal}</b><br/>',
					pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} </b><br/>',
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
					},
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: 'Candidaturas indígenas',
						//colorByPoint: true,
						colors: ['#7CB5EC', '#5254bf', '#09df6f'],
						data: seriesAcomodo,
					},
				],
			});
		}
	}, [jsonGraficasCandidatos, tabSeleccionada, conteoTotalIdentidad, conteoTotal]);

	useEffect(() => {
		//console.log(" ---------------- jsonGraficasPartidos: " + jsonGraficasPartidos);
		//console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
		if (jsonGraficasPartidos && tabSeleccionada === '5' && jsonGraficasPartidos.descIndigena) {
			var seriesAcomodo = [];
			var conteoTotal = null;
			jsonGraficasPartidos.descIndigena.forEach((item) => {
				seriesAcomodo = [
					...seriesAcomodo,
					{
						name: item.siglas,
						y: item.porcentaje,
						conteoTotal: item.conteoTotal,
						conteoRespuestas: item.conteoRespuestas,
						nombreAsociacion: item.nombreAsociacion,
					},
				];
				if (!conteoTotal) conteoTotal = item.conteoTotal;
				if (conteoTotal == undefined) conteoTotal = 0;
			});
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-barras-indigenas',
					type: 'bar',
				},
				colors: ['#D5007F'],

				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					//text: 'Candidaturas que se identifican como indígenas'
					//text: `De las ${conteoTotalIdentidad} (100%) candidaturas que respondieron el cuestionario de identidad, ${conteoTotal} se identificaron como personas indígenas, se presentan los siguientes porcentajes:`
					text: `Desagregado por actor político.`,
				},

				xAxis: {
					type: 'category',
					title: {
						text:
							/* 							props.entidad === '13' || props.entidad === '20' || props.entidad === '28'
								? 'Partidos Políticos, Coaliciones, Candidaturas Independientes y Candidaturas Comunes'
								: 'Partidos Políticos, Coaliciones y Candidaturas Independientes', */
							'Partidos Políticos',
					},
					labels: {
						enabled: false,
					},
					labels: {
						useHTML: true,
						formatter: function (item) {
							if (item.value === 'INDEPENDIENTE') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/CI.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PAN') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PAN.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PRI') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PRI.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PRD') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PRD.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PT') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PT.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'MORENA') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/MORENA.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PVEM') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PVEM.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'MOVIMIENTO CIUDADANO') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/MV.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'FUERZA Y CORAZON POR MEXICO') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/FYCM.png" height="20px" width="50px"></img>`;
							} else if (item.value === 'SIGAMOS HACIENDO HISTORIA') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/SHH.png" height="20px" width="50px"></img>`;
							}
						},
					},
				},
				yAxis: {
					title: {
						//text: 'Porcentaje de captura',
						text: '',
					},
					/* 					min: 0,
					max: 100, */
					min: null,
					max: null,
					labels: {
						enabled: false,
					},
				},
				legend: {
					enabled: false,
				},
				plotOptions: {
					series: {
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							//format: '{point.conteoRespuestas} de {point.conteoTotal}',
							format: '{point.conteoRespuestas}',
							//format: '{point.y}%',
						},
					},
				},
				tooltip: {
					headerFormat: null,
					borderColor: '#A0005F',
					borderRadius: 2,
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.y}%</b><br/>',
					pointFormat:
						//'<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.conteoRespuestas} de {point.conteoTotal}</b><br/>',
						'<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.conteoRespuestas}</b><br/>',
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: 'Candidaturas que se identifican como indígenas por partido',
						colorByPoint: true,
						data: seriesAcomodo,
					},
				],
			});
		}
	}, [jsonGraficasPartidos, tabSeleccionada, conteoTotalIdentidad, props.entidad]);

	/*
        /*diversidad*/
	/*   useEffect(() => {
           //console.log(" ---------------- jsonGraficasCandidatos: " + jsonGraficasCandidatos);
           //console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
           if (jsonGraficasCandidatos && tabSeleccionada === '8') {
               var seriesAcomodo = [];
               jsonGraficasCandidatos.descDiversidadSexual.forEach(item => {
                   seriesAcomodo = [
                       ...seriesAcomodo,
                       {
                           name: item.descripcionRespuesta,
                           y: item.porcentaje,
                           conteoTotal: item.conteoTotal,
                           conteoRespuestas: item.conteo
                       }
                   ]
                   if (!conteoTotalIdentidad)
                       setConteoTotalIdentidad(item.conteoTotal);
               })
               Highcharts.chart({
                   chart: {
                       renderTo: 'grafica-candidatura-diversidad',
                       plotBackgroundColor: null,
                       plotBorderWidth: null,
                       plotShadow: false,
                       type: 'pie'
                   },
                   title: {
                       //text: 'Candidaturas que se identifican como parte de la comunidad LGBTTTIQ+'
                       text: 'Del total de las candidaturas que respondieron el cuestionario identidad, se identifican como parte de la comunidad LGBTTTIQ+:'
                   },
   
                   plotOptions: {
                       pie: {
                           innerSize: 150,
                           allowPointSelect: true,
                           //cursor: 'pointer',
                           dataLabels: {
                               enabled: true,
                               format: '{point.y}%'
                           },
                           showInLegend: true
                       }
                   },
                   tooltip: {
                       headerFormat: null,
                       borderColor: '#A0005F',
                       borderRadius: 2,
                       //pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
                       pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} de {point.conteoTotal}</b><br/>'
                   },
                   credits: {
                       enabled: false
                   },
                   series: [{
                       name: '',
                       data: seriesAcomodo
                   }]
               });
           }
       }, [jsonGraficasCandidatos, tabSeleccionada]);
   */

	useEffect(() => {
		//console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
		if (jsonGraficasCandidatos && tabSeleccionada === '8' && jsonGraficasCandidatos.descDiversidadSexual) {
			// console.log(" ---------------- jsonGraficasCandidatos1: " + jsonGraficasCandidatos.descDiversidadSexual);
			var seriesAcomodo = [];
			var tempTotal = 0;
			jsonGraficasCandidatos.descDiversidadSexual.forEach((item) => {
				//   console.log(" ---------------- jsonGraficasCandidatos2: ", item.descripcionRespuesta);
				seriesAcomodo = [
					...seriesAcomodo,
					{
						name: item.descripcionRespuesta,
						y: item.porcentaje,
						conteoTotal: item.conteoTotal,
						conteoRespuestas: item.conteo,
					},
				];
				if (jsonGraficasCandidatos.descDiscapacidad.length <= 3) {
					if (item.descripcionRespuesta === 'Sí') tempTotal = item.conteo;
				}

				if (tempTotal > 0) setConteoTotal(tempTotal);
				else setConteoTotal('0');

				if (!conteoTotalIdentidad || conteoTotalIdentidad != item.conteoTotal) setConteoTotalIdentidad(item.conteoTotal);
			});
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-candidatura-diversidad',
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie',
				},
				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					//text: 'Del total de las candidaturas que respondieron el cuestionario identidad, se identifican como parte de la comunidad LGBTTTIQ+:',
					text: 'Candidaturas que se autoidentifican como personas que forman parte de la comunidad LGBTTTIQ+',
				},

				tooltip: {
					headerFormat: null,
					borderColor: '#A0005F',
					borderRadius: 2,
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} de {point.conteoTotal}</b><br/>',
					pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} </b><br/>',
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
					},
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: 'Candidaturas diversidad',
						// colorByPoint: true,
						//colors: ['#E782CC', '#CCCCCC', '#36DD99'],
						colors: ['#7CB5EC', '#5254bf', '#09df6f'],
						data: seriesAcomodo,
					},
				],
			});
		}
	}, [jsonGraficasCandidatos, tabSeleccionada, conteoTotalIdentidad, conteoTotal]);

	useEffect(() => {
		if (jsonGraficasPartidos && tabSeleccionada === '8' && jsonGraficasPartidos.descDiversidadSexual) {
			var seriesAcomodo = [];
			var conteoTotal = null;
			jsonGraficasPartidos.descDiversidadSexual.forEach((item) => {
				seriesAcomodo = [
					...seriesAcomodo,
					{
						name: item.siglas,
						y: item.porcentaje,
						conteoTotal: item.conteoTotal,
						conteoRespuestas: item.conteoRespuestas,
						nombreAsociacion: item.nombreAsociacion,
					},
				];
				if (!conteoTotal) conteoTotal = item.conteoTotal;
				if (conteoTotal == undefined) conteoTotal = 0;
			});
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-barras-diversidad',
					type: 'bar',
				},
				colors: ['#D5007F'],
				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					//text: 'Candidaturas que se identifican como parte de la comunidad LGBTTTIQ+ por partido'
					//text: 'Del total de las candidaturas que se identificaron como parte de la comunidad LGBTTTIQ+, se presentan los siguientes porcentajes:'
					//text: `De las ${conteoTotalIdentidad} (100%) candidaturas que respondieron el cuestionario de identidad, ${conteoTotal} se identificaron como parte de la comunidad LGBTTTIQ+, se presentan los siguientes porcentajes:`
					text: `Desagregado por actor político.`,
				},
				xAxis: {
					type: 'category',
					title: {
						text:
							/* 							props.entidad === '13' || props.entidad === '20' || props.entidad === '28'
								? 'Partidos Políticos, Coaliciones, Candidaturas Independientes y Candidaturas Comunes'
								: 'Partidos Políticos, Coaliciones y Candidaturas Independientes', */
							'Partidos Políticos',
					},
					labels: {
						enabled: false,
					},
					labels: {
						useHTML: true,
						formatter: function (item) {
							if (item.value === 'INDEPENDIENTE') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/CI.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PAN') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PAN.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PRI') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PRI.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PRD') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PRD.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PT') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PT.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'MORENA') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/MORENA.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PVEM') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PVEM.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'MOVIMIENTO CIUDADANO') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/MV.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'FUERZA Y CORAZON POR MEXICO') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/FYCM.png" height="20px" width="50px"></img>`;
							} else if (item.value === 'SIGAMOS HACIENDO HISTORIA') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/SHH.png" height="20px" width="50px"></img>`;
							}
						},
					},
				},
				yAxis: {
					title: {
						//text: 'Porcentaje de captura',
						text: '',
					},
					/* 					min: 0,
					max: 100, */
					min: null,
					max: null,
					labels: {
						enabled: false,
					},
				},
				legend: {
					enabled: false,
				},
				plotOptions: {
					series: {
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							//format: '{point.conteoRespuestas} de {point.conteoTotal}',
							//format: '{point.y}%',
							format: '{point.conteoRespuestas}',
						},
					},
				},
				tooltip: {
					headerFormat: null,
					borderColor: '#A0005F',
					borderRadius: 2,
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.y}%</b><br/>',
					pointFormat:
						//'<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.conteoRespuestas} de {point.conteoTotal}</b><br/>',
						'<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.conteoRespuestas}</b><br/>',
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: 'Candidaturas que se identifican como parte de la comunidad LGBTTTIQ+ por partido',
						colorByPoint: true,
						data: seriesAcomodo,
					},
				],
			});
		}
	}, [jsonGraficasPartidos, tabSeleccionada, conteoTotalIdentidad, props.entidad]);

	useEffect(() => {
		//console.log(" ---------------- jsonGraficasCandidatos: " + jsonGraficasCandidatos);
		//console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
		if (jsonGraficasCandidatos && tabSeleccionada === '13' && jsonGraficasCandidatos.descJoven) {
			var seriesAcomodo = [];
			var tempTotal = 0;
			jsonGraficasCandidatos.descJoven.forEach((item) => {
				seriesAcomodo = [
					...seriesAcomodo,
					{
						name: item.descripcionRespuesta,
						y: item.porcentaje,
						conteoTotal: item.conteoTotal,
						conteoRespuestas: item.conteo,
					},
				];

				if (jsonGraficasCandidatos.descDiscapacidad.length <= 3) {
					if (item.descripcionRespuesta === 'Sí') tempTotal = item.conteo;
				}

				if (tempTotal > 0) setConteoTotal(tempTotal);
				else setConteoTotal('0');

				if (!conteoTotalIdentidad || conteoTotalIdentidad != item.conteoTotal) setConteoTotalIdentidad(item.conteoTotal);
			});
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-candidatura-jovenes',
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie',
				},
				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					//text: 'Del total de las candidaturas que respondieron el cuestionario identidad, se identifican como población joven:',
					text: 'Candidaturas que se autoidentifican como personas que forman parte de la población joven',
				},

				tooltip: {
					headerFormat: null,
					borderColor: '#A0005F',
					borderRadius: 2,
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} de {point.conteoTotal}</b><br/>',
					pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} </b><br/>',
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
					},
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: 'Candidaturas jóvenes',
						// colorByPoint: true,
						//colors: ['#E782CC', '#CCCCCC', '#36DD99'],
						colors: ['#7CB5EC', '#5254bf', '#09df6f'],
						data: seriesAcomodo,
					},
				],
			});
		}
	}, [jsonGraficasCandidatos, tabSeleccionada, conteoTotalIdentidad, conteoTotal]);

	useEffect(() => {
		if (jsonGraficasPartidos && tabSeleccionada === '13' && jsonGraficasPartidos.descJoven) {
			var seriesAcomodo = [];
			var conteoTotal = null;
			jsonGraficasPartidos.descJoven.forEach((item) => {
				seriesAcomodo = [
					...seriesAcomodo,
					{
						name: item.siglas,
						y: item.porcentaje,
						conteoTotal: item.conteoTotal,
						conteoRespuestas: item.conteoRespuestas,
						nombreAsociacion: item.nombreAsociacion,
					},
				];
				if (!conteoTotal) conteoTotal = item.conteoTotal;
				if (conteoTotal == undefined) conteoTotal = 0;
			});
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-barras-jovenes',
					type: 'bar',
				},
				colors: ['#D5007F'],
				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					//text: 'Candidaturas que se identifican como parte de la comunidad LGBTTTIQ+ por partido'
					//text: 'Del total de las candidaturas que se identificaron como parte de la comunidad LGBTTTIQ+, se presentan los siguientes porcentajes:'
					//text: `De las ${conteoTotalIdentidad} (100%) candidaturas que respondieron el cuestionario de identidad, ${conteoTotal} se identificaron como población joven, se presentan los siguientes porcentajes:`
					text: `Desagregado por actor político.`,
				},
				xAxis: {
					type: 'category',
					title: {
						text:
							/* 							props.entidad === '13' || props.entidad === '20' || props.entidad === '28'
								? 'Partidos Políticos, Coaliciones, Candidaturas Independientes y Candidaturas Comunes'
								: 'Partidos Políticos, Coaliciones y Candidaturas Independientes', */
							'Partidos Políticos',
					},
					labels: {
						enabled: false,
					},
					labels: {
						useHTML: true,
						formatter: function (item) {
							if (item.value === 'INDEPENDIENTE') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/CI.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PAN') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PAN.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PRI') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PRI.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PRD') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PRD.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PT') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PT.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'MORENA') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/MORENA.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PVEM') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PVEM.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'MOVIMIENTO CIUDADANO') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/MV.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'FUERZA Y CORAZON POR MEXICO') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/FYCM.png" height="20px" width="50px"></img>`;
							} else if (item.value === 'SIGAMOS HACIENDO HISTORIA') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/SHH.png" height="20px" width="50px"></img>`;
							}
						},
					},
				},
				yAxis: {
					title: {
						//text: 'Porcentaje de captura',
						text: '',
					},
					/* 					min: 0,
					max: 100, */
					min: null,
					max: null,
					labels: {
						enabled: false,
					},
				},
				legend: {
					enabled: false,
				},
				plotOptions: {
					series: {
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							//format: '{point.conteoRespuestas} de {point.conteoTotal}',
							//format: '{point.y}%',
							format: '{point.conteoRespuestas}',
						},
					},
				},
				tooltip: {
					headerFormat: null,
					borderColor: '#A0005F',
					borderRadius: 2,
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.y}%</b><br/>',
					pointFormat:
						//'<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.conteoRespuestas} de {point.conteoTotal}</b><br/>',
						'<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.conteoRespuestas}</b><br/>',
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: 'Candidaturas que se identifican como población joven por partido',
						colorByPoint: true,
						data: seriesAcomodo,
					},
				],
			});
		}
	}, [jsonGraficasPartidos, tabSeleccionada, conteoTotalIdentidad, props.entidad]);

	useEffect(() => {
		//console.log(" ---------------- jsonGraficasCandidatos: " + jsonGraficasCandidatos);
		//console.log(" ---------------- tabSeleccionada: " + tabSeleccionada);
		if (jsonGraficasCandidatos && tabSeleccionada === '14' && jsonGraficasCandidatos.descMayor) {
			var seriesAcomodo = [];
			var tempTotal = 0;
			jsonGraficasCandidatos.descMayor.forEach((item) => {
				seriesAcomodo = [
					...seriesAcomodo,
					{
						name: item.descripcionRespuesta,
						y: item.porcentaje,
						conteoTotal: item.conteoTotal,
						conteoRespuestas: item.conteo,
					},
				];

				if (jsonGraficasCandidatos.descDiscapacidad.length <= 3) {
					if (item.descripcionRespuesta === 'Sí') tempTotal = item.conteo;
				}

				if (tempTotal > 0) setConteoTotal(tempTotal);
				else setConteoTotal('0');

				if (!conteoTotalIdentidad || conteoTotalIdentidad != item.conteoTotal) setConteoTotalIdentidad(item.conteoTotal);
			});
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-candidatura-adultos',
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie',
				},
				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					//text: 'Del total de las candidaturas que respondieron el cuestionario identidad, se identifican como población mayor:',
					text: 'Candidaturas que se autoidentifican como personas que forman parte de la población mayor',
				},

				tooltip: {
					headerFormat: null,
					borderColor: '#A0005F',
					borderRadius: 2,
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} de {point.conteoTotal}</b><br/>',
					pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.conteoRespuestas} </b><br/>',
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
					},
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: 'Candidaturas personas mayores',
						// colorByPoint: true,
						//colors: ['#E782CC', '#CCCCCC', '#36DD99'],
						colors: ['#7CB5EC', '#5254bf', '#09df6f'],
						data: seriesAcomodo,
					},
				],
			});
		}
	}, [jsonGraficasCandidatos, tabSeleccionada, conteoTotalIdentidad, conteoTotal]);

	useEffect(() => {
		if (jsonGraficasPartidos && tabSeleccionada === '14' && jsonGraficasPartidos.descMayor) {
			var seriesAcomodo = [];
			var conteoTotal = null;
			jsonGraficasPartidos.descMayor.forEach((item) => {
				//let porcentaje=parseFloat(item.porcentaje.toFixed(2));

				// console.log("porcentaje1x", porcentaje1);
				seriesAcomodo = [
					...seriesAcomodo,
					{
						name: item.siglas,
						//  y: porcentaje,
						y: item.porcentaje,
						conteoTotal: item.conteoTotal,
						conteoRespuestas: item.conteoRespuestas,
						nombreAsociacion: item.nombreAsociacion,
					},
				];
				if (!conteoTotal) conteoTotal = item.conteoTotal;
				if (conteoTotal == undefined) conteoTotal = 0;
			});
			Highcharts.chart({
				chart: {
					renderTo: 'grafica-barras-adultos',
					type: 'bar',
				},
				colors: ['#D5007F'],
				title: {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontSize: 19,
						fontWeight: 500,
						textAlign: 'center',
					},
					//text: 'Candidaturas que se identifican como parte de la comunidad LGBTTTIQ+ por partido'
					//text: 'Del total de las candidaturas que se identificaron como parte de la comunidad LGBTTTIQ+, se presentan los siguientes porcentajes:'
					//text: `De las ${conteoTotalIdentidad} (100%) candidaturas que respondieron el cuestionario de identidad, ${conteoTotal} se identificaron como población mayor, se presentan los siguientes porcentajes:`
					text: `Desagregado por actor político.`,
				},
				xAxis: {
					type: 'category',
					title: {
						text:
							/* 							props.entidad === '13' || props.entidad === '20' || props.entidad === '28'
								? 'Partidos Políticos, Coaliciones, Candidaturas Independientes y Candidaturas Comunes'
								: 'Partidos Políticos, Coaliciones y Candidaturas Independientes', */
							'Partidos Políticos',
					},
					labels: {
						enabled: false,
					},
					labels: {
						useHTML: true,
						formatter: function (item) {
							if (item.value === 'INDEPENDIENTE') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/CI.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PAN') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PAN.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PRI') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PRI.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PRD') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PRD.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PT') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PT.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'MORENA') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/MORENA.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'PVEM') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/PVEM.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'MOVIMIENTO CIUDADANO') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/MV.png" height="20px" width="20px"></img>`;
							} else if (item.value === 'FUERZA Y CORAZON POR MEXICO') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/FYCM.png" height="20px" width="50px"></img>`;
							} else if (item.value === 'SIGAMOS HACIENDO HISTORIA') {
								return `<img src="${process.env.REACT_APP_API_URL}img/emblemasPartidos/SHH.png" height="20px" width="50px"></img>`;
							}
						},
					},
				},
				yAxis: {
					title: {
						//text: 'Porcentaje de captura',
						text: '',
					},
					/* 					min: 0,
					max: 100, */
					min: null,
					max: null,
					labels: {
						enabled: false,
					},
				},
				legend: {
					enabled: false,
				},
				plotOptions: {
					series: {
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							//format: '{point.conteoRespuestas} de {point.conteoTotal}',
							//format: '{point.y}%',
							format: '{point.conteoRespuestas}',
						},
					},
				},
				tooltip: {
					headerFormat: null,
					borderColor: '#A0005F',
					borderRadius: 2,
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.name}</span>: <b>{point.y}</b><br/>',
					//pointFormat: '<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.y}%</b><br/>',
					pointFormat:
						//'<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.conteoRespuestas} de {point.conteoTotal}</b><br/>',
						'<span style="color:#000000;border:#A0005F">{point.nombreAsociacion}</span>:{point.conteoRespuestas}</b><br/>',
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: 'Candidaturas que se identifican como población mayor por partido',
						colorByPoint: true,
						data: seriesAcomodo,
					},
				],
			});
		}
	}, [jsonGraficasPartidos, tabSeleccionada, conteoTotalIdentidad, props.entidad]);

	useEffect(() => {
		if (jsonGraficasAccionesAfirmativas) {
			if (jsonGraficasAccionesAfirmativas[tabSeleccionada]) {
				let typeChart = 'column';
				if (window.screen.width < 768) {
					typeChart = 'bar';
				}
				Highcharts.chart({
					chart: {
						renderTo: 'grafica-accionesAfirmativas-partidos',
						type: typeChart,
					},
					title: {
						style: {
							fontFamily: 'Roboto, sans-serif',
							fontSize: 19,
							fontWeight: 500,
							textAlign: 'center',
						},
						text: jsonGraficasAccionesAfirmativas.nombrePartidos.find((p) => p.id == tabSeleccionada).nombre,
					},
					xAxis: {
						categories: jsonGraficasAccionesAfirmativas.categories,
					},
					yAxis: {
						title: {
							text: null,
						},
					},
					credits: {
						enabled: false,
					},
					series: jsonGraficasAccionesAfirmativas[tabSeleccionada],
					colors: ['#FF8C8C', '#DB2491', '#228F99', '#B243A1'],
					tooltip: {
						borderColor: '#A0005F',
						borderRadius: 2,
						pointFormat: '<span style="color:#000000;border:#A0005F">{point.series.name}</span>: {point.y} personas</b><br/>',
					},
				});
			}
		}
	}, [jsonGraficasAccionesAfirmativas, tabSeleccionada]);

	let listMenuAccionesAfirmativas = null;
	if (jsonGraficasAccionesAfirmativas) {
		if (jsonGraficasAccionesAfirmativas.partidos) {
			listMenuAccionesAfirmativas = jsonGraficasAccionesAfirmativas.partidos.map((partido) => (
				<>
					<Menu.Item key={`${partido}`}>
						<span>
							<RightOutlined />
							{jsonGraficasAccionesAfirmativas.nombrePartidos.find((p) => p.id == partido).nombre}
						</span>
					</Menu.Item>
				</>
			));
		}
	}

	const onChangeTab = (tabKey) => {
		////console.log(tabKey);
		setTabSeleccionada(tabKey);
	};
	const reiniciarI = () => {
		i = 0;
	};

	const onSelectMenu = (item) => {
		////console.log(tabKey);
		setTabSeleccionada(item.key);
	};

	const [mode, setMode] = React.useState('inline');

	return (
		<div>
			<Row>
				<Col span={24}>
					<p className="info-graficas bb titulo-seccion">Estadísticas de las personas candidatas:</p>
					{/*<h5>La siguiente gráfica muestra las candidaturas agrupadas por:</h5> <br />*/}
					<h5>
						Consulta las estadísticas de los datos registrados por las personas candidatas, por tipo de candidatura, y la información proporcionada
						en el cuestionario de identidad:
					</h5>{' '}
					<br />
					{/*<Divider />*/}
				</Col>
			</Row>
			<Row>
				<Col xs={{span: 23}} md={{span: 9}} lg={{span: 6}}>
					<Menu
						//defaultSelectedKeys={['1']}
						//defaultOpenKeys={['sub1']}
						mode={mode}
						defaultSelectedKeys={[`${tabSeleccionada}`]}
						selectedKeys={[`${tabSeleccionada}`]}
						onSelect={onSelectMenu}
						//theme={theme}
					>
						{/* <Menu.Item key="10">
                            <span>
                                <RightOutlined />
                                Registro curricular de gubernaturas
                            </span>
                        </Menu.Item>*/}
						{/*props.entidad === '23' && (
							<Menu.Item key="1">
								<span>
									<RightOutlined />
									Registro curricular de diputaciones
								</span>
							</Menu.Item>
                        )*/}
						{props.entidad === '10' && (
							<Menu.Item key="11">
								<span>
									<RightOutlined />
									Registro curricular de presidencias municipales
								</span>
							</Menu.Item>
						)}
						{jsonPresidenteGraficaPorcentaje && (
							<Menu.Item key="17">
								<span>
									<RightOutlined />
									Registro curricular de presidencia
								</span>
							</Menu.Item>
						)}
						{jsonSenadoresGraficaPorcentaje && (
							<Menu.Item key="15">
								<span>
									<RightOutlined />
									Registro curricular de senadurías
								</span>
							</Menu.Item>
						)}
						{jsonDiputadosGraficaPorcentaje && (
							<Menu.Item key="16">
								<span>
									<RightOutlined />
									Registro curricular de diputaciones
								</span>
							</Menu.Item>
						)}
						<Menu.Item key="2">
							<span>
								<RightOutlined />
								Grado académico
							</span>
						</Menu.Item>
						<Menu.Item key="3">
							<span>
								<RightOutlined />
								Rangos de edad
							</span>
						</Menu.Item>
						<Menu.Item key="4">
							<span>
								<RightOutlined />
								Sexo
							</span>
						</Menu.Item>
						<div className="titulo-seccion-graficas">
							<p>Personas candidatas:</p>
						</div>
						<Menu.Item key="5">
							<span>
								<RightOutlined />
								Indígenas
							</span>
						</Menu.Item>
						<Menu.Item key="6">
							<span>
								<RightOutlined />
								Con discapacidad
							</span>
						</Menu.Item>
						<Menu.Item key="7">
							<span>
								<RightOutlined />
								Afromexicanas
							</span>
						</Menu.Item>
						<Menu.Item key="9">
							<span>
								<RightOutlined />
								Migrantes
							</span>
						</Menu.Item>
						<Menu.Item key="8">
							<span>
								<RightOutlined />
								Diversidad sexual
							</span>
						</Menu.Item>
						<Menu.Item key="13">
							<span>
								<RightOutlined />
								Jóvenes
							</span>
						</Menu.Item>
						<Menu.Item key="14">
							<span>
								<RightOutlined />
								Mayores
							</span>
						</Menu.Item>
						<Menu.Item key="12">
							<span>
								<RightOutlined />
								Nivel de ingresos
							</span>
						</Menu.Item>
						<div className="titulo-seccion-graficas-rosa">
							<p>Acciones afirmativas de los partidos:</p>
						</div>
						{listMenuAccionesAfirmativas}
					</Menu>
				</Col>
				<Col xs={{span: 24}} md={{span: 15}} lg={{span: 18}} style={{paddingLeft: '8px'}}>
					{
						//((jsonDiputadosGraficaPorcentaje && jsonGubernaturaGraficaPorcentaje && jsonPresidentesGrafica && jsonEstadosGraficaPorcentaje) ||
					}
					{jsonDiputadosGraficaPorcentaje &&
						jsonSenadoresGraficaPorcentaje &&
						jsonPresidenteGraficaPorcentaje &&
						(tabSeleccionada == '1' ||
							tabSeleccionada == '10' ||
							tabSeleccionada == '11' ||
							tabSeleccionada == '15' ||
							tabSeleccionada == '16' ||
							tabSeleccionada == '17') && (
							<div>
								{
									////console.log("jsonDiputadosGraficaPorcentaje", jsonDiputadosGraficaPorcentaje.desglose)
									//setEstado(props.entidad)
								}
								<Row>
									<Col span={24}>
										<div id="grafica-rc-diputados" className="grafica" />
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<div>
											<div className="contenedor-desglose">
												{
													tabSeleccionada == '15' ? (
														<>
															<div id="desglose-mr-dip">
																<span style={{width: `${anchoDip}%`}}>MR</span>
																{
																	////console.log("jsonDiputadosGraficaPorcentaje1", jsonDiputadosGraficaPorcentaje.desglose)
																}
																{jsonSenadoresGraficaPorcentaje.desglose.map((element) => {
																	i = i + 1;
																	//console.log("jsonDiputadosGraficaPorcentaje3", element.mr)
																	return (
																		<span className="margin-mr-rp-graficas-sen" style={{width: `${anchoDip}%`}}>
																			{element.mr}
																		</span>
																	);
																})}
																{reiniciarI()}
															</div>

															<div id="desglose-rp-dip">
																<span style={{width: `${anchoDip}%`}}>RP</span>

																{jsonSenadoresGraficaPorcentaje.desglose.map((element) => {
																	i = i + 1;
																	return (
																		<span className="margin-mr-rp-graficas-sen" style={{width: `${anchoDip}%`}}>
																			{element.rp}
																		</span>
																	);
																})}
																{reiniciarI()}
															</div>
														</>
													) : tabSeleccionada == '16' ? (
														<>
															<div id="desglose-mr-dip">
																<span style={{width: `${anchoDip}%`}}>MR</span>
																{
																	////console.log("",)
																}
																{jsonDiputadosGraficaPorcentaje.desglose.map((element) => {
																	i = i + 1;
																	//console.log("")
																	return (
																		<span className="margin-mr-rp-graficas-dip" style={{width: `${anchoDip}%`}}>
																			{element.mr}
																		</span>
																	);
																})}
																{reiniciarI()}
															</div>

															<div id="desglose-rp-dip">
																<span style={{width: `${anchoDip}%`}}>RP</span>

																{jsonDiputadosGraficaPorcentaje.desglose.map((element) => {
																	i = i + 1;
																	return (
																		<span className="margin-mr-rp-graficas-dip" style={{width: `${anchoDip}%`}}>
																			{element.rp}
																		</span>
																	);
																})}
																{reiniciarI()}
															</div>
														</>
													) : (
														''
													) /* (
													  tabSeleccionada == '177' ? (
													<>
														<div id="desglose-mr-dip">
															<span style={{width: `${anchoDip}%`, marginLeft: '-25px'}}>MR</span>
															{
																////console.log("jsonDiputadosGraficaPorcentaje1", jsonDiputadosGraficaPorcentaje.desglose)
															}
															{jsonPresidenteGraficaPorcentaje.desglose.map((element) => {
																i = i + 1;
																//console.log("jsonDiputadosGraficaPorcentaje3", element.mr)
																return <span style={{width: `${anchoDip}%`}}>{element.mr}</span>;
															})}
															{reiniciarI()}
														</div>

														<div id="desglose-rp-dip">
															<span style={{width: `${anchoDip}%`, marginLeft: '-25px'}}>RP</span>

															{jsonPresidenteGraficaPorcentaje.desglose.map((element) => {
																i = i + 1;
																return <span style={{width: `${anchoDip}%`}}>{element.rp}</span>;
															})}
															{reiniciarI()}
														</div>
													</>
												) :  ''
												) */
												}
												<div id="desglose-total-dip">
													{
														//console.log("entrando a los totales",jsonGubernaturaGraficaPorcentaje)
													}

													{tabSeleccionada === '1' ? (
														<>
															{' '}
															<span style={{width: `${anchoDip}%`}}>TOTAL</span>
															{jsonDiputadosGraficaPorcentaje.desglose.map((element) => {
																i = i + 1;
																return <span style={{width: `${anchoDip}%`}}>{element.total}</span>;
															})}
														</>
													) : tabSeleccionada === '10' ? (
														props.entidad === null ? (
															<>
																{' '}
																<span style={{width: `${anchoDip - 1.5}%`}}>TOTAL</span>
																{jsonGubernaturaGraficaPorcentaje.desglose.map((element) => {
																	return <span style={{width: `${anchoDip + 0.05}%`}}>{element.total}</span>;
																})}
															</>
														) : props.entidad === '13' ? (
															<>
																{' '}
																<span style={{width: `${anchoDip - 12.5}%`}}>TOTAL</span>
																{jsonEstadosGraficaPorcentaje.hidalgo.desglose.map((element) => {
																	i = i + 1;
																	return (
																		<span
																			style={{
																				width:
																					i == 1
																						? `${anchoDip - 1}%`
																						: i == 2
																						? `${anchoDip + 8}%`
																						: i == 3
																						? `${anchoDip - 2}%`
																						: i == 4
																						? `${anchoDip + 7.5}%`
																						: `${anchoDip}%`,
																			}}
																		>
																			{element.total}
																		</span>
																	);
																})}
															</>
														) : props.entidad === '1' ? (
															<>
																{' '}
																<span style={{width: `${anchoDip - 8.5}%`}}>TOTAL</span>
																{jsonEstadosGraficaPorcentaje.aguascalientes.desglose.map((element) => {
																	i = i + 1;
																	return (
																		<span
																			style={{
																				width:
																					i == 1
																						? `${anchoDip - 3}%`
																						: i == 2
																						? `${anchoDip + 7}%`
																						: i == 3
																						? `${anchoDip - 3}%`
																						: i == 4
																						? `${anchoDip + 7}%`
																						: i == 5
																						? `${anchoDip - 3}%`
																						: `${anchoDip}%`,
																			}}
																		>
																			{element.total}
																		</span>
																	);
																})}
															</>
														) : props.entidad === '10' ? (
															<>
																{' '}
																<span style={{width: `${anchoDip - 17.5}%`}}>TOTAL</span>
																{jsonEstadosGraficaPorcentaje.durango.desglose.map((element) => {
																	i = i + 1;
																	return (
																		<span
																			style={{
																				width:
																					i == 1
																						? `${anchoDip + 2}%`
																						: i == 2
																						? `${anchoDip + 11}%`
																						: i == 3
																						? `${anchoDip}%`
																						: `${anchoDip}%`,
																			}}
																		>
																			{element.total}
																		</span>
																	);
																})}
															</>
														) : props.entidad === '20' ? (
															<>
																{' '}
																<span style={{width: `${anchoDip - 6.5}%`}}>TOTAL</span>
																{jsonEstadosGraficaPorcentaje.oaxaca.desglose.map((element) => {
																	i = i + 1;
																	return (
																		<span
																			style={{
																				width:
																					i == 1
																						? `${anchoDip - 4}%`
																						: i == 2
																						? `${anchoDip + 7}%`
																						: i == 3
																						? `${anchoDip - 4.5}%`
																						: i == 4
																						? `${anchoDip + 7}%`
																						: i == 5
																						? `${anchoDip - 3.5}%`
																						: i == 6
																						? `${anchoDip + 4.5}%`
																						: `${anchoDip}%`,
																			}}
																		>
																			{element.total}
																		</span>
																	);
																})}
															</>
														) : props.entidad === '23' ? (
															<>
																{' '}
																<span style={{width: `${anchoDip - 8.5}%`}}>TOTAL</span>
																{jsonEstadosGraficaPorcentaje.quintanaroo.desglose.map((element) => {
																	i = i + 1;
																	return (
																		<span
																			style={{
																				width:
																					i == 1
																						? `${anchoDip - 3}%`
																						: i == 2
																						? `${anchoDip + 7}%`
																						: i == 3
																						? `${anchoDip - 3}%`
																						: i == 4
																						? `${anchoDip + 7}%`
																						: i == 5
																						? `${anchoDip - 4}%`
																						: `${anchoDip}%`,
																			}}
																		>
																			{element.total}
																		</span>
																	);
																})}
															</>
														) : props.entidad === '28' ? (
															<>
																{' '}
																<span style={{width: `${anchoDip - 17.5}%`}}>TOTAL</span>
																{jsonEstadosGraficaPorcentaje.tamaulipas.desglose.map((element) => {
																	i = i + 1;
																	return (
																		<span
																			style={{
																				width:
																					i == 1
																						? `${anchoDip + 2}%`
																						: i == 2
																						? `${anchoDip + 10}%`
																						: i == 3
																						? `${anchoDip + 3}%`
																						: `${anchoDip}%`,
																			}}
																		>
																			{element.total}
																		</span>
																	);
																})}
															</>
														) : (
															''
														)
													) : tabSeleccionada === '11' ? (
														<>
															{' '}
															<span style={{width: `${anchoDip - 3}%`}}>TOTAL</span>
															{jsonPresidentesGrafica.desglose.map((element) => {
																i = i + 1;

																return (
																	<span
																		style={{
																			width:
																				i == 1
																					? `${anchoDip - 1}%`
																					: i == 2
																					? `${anchoDip + 1}%`
																					: i == 3
																					? `${anchoDip}%`
																					: i == 4
																					? `${anchoDip + 1}%`
																					: `${anchoDip}%`,
																		}}
																	>
																		{element.total}
																	</span>
																);
															})}{' '}
														</>
													) : tabSeleccionada === '15' ? (
														<>
															{' '}
															<span style={{width: `9%`}}>TOTAL</span>
															{jsonSenadoresGraficaPorcentaje.desglose.map((element) => {
																return (
																	<span className="margin-totales-graficas-sen-dip" style={{width: `${anchoDip}%`}}>
																		{element.total}
																	</span>
																);
															})}{' '}
														</>
													) : tabSeleccionada === '16' ? (
														<>
															{' '}
															<span style={{width: `9%`}}>TOTAL</span>
															{jsonDiputadosGraficaPorcentaje.desglose.map((element) => {
																return (
																	<span className="margin-totales-graficas-sen-dip" style={{width: `${anchoDip}%`}}>
																		{element.total}
																	</span>
																);
															})}{' '}
														</>
													) : tabSeleccionada === '17' ? (
														<>
															{' '}
															<span style={{width: `9%`}}>TOTAL</span>
															{jsonPresidenteGraficaPorcentaje.desglose.map((element) => {
																return (
																	<span className="margin-totales-graficas-pre" style={{width: `${anchoDip}%`}}>
																		{element.total}
																	</span>
																);
															})}{' '}
														</>
													) : (
														''
													)}
													{reiniciarI()}
												</div>
											</div>
										</div>
										<div>
											<div className="contenedor-desglose-partidos">
												{tabSeleccionada === '1' ? (
													<div id="nombre-actor-politico-dip">
														<span style={{width: `${anchoDip}%`}}></span>
														{jsonDiputadosGraficaPorcentaje.porcentaje.map((element) => {
															return (
																<span style={{width: `${anchoDip}%`}} className="actor-politico-grafica">
																	<Tooltip color="#A0005F" title={element.nCompleto}>
																		{' '}
																		{element.name}
																	</Tooltip>
																</span>
															);
														})}
													</div>
												) : tabSeleccionada === '10' ? (
													props.entidad === null ? (
														<div id="nombre-actor-politico-gob">
															<span style={{width: `${anchoDip}%`}}></span>
															{jsonGubernaturaGraficaPorcentaje.porcentaje.map((element) => {
																return (
																	<span style={{width: `${anchoDip}%`}} className="actor-politico-grafica">
																		<Tooltip color="#A0005F" title={element.nombreAsociacion}>
																			{' '}
																			{element.name}
																		</Tooltip>
																	</span>
																);
															})}
														</div>
													) : props.entidad === '13' ? (
														<div id="nombre-actor-politico-gob-13">
															<span style={{width: `${anchoDip}%`}}></span>
															{jsonEstadosGraficaPorcentaje.hidalgo.porcentaje.map((element) => {
																return (
																	<span style={{width: `${anchoDip}%`}} className="actor-politico-grafica">
																		<Tooltip color="#A0005F" title={element.nombreAsociacion}>
																			{' '}
																			{element.name}
																		</Tooltip>
																	</span>
																);
															})}
														</div>
													) : props.entidad === '1' ? (
														<div id="nombre-actor-politico-gob-1">
															<span style={{width: `${anchoDip}%`}}></span>
															{jsonEstadosGraficaPorcentaje.aguascalientes.porcentaje.map((element) => {
																return (
																	<span style={{width: `${anchoDip}%`}} className="actor-politico-grafica">
																		<Tooltip color="#A0005F" title={element.nombreAsociacion}>
																			{' '}
																			{element.name}
																		</Tooltip>
																	</span>
																);
															})}
														</div>
													) : props.entidad === '10' ? (
														<div id="nombre-actor-politico-gob-10">
															<span style={{width: `${anchoDip}%`}}></span>
															{jsonEstadosGraficaPorcentaje.durango.porcentaje.map((element) => {
																return (
																	<span style={{width: `${anchoDip}%`}} className="actor-politico-grafica">
																		<Tooltip color="#A0005F" title={element.nombreAsociacion}>
																			{' '}
																			{element.name}
																		</Tooltip>
																	</span>
																);
															})}
														</div>
													) : props.entidad === '20' ? (
														<div id="nombre-actor-politico-gob-20">
															<span style={{width: `${anchoDip}%`}}></span>
															{jsonEstadosGraficaPorcentaje.oaxaca.porcentaje.map((element) => {
																return (
																	<span style={{width: `${anchoDip}%`}} className="actor-politico-grafica">
																		<Tooltip color="#A0005F" title={element.nombreAsociacion}>
																			{' '}
																			{element.name}
																		</Tooltip>
																	</span>
																);
															})}
														</div>
													) : props.entidad === '23' ? (
														<div id="nombre-actor-politico-gob-23">
															<span style={{width: `${anchoDip}%`}}></span>
															{jsonEstadosGraficaPorcentaje.quintanaroo.porcentaje.map((element) => {
																return (
																	<span style={{width: `${anchoDip}%`}} className="actor-politico-grafica">
																		<Tooltip color="#A0005F" title={element.nombreAsociacion}>
																			{' '}
																			{element.name}
																		</Tooltip>
																	</span>
																);
															})}
														</div>
													) : props.entidad === '28' ? (
														<div id="nombre-actor-politico-gob-28">
															<span style={{width: `${anchoDip}%`}}></span>
															{jsonEstadosGraficaPorcentaje.tamaulipas.porcentaje.map((element) => {
																return (
																	<span style={{width: `${anchoDip}%`}} className="actor-politico-grafica">
																		<Tooltip color="#A0005F" title={element.nombreAsociacion}>
																			{' '}
																			{element.name}
																		</Tooltip>
																	</span>
																);
															})}
														</div>
													) : (
														''
													)
												) : tabSeleccionada === '11' ? (
													<div id="nombre-actor-politico-pre">
														<span style={{width: `${anchoDip}%`}}></span>
														{jsonPresidentesGrafica.porcentaje.map((element) => {
															return (
																<span style={{width: `${anchoDip}%`}} className="actor-politico-grafica ">
																	<Tooltip color="#A0005F" title={element.nombreAsociacion}>
																		{' '}
																		{element.name}
																	</Tooltip>
																</span>
															);
														})}
													</div>
												) : tabSeleccionada === '15' ? (
													<div id="nombre-actor-politico-sen">
														<span style={{height: `${anchoDip}%`}}></span>
														{jsonSenadoresGraficaPorcentaje.porcentaje.map((element) => {
															return (
																<span style={{width: `${anchoDip}%`}} className="actor-politico-grafica-senadores">
																	<Tooltip color="#A0005F" title={element.nCompleto} height={'10px'}>
																		<div className="span-actor-politico-grafica">{element.name}</div>
																	</Tooltip>
																</span>
															);
														})}
													</div>
												) : tabSeleccionada === '16' ? (
													<div id="nombre-actor-politico-dip">
														<span style={{height: `${anchoDip}%`}}></span>
														{jsonDiputadosGraficaPorcentaje.porcentaje.map((element) => {
															return (
																<span style={{width: `${anchoDip}%`}} className="actor-politico-grafica-senadores">
																	<Tooltip color="#A0005F" title={element.nCompleto} height={'10px'}>
																		<div className="span-actor-politico-grafica">{element.name}</div>
																	</Tooltip>
																</span>
															);
														})}
													</div>
												) : tabSeleccionada === '17' ? (
													<div id="nombre-actor-politico-dip">
														<span style={{height: `${anchoDip}%`}}></span>
														{jsonPresidenteGraficaPorcentaje.porcentaje.map((element) => {
															return (
																<span style={{width: `${anchoDip}%`}} className="actor-politico-grafica-presidente">
																	<Tooltip color="#A0005F" title={element.nCompleto} height={'10px'}>
																		<div className="span-actor-politico-grafica">{element.name}</div>
																	</Tooltip>
																</span>
															);
														})}
													</div>
												) : (
													''
												)}
											</div>
										</div>
									</Col>
								</Row>
							</div>
						)}

					{/*tabSeleccionada == '15' && jsonSenadoresGraficaPorcentaje && (
						<div>
							<Row>
								<Col span={24}>
									<div id="grafica-rc-senadores" className="grafica" />
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<div className="contenedor-desglose">
										<div>
											<div id="desglose-mr-dip">
												<span style={{width: `7%`}}>MR</span>
												{jsonSenadoresGraficaPorcentaje.desglose.map((element) => {
													return <span style={{width: `${anchoDipSen}%`}}>{element.mr}</span>;
												})}
											</div>
											<div id="desglose-rp-dip">
												<span style={{width: `7%`}}>RP</span>
												{jsonSenadoresGraficaPorcentaje.desglose.map((element) => {
													return <span style={{width: `${anchoDipSen}%`}}>{element.rp}</span>;
												})}
											</div>

											<div id="desglose-total-dip">
												<span style={{width: `7%`}}>TOTAL</span>
												{jsonSenadoresGraficaPorcentaje.desglose.map((element) => {
													return <span style={{width: `${anchoDipSen}%`}}>{element.total}</span>;
												})}
											</div>
										</div>
									</div>
									<div className="contenedor-desglose-partidos">
										<div>
											<div id="nombre-actor-politico-dip">
												{actoresPoliticosSMR.map((element) => {
													return (
														<span style={{width: `${anchoDipSen}%`}} className="actor-politico-grafica-senadores">
															{element}
														</span>
													);
												})}
											</div>
										</div>
									</div>
								</Col>
							</Row>
						</div>
                                            )*/}

					{tabSeleccionada == '2' && jsonGraficaGradoAcademico && (
						<div className="pie_grafica_completo" style={{textAlign: 'center', paddingLeft: '20px', paddingRight: '20px'}}>
							<div id="grafica-grado-academico"></div>
						</div>
					)}
					{tabSeleccionada == '3' && jsonGraficaRangoEdad && (
						<div className="pie_grafica_completo" style={{textAlign: 'left', paddingLeft: '20px', paddingRight: '20px'}}>
							<div id="grafica-rango-edad"></div>
						</div>
					)}
					{tabSeleccionada == '4' && jsonGraficaSexo && (
						<div className="pie_grafica_completo" style={{textAlign: 'justify', paddingLeft: '20px', paddingRight: '20px'}}>
							<div id="grafica-partidos-politicos"></div>
						</div>
					)}
					{tabSeleccionada == '9' && jsonGraficasCandidatos && jsonGraficasCandidatos.descMigrante ? (
						<div>
							<div id="grafica-candidatura-migrante" className="grafica_2"></div>
							<div className="pie_grafica" style={{textAlign: 'center', paddingLeft: '20px', paddingRight: '20px'}}>
								<span>
									Del total de las candidaturas que respondieron el cuestionario de identidad, {conteoTotal} se autoidentificaron como
									migrantes.
								</span>
								<br />
							</div>
							<br />
							{<div id="grafica-barras-migrante" className="grafica_2_barra"></div>}
							<span className="genero-informa">
								{/* 								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
								Para mayor información sobre este último punto, se sugiere consultar el Acuerdo &nbsp; */}
								{/* 								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el “Cuestionario de Identidad”, 
								este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en situación de discriminación; 
								lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa. Para mayor información sobre este último punto, 
								se sugiere consultar el Acuerdo &nbsp;
								<a style={{wordBreak: 'break-all'}} target="_blank"
									 href="https://repositoriodocumental.ine.mx/pdfjs-flipbook/web/viewer.html?file=/xmlui/bitstream/handle/123456789/141976/CGex202209-07-ap-12-Gaceta.pdf?sequence=7&isAllowed=y"
								>
									INE/CG616/2022.
								</a> */}
								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
							</span>
						</div>
					) : (
						''
					)}
					{tabSeleccionada == '5' && jsonGraficasCandidatos && jsonGraficasCandidatos.descIndigena ? (
						<div>
							<div id="grafica-candidatura-indigenas" className="grafica_2"></div>
							<div className="pie_grafica" style={{textAlign: 'center', paddingLeft: '20px', paddingRight: '20px'}}>
								{/* 								<span>
									De las {conteoTotalIdentidad} (100%) candidaturas que respondieron el cuestionario de identidad, {conteoTotal} se
									identificaron como personas indígenas.
								</span>{' '} */}
								<span>
									Del total de las candidaturas que respondieron el cuestionario de identidad, {conteoTotal} se autoidentificaron como
									personas indígenas.
								</span>{' '}
								<br />
							</div>
							<br />
							{<div id="grafica-barras-indigenas" className="grafica_2_barra"></div>}
							{/*<div className="">
                                <span className="genero-informa">
                                    Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el “Cuestionario de identidad”, este contiene preguntas en cinco dimensiones relativas a la autoidentificación con algún grupo en situación de discriminación.
                                </span>
                            </div>*/}

							<span className="genero-informa">
								{/* 								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
								Para mayor información sobre este último punto, se sugiere consultar el Acuerdo &nbsp; */}
								{/* 								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
								Para mayor información sobre este último punto, se sugiere consultar el Acuerdo &nbsp;
								<a
									style={{wordBreak: 'break-all'}}
									target="_blank"
									href="https://repositoriodocumental.ine.mx/pdfjs-flipbook/web/viewer.html?file=/xmlui/bitstream/handle/123456789/141976/CGex202209-07-ap-12-Gaceta.pdf?sequence=7&isAllowed=y"
								>
									INE/CG616/2022.
								</a> */}
								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
							</span>
						</div>
					) : (
						''
					)}
					{tabSeleccionada == '6' && jsonGraficasCandidatos && jsonGraficasCandidatos.descDiscapacidad ? (
						<div>
							<div id="grafica-candidatura-discapacidad" className="grafica_2"></div>
							<div className="pie_grafica" style={{textAlign: 'center', paddingLeft: '20px', paddingRight: '20px'}}>
								{/* 								<span>
									De las {conteoTotalIdentidad} (100%) candidaturas que respondieron el cuestionario de identidad, {conteoTotal} se
									identificaron con alguna discapacidad.
								</span>{' '} */}
								<span>
									Del total de las candidaturas que respondieron el cuestionario de identidad, {conteoTotal} se autoidentificaron con alguna
									discapacidad.
								</span>{' '}
								<br />
							</div>
							<br />
							{<div id="grafica-barras-discapacidad" className="grafica_2_barra"></div>}
							{/*<div className="">
                                <span className="genero-informa">
                                    Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el “Cuestionario de identidad”, este contiene preguntas en cinco dimensiones relativas a la autoidentificación con algún grupo en situación de discriminación.
                                </span>
                            </div>*/}
							<span className="genero-informa">
								{/* 								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
								Para mayor información sobre este último punto, se sugiere consultar el Acuerdo &nbsp; */}
								{/* 								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
								Para mayor información sobre este último punto, se sugiere consultar el Acuerdo &nbsp;
								<a
									style={{wordBreak: 'break-all'}}
									target="_blank"
									href="https://repositoriodocumental.ine.mx/pdfjs-flipbook/web/viewer.html?file=/xmlui/bitstream/handle/123456789/141976/CGex202209-07-ap-12-Gaceta.pdf?sequence=7&isAllowed=y"
								>
									INE/CG616/2022.
								</a> */}
								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
							</span>
						</div>
					) : (
						''
					)}
					{tabSeleccionada == '7' && jsonGraficasCandidatos && jsonGraficasCandidatos.descAfromexicana ? (
						<div>
							<div id="grafica-candidatura-afromexicanas" className="grafica_2"></div>
							<div className="pie_grafica" style={{textAlign: 'center', paddingLeft: '20px', paddingRight: '20px'}}>
								<span>
									Del total de las candidaturas que respondieron el cuestionario de identidad, {conteoTotal} se autoidentificaron como
									afromexicanas.
								</span>{' '}
								<br />
							</div>
							<br />
							{<div id="grafica-barras-afromexicanas" className="grafica_2_barra"></div>}
							{/*<div className="">
                                <span className="genero-informa">
                                    Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el “Cuestionario de identidad”, este contiene preguntas en cinco dimensiones relativas a la autoidentificación con algún grupo en situación de discriminación.
                                </span>
                            </div>*/}
							<span className="genero-informa">
								{/* 								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
								Para mayor información sobre este último punto, se sugiere consultar el Acuerdo &nbsp; */}
								{/* 								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
								Para mayor información sobre este último punto, se sugiere consultar el Acuerdo &nbsp;
								<a
									style={{wordBreak: 'break-all'}}
									target="_blank"
									href="https://repositoriodocumental.ine.mx/pdfjs-flipbook/web/viewer.html?file=/xmlui/bitstream/handle/123456789/141976/CGex202209-07-ap-12-Gaceta.pdf?sequence=7&isAllowed=y"
								>
									INE/CG616/2022.
								</a> */}
								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
							</span>
						</div>
					) : (
						''
					)}
					{tabSeleccionada == '8' && jsonGraficasCandidatos && jsonGraficasCandidatos.descDiversidadSexual ? (
						<div>
							<div id="grafica-candidatura-diversidad" className="grafica_2"></div>
							<div className="pie_grafica" style={{textAlign: 'center', paddingLeft: '20px', paddingRight: '20px'}}>
								<span>
									Del total de las candidaturas que respondieron el cuestionario de identidad, {conteoTotal} se autoidentificaron como parte
									de la comunidad LGBTTTIQ+.
								</span>{' '}
								<br />
							</div>
							<br />
							{<div id="grafica-barras-diversidad" className="grafica_2_barra"></div>}
							{/*<div className="">
                                <span className="genero-informa">
                                    Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el “Cuestionario de identidad”, este contiene preguntas en cinco dimensiones relativas a la autoidentificación con algún grupo en situación de discriminación.
                                </span>
                            </div>*/}
							<span className="genero-informa">
								{/* 								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
								Para mayor información sobre este último punto, se sugiere consultar el Acuerdo &nbsp; */}
								{/* 								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
								Para mayor información sobre este último punto, se sugiere consultar el Acuerdo &nbsp;
								<a
									style={{wordBreak: 'break-all'}}
									target="_blank"
									href="https://repositoriodocumental.ine.mx/pdfjs-flipbook/web/viewer.html?file=/xmlui/bitstream/handle/123456789/141976/CGex202209-07-ap-12-Gaceta.pdf?sequence=7&isAllowed=y"
								>
									INE/CG616/2022.
								</a> */}
								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
							</span>
						</div>
					) : (
						''
					)}
					{tabSeleccionada == '12' && jsonGraficaNivelSE && (
						<div>
							<div id="grafica-nivel-ingreso" className="grafica_2"></div>
							<div className="pie_grafica" style={{textAlign: 'center', paddingLeft: '20px', paddingRight: '20px'}}></div>
							{/*<div>
								<br/>
								<span className="genero-informa">
									Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
									“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
									situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
									Para mayor información sobre este último punto, se sugiere consultar el Acuerdo &nbsp;
									<a
										style={{wordBreak: 'break-all'}}
										target="_blank"
										href="https://repositoriodocumental.ine.mx/pdfjs-flipbook/web/viewer.html?file=/xmlui/bitstream/handle/123456789/141976/CGex202209-07-ap-12-Gaceta.pdf?sequence=7&isAllowed=y"
									>
										INE/CG616/2022.
									</a>
								</span>
							</div>*/}
							<span className="genero-informa">
								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
							</span>
						</div>
					)}
					{tabSeleccionada == '13' && jsonGraficasCandidatos && jsonGraficasCandidatos.descJoven ? (
						<div>
							<div id="grafica-candidatura-jovenes" className="grafica_2"></div>
							<div className="pie_grafica" style={{textAlign: 'center', paddingLeft: '20px', paddingRight: '20px'}}>
								<span>
									Del total de las candidaturas que respondieron el cuestionario de identidad, {conteoTotal} se autoidentificaron como
									población joven.{' '}
								</span>{' '}
								<br />
							</div>
							<br />
							{<div id="grafica-barras-jovenes" className="grafica_2_barra"></div>}
							{/*<div className="">
                                <span className="genero-informa">
                                    Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el “Cuestionario de identidad”, este contiene preguntas en cinco dimensiones relativas a la autoidentificación con algún grupo en situación de discriminación.
                                </span>
                            </div>*/}
							<span className="genero-informa">
								{/* 								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
								Para mayor información sobre este último punto, se sugiere consultar el Acuerdo &nbsp; */}
								{/* 								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
								Para mayor información sobre este último punto, se sugiere consultar el Acuerdo &nbsp;
								<a
									style={{wordBreak: 'break-all'}}
									target="_blank"
									href="https://repositoriodocumental.ine.mx/pdfjs-flipbook/web/viewer.html?file=/xmlui/bitstream/handle/123456789/141976/CGex202209-07-ap-12-Gaceta.pdf?sequence=7&isAllowed=y"
								>
									INE/CG616/2022.
								</a> */}
								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
							</span>
						</div>
					) : (
						''
					)}
					{tabSeleccionada == '14' && jsonGraficasCandidatos && jsonGraficasCandidatos.descMayor ? (
						<div>
							<div id="grafica-candidatura-adultos" className="grafica_2"></div>
							<div className="pie_grafica" style={{textAlign: 'center', paddingLeft: '20px', paddingRight: '20px'}}>
								<span>
									Del total de las candidaturas que respondieron el cuestionario de identidad, {conteoTotal} se autoidentificaron como
									población mayor.
								</span>{' '}
								<br />
							</div>
							<br />
							{<div id="grafica-barras-adultos" className="grafica_2_barra"></div>}
							{/*<div className="">
                                <span className="genero-informa">
                                    Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el “Cuestionario de identidad”, este contiene preguntas en cinco dimensiones relativas a la autoidentificación con algún grupo en situación de discriminación.
                                </span>
                            </div>*/}
							<span className="genero-informa">
								{/* 								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
								Para mayor información sobre este último punto, se sugiere consultar el Acuerdo &nbsp; */}
								{/* 								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
								Para mayor información sobre este último punto, se sugiere consultar el Acuerdo &nbsp;
								<a
									style={{wordBreak: 'break-all'}}
									target="_blank"
									href="https://repositoriodocumental.ine.mx/pdfjs-flipbook/web/viewer.html?file=/xmlui/bitstream/handle/123456789/141976/CGex202209-07-ap-12-Gaceta.pdf?sequence=7&isAllowed=y"
								>
									INE/CG616/2022.
								</a> */}
								Los datos aquí presentados corresponden al número de personas candidatas (propietarias y suplentes) que respondieron el
								“Cuestionario de Identidad”, este contiene preguntas en ocho dimensiones relativas a la autoidentificación con algún grupo en
								situación de discriminación; lo que no implica necesariamente que hayan sido registradas en el marco de una acción afirmativa.
							</span>
						</div>
					) : (
						''
					)}
					{jsonGraficasAccionesAfirmativas && jsonGraficasAccionesAfirmativas[tabSeleccionada] && (
						<div className="pie_grafica_completo" style={{textAlign: 'justify', paddingLeft: '20px', paddingRight: '20px'}}>
							<div id="grafica-accionesAfirmativas-partidos"></div>
						</div>
					)}
				</Col>
			</Row>
			<Row>
				<Col span={24}>&nbsp;</Col>
			</Row>
			{/*jsonEtiquetas && (
				<Row className="consulta-text">
					<Col span={19}></Col>
					<Col span={5} style={{textAlign: 'center'}}>
						{jsonEtiquetas[0].etiquetaDescripcion}:&nbsp; <br />
						<span className="consulta-number">
							<Col style={{fontSize: '30px'}}>{jsonEtiquetas[0].cantidad}</Col>
						</span>
					</Col>
				</Row>
            )*/}
		</div>
	);
};

export default Graphics;
