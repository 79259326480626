import React from 'react';
import CredencialFooter from '../../assets/img/credencial-footer.jpg';
import BgFooter from '../../assets/img/bg-footer.png';
import WCAGFooter from '../../assets/img/accesibilidad.svg';
import IgualdadFooter from '../../assets/img/Igualdad-Laboral.png';
import MejoresFooter from '../../assets/img/LOGO_HRC_EQUIDAD_LGBTQ.png';
import Candidaturas2022 from '../../assets/img/CyC2022.svg';
import CandidaturasLocales2021 from '../../assets/img/Candidaturas-locales.png';
import Candidatos2021 from '../../assets/img/Candidatos-2021.svg';
import ViolenciaDigital from '../../assets/img/violencia-digital-506x187.jpg';
import useDataContext from '../../context/useDataContext';
import {Divider} from 'antd';

const Footer = (props) => {
	const {
		jsonLigasConsulta
	} = useDataContext();

	return (
		<footer>
			<div className="container candidatos">
				<div className="clearfix">&nbsp;</div>
				<div className="col-xs-12 text-left ">
					La presente sección se actualizará conforme a los plazos establecidos en el acuerdo &nbsp;
					<a
						style={{wordBreak: 'break-all', fontWeight: 'bold'}}
						target="_blank"
						href="https://repositoriodocumental.ine.mx/pdfjs-flipbook/web/viewer.html?file=/xmlui/bitstream/handle/123456789/141976/CGex202209-07-ap-12-Gaceta.pdf?sequence=7&isAllowed=y"
					>
						INE/CG616/2022.
					</a>
				</div>
				<div className="clearfix">&nbsp;</div>
			</div>
			<div className="container candidatos">
				<Divider />
				{/* <div className="col-xs-4 col-md-3 col-md-offset-0"></div> */}
				<div className="col-xs-12 col-md-6 col-md-offset-0">
					<div className="contenedor-candidatos-2018 ">
						<a
							href={jsonLigasConsulta && jsonLigasConsulta.rutaCYCCLocales}
							target="_blank"
							title="Sistema Candidatas y Candidatos, Conóceles Locales"
						>
							<img
								alt="Sistema Candidatas y Candidatos, Conóceles Locales"
								src={CandidaturasLocales2021}
								width="100%"
								className="banner-2018-responsive "
							/>
						</a>
					</div>
				</div>
				<div className="col-xs-12 col-md-6 col-md-offset-0">
					<div className="contenedor-candidatos-2018 ">
						<a href="https://igualdad.ine.mx/violencia-digital-y-mediatica/" target="_blank" title="Violencia digital y mediática">
							<img alt="violencia digital y mediática" src={ViolenciaDigital} width="100%" className="banner-2018-responsive " />
						</a>
					</div>
				</div>
				<div className="clearfix">&nbsp;</div>
				<div>
					<label></label>
				</div>
			</div>
			<div className="container candidatos"></div>
			<section className="footer">
				<div className="container">
					<div className="row">
						{/*<div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                            <label>Únete a nuestra comunidad</label>
                            <a href="https://portal.ine.mx/comunidad-ine" target="_blank" title="Únete a nuestra comunidad">
                                <img className="img-responsive" alt="Únete a nuestra comunidad" src={BgFooter} />
                            </a>
                            <label>Declaración de Accesibilidad WCAG 2.0</label>
                            <a href="https://www.hearcolors.com.mx/SelloAccesibilidad.php?num_clte=I9N73E8L" target="_blank" title="Únete a nuestra comunidad">
                                <img className="img-responsive" alt="Únete a nuestra comunidad" src={WCAGFooter} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                            <label>Ir a la sección de credencial para votar</label>
                            <a href="https://portal.ine.mx/credencial" target="_blank" title="Ir a la sección de credencial para votar">
                                <img className="img-responsive" alt="Ir a la sección de credencial para votar" src={CredencialFooter} />
                            </a>
                        </div>*/}
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
							<div className="row">
								<div className="col-sm-12 col-md-6 col-lg-4">
									<label>Páginas principales</label>
									<ul>
										<li>
											<a href="https://portal.ine.mx/sobre-el-ine/">Sobre el INE</a>
										</li>
										<li>
											<a href="https://portal.ine.mx/credencial/">Credencial para votar</a>
										</li>
										<li>
											<a href="https://portal.ine.mx/voto-y-elecciones/">Voto y Elecciones</a>
										</li>
										<li>
											<a href="https://portal.ine.mx/actores-politicos/">Actores Políticos</a>
										</li>
										<li>
											<a href="https://portal.ine.mx/cultura-civica/">Cultura cívica</a>
										</li>
										<li>
											<a href="https://portal.ine.mx/comunidad-ine/">Trabaja en el INE</a>
										</li>
										<li>
											<a href="https://portal.ine.mx/internacional/">Internacional</a>
										</li>
										<li>
											<a href="https://portal.ine.mx/voto-y-elecciones/fiscalizacion/">Fiscalización</a>
										</li>
										<li>
											<a href="https://portal.ine.mx/estructura-ine/">Estructura INE</a>
										</li>
										<li>
											<a href="https://portal.ine.mx/mapa-de-sitio/">Mapa del sitio</a>
										</li>
									</ul>
								</div>
								<div className="col-sm-12 col-md-6 col-lg-4">
									<label>Sitios de Interés</label>
									<ul>
										<li>
											<a href="https://centralelectoral.ine.mx/">Central Electoral</a>
										</li>
										<li>
											<a href="https://norma.ine.mx/">Norma INE</a>
										</li>
										<li>
											<a href="https://portal.ine.mx/gaceta-electoral/">Gaceta Electoral</a>
										</li>
										<li>
											<a href="https://igualdad.ine.mx/">Igualdad</a>
										</li>
										<li>
											<a href="https://www.votoextranjero.mx/">Voto en el Extranjero</a>
										</li>
										<li>
											<a href="https://portal.ine.mx/servicios-ine/">Servicios</a>
										</li>
										<li>
											<a href="https://portal.ine.mx/conoce-el-codigo-de-etica-de-la-funcion-publica-electoral-y-el-codigo-de-conducta-del-ine/">
												Código de Ética y Conducta
											</a>
										</li>
										<li>
											<a href="https://directorio.ine.mx/">Directorio</a>
										</li>
										<li>
											<a href="https://www.te.gob.mx/">TEPJF</a>
										</li>
										<li>
											<a href="https://www.fgr.org.mx/">FGR</a>
										</li>
										<li>
											<a href="https://www.fgr.org.mx/swb/fisel">FISEL</a>
										</li>
										<li>
											<a href="https://portalanterior.ine.mx/portal/">Sitio anterior del INE</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="sub-footer">
				<div className="row text-center color-fondo">
					<div className="col-xs-12 col-sm-12">
						<div className="col-xs-12 col-sm-4" style={{padding: '1%'}}>
							<a href="https://www.hearcolors.com.mx/SelloAccesibilidad.php?num_clte=I9N73E8L" target="_blank" title="Únete a nuestra comunidad">
								<div className="col-xs-3 col-sm-3">
									<img className="img-hearcolors" alt="Únete a nuestra comunidad" src={WCAGFooter} />
								</div>
								<div className="col-xs-8 col-sm-8">
									<label className="text-hearcolors">Declaración de Accesibilidad WCAG 2.0</label>
								</div>
							</a>
						</div>
						<div className="col-xs-12 col-sm-4">
							<img className="img-hearcolors" src={IgualdadFooter} height={118} width={93} />
						</div>
						<div className="col-xs-12 col-sm-4">
							<img className="img-hearcolors" src={MejoresFooter} height={113} width={187} />
						</div>
					</div>
				</div>
				<hr className="linea-rosa" />
				<div className="container">
					<div className="row text-center">
						<div className="col-xs-12 col-sm-4">
							<label>INE</label>
							<p className="text-left">
								© Derechos Reservados, Instituto Nacional Electoral,
								<br />
								México.
							</p>
						</div>
						<div className="col-xs-12 col-sm-4">
							<label>Oficinas Centrales</label>
							<p className="text-left">
								Viaducto Tlalpan No. 100 Col. Arenal Tepepan, Alcaldía
								<br />
								Tlalpan, C.P. 14610, Ciudad de México.
							</p>
						</div>
						<div className="col-xs-12 col-sm-4">
							<label>Llámanos</label>
							<p className="text-left">
								Desde cualquier parte del país sin costo: <strong>800 433 2000.</strong>
								<br />
								Desde Estados Unidos sin costo: <strong>1 (866) 986 8306.</strong> Desde
								<br />
								otros países por cobrar: <strong>+52 (55) 5481 9897.</strong>
							</p>
						</div>
						<div className="col-xs-12">
							<span id="version">
								{process.env.REACT_APP_VERSION_APLICACION}&nbsp;{process.env.REACT_APP_REVISION_APLICACION}
							</span>
						</div>
					</div>
				</div>
			</section>
		</footer>
	);
};
export default Footer;
