import './App.css';
import {ConfigProvider} from 'antd';
import esES from 'antd/es/locale/es_ES';
import BusquedaProvider from './providers/BusquedaProvider';
import DataProvider from './providers/DataProvider';
import Home from './components/pages/Home';
import {Switch, Route} from 'react-router';
import Ficha from './components/pages/Ficha';
import PreguntasFrecuentes from './components/pages/PreguntasFrecuentes';
import Diputados from './components/pages/Diputados';
import Senadores from './components/pages/Senadores';
import Gubernatura from './components/pages/Gubernatura';
import PresidenteMunicipal from './components/pages/PresidenteMunicipal';
import Presidente from './components/pages/Presidente';
import Error404 from './components/pages/Error404';

function App() {
	return (
		<ConfigProvider locale={esES}>
			<DataProvider>
				<BusquedaProvider>
					<Switch>
						<Route path="/" component={Home} exact />
						{/*<Route path="/" component={EnConstruccion} exact />*/}
						<Route path="/detalleCandidato/:idCandidato/:idTipoCandidatura" render={(props) => <Ficha {...props} />} exact />
						<Route path="/preguntasFrecuentes" render={(props) => <PreguntasFrecuentes {...props} />} exact />
						<Route path="/diputados/:idAsociacion/:tipoAsociacion" render={(props) => <Diputados {...props} />} exact />
						<Route path="/gubernatura/:idAsociacion/:tipoAsociacion/:idEntidad" render={(props) => <Gubernatura {...props} />} exact />
						<Route path="/presidenteMunicipal/:idAsociacion/:tipoAsociacion" render={(props) => <PresidenteMunicipal {...props} />} exact />
						<Route path="/senadores/:idAsociacion/:tipoAsociacion" render={(props) => <Senadores {...props} />} exact />
						<Route path="/presidente/:idAsociacion/:tipoAsociacion" render={(props) => <Presidente {...props} />} exact />
						<Route render={(props) => <Error404 {...props} />} />
					</Switch>
				</BusquedaProvider>
			</DataProvider>
		</ConfigProvider>
	);
}

export default App;
