import React from "react";
import Icon from '@ant-design/icons';
import { Spin as SpinAntd } from 'antd';
import LoaderGif from "../../assets/img/loader.gif";

function LoaderGifComponent(props) {
    return (
        <img src={LoaderGif} alt="Otter dancing with a fish" style={{ width: '70px', marginLeft: '-23px', marginTop: '-23px' }} />
    )
}

export default function Spin(props) {
    const SpinIcon = props => <Icon component={LoaderGifComponent} {...props} />;
    const antIcon = <SpinIcon style={{ fontSize: 24 }} spin />;
    return <SpinAntd indicator={antIcon} {...props} />
}