import React, {useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import IneSeccion1 from '../../assets/img/Grupo_5020.svg';
import IneSeccion2 from '../../assets/img/Grupo_5022.svg';
import IneSeccion3 from '../../assets/img/Grupo_5021.svg';
import IneSeccion4 from '../../assets/img/Grupo_5023.svg';
import {/*Icon,*/ QuestionCircleFilled, CloseCircleOutlined /*, DownloadOutlined*/, SearchOutlined, ClearOutlined, InfoCircleFilled} from '@ant-design/icons';
import {
	Form,
	/*List, Skeleton,*/ Card,
	Table,
	Button,
	Select,
	Input,
	InputNumber,
	/*Tooltip,*/ Modal,
	Radio /*Typography, Space,*/,
	Grid,
	Row,
	Col,
	Divider,
	Checkbox,
	Tooltip,
	Typography,
} from 'antd';
import useBusquedaContext from '../../context/useBusquedaContext';
import useDataContext from '../../context/useDataContext';
import ExportCSV from '../../utils/ExportCSV';
import Spin from '../ui/Spin';
import Graphics from './Graphics';
import moment from 'moment';
import 'moment/locale/es';
import {set} from 'react-ga';

const {TextArea} = Input;
const {useBreakpoint} = Grid;
const {Option} = Select;
//const { Search } = Input;
const {Text, Title} = Typography;
const useMountEffect = (fun) => useEffect(fun, []);
var fechaCreacion = moment().format('DD-MM-YYYY');
var fechaActualizacion = moment('04-18-2022').format('DD-MM-YYYY');
var fechaActualizacion1 = moment('04-13-2022').format('DD-MM-YYYY');
//console.log("fechas comparación",fechaActualizacion >= fechaCreacion ,fechaCreacion, fechaActualizacion, fechaActualizacion <= fechaCreacion)

const tipoCandidaturaDurango = [
	{
		idTipoCandidatura: 6,
		nombreCorto: 'Gubernatura',
	},
	{
		idTipoCandidatura: 9,
		nombreCorto: 'Presidencia Municipal',
	},
];

const tipoCandidaturaTamaulipas = [
	{
		idTipoCandidatura: 2,
		nombreCorto: 'Senaduría mayoría relativa',
	},
];

const tipoCandidaturaOtras = [
	{
		idTipoCandidatura: 6,
		nombreCorto: 'Gubernatura',
	},
];

const ModalDos = ({onClose}) => {
	const modalStyle = {
		position: 'fixed',
		top: '0',
		left: '0',
		width: '100%',
		height: '100%',
		background: 'rgba(0, 0, 0, 0.5)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: '100',
	};

	const contentStyle = {
		background: '#fff',
		padding: '25px',
		borderRadius: '6px',
		boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
		maxWidth: '80%',
		width: '80%',
		textAlign: 'left',
		maxHeight: '80%',
	};

	const textStyle = {
		color: 'balck',
		fontSize: '3vh',
	};

	const botonStyle = {
		maxWidth: '15vw',
		minWidth: '5vw',
		fontSize: '2vh',
		marginLeft: 'auto',
		display: 'block',
		backgroundColor: '#db2491',
		color: 'white',
		border: '1px solid #db2491',
	};

	return (
		<div style={modalStyle}>
			<div style={contentStyle}>
				<p style={textStyle}>
					Conoce el listado de candidaturas aprobadas por el Consejo General.
					<br />
					<br />A partir del 20 de marzo de 2024 podrás consultar la información curricular y de identidad de las personas candidatas.
				</p>
				<button style={botonStyle} onClick={onClose}>
					Cerrar
				</button>
			</div>
		</div>
	);
};

const Busqueda = (props) => {
	const [showModal, setShowModal] = useState(true);

	const handleCloseModal = () => {
		setShowModal(false);
	};

	useEffect(() => {
		const currentDate = new Date();
		//const popupDeadline = new Date('2024-03-19');
		const popupDeadline = new Date('2024-03-01');

		// Mostrar el modal solo si la fecha actual es anterior a la fecha límite
		if (showModal && currentDate < popupDeadline) {
			setShowModal(true);
		} else {
			setShowModal(false);
		}
	}, [showModal]);

	const [form] = Form.useForm();
	const screens = useBreakpoint();

	const [expandir, setExpandir] = useState(false);
	const [actoresPoliticosFilter, setActoresPoliticosFilter] = useState([]);
	const [dataCandidatos, setDataCandidatos] = useState([]);
	const [consultarLocal, setConsultarLocal] = useState(false);

	const [modalVisible, setModalVisible] = useState(false);

	const {
		tiposCandidaturas,
		setTiposCandidaturas,
		entidades,
		setEntidades,
		circunscripciones,
		setCircunscripciones,
		gradosAcademicos,
		setGradosAcademicos,
		grado,
		setGrado,
		rangosEdades,
		setRangosEdades,
		generos,
		setGeneros,
		actoresPoliticos,
		setActoresPoliticos,
		secciones,
		setSecciones,
		candidatosDMR,
		setCandidatosDMR,
		candidatosDRP,
		setCandidatosDRP,
		candidatosSMR,
		setCandidatosSMR,
		candidatosSRP,
		setCandidatosSRP,
		candidatosPresidente,
		setCandidatosPresidente,
		jsonCargados,
		setJsonCargados,
		entidadesDetalleProceso,
		setEntidadesDetalleProceso,
		candidatosGubernatura,
		setCandidatosGubernatura,
		candidatosPresidenteMunicipal,
		setCandidatosPresidenteMunicipal,
		municipios,
		setMunicipios,
		regresar,
		setRegresar,
		jsonGubernaturaGraficaPorcentaje,
		setJonGubernaturaGraficaPorcentaje,
		actoresPoliticosPresidenteMunicipal,
		setActoresPoliticosPresidenteMunicipal,
		actoresPoliticosGubernatura,
		setActoresPoliticosGubernatura,
		actoresPoliticosDMR,
		setActoresPoliticosDMR,
		actoresPoliticosSMR,
		setActoresPoliticosSMR,
		actoresPoliticosDRP,
		setActoresPoliticosDRP,
		actoresPoliticosSRP,
		setActoresPoliticosSRP,
		actoresPoliticosPresidente,
		setActoresPoliticosPresidente,
		jsonEtiquetas,
	} = useDataContext();
	//console.log("Regresar11",   candidatosPresidenteMunicipal);
	const {
		tipoCandidatura,
		setTipoCandidatura,
		descTipoCandidatura,
		setDescTipoCandidatura,
		entidad,
		setEntidad,
		circunscripcion,
		setCircunscripcion,
		seccionElectoral,
		setSeccionElectoral,
		estadosCircunscripcion,
		setEstadosCircunscripcion,
		gradoAcademico,
		setGradoAcademico,
		rangoEdad,
		setRangoEdad,
		genero,
		setGenero,
		actorPolitico,
		setActorPolitico,
		consultar,
		setConsultar,
		paginacion,
		setPaginacion,
		paginacionSup,
		setPaginacionSup,
		tipoCandidatosBusqueda,
		setTipoCandidatosBusqueda,
		tabSeleccionada,
		setTabSeleccionada,
		cargadoExcel,
		setCargandoExcel,
		limpiarFiltros,
		setLimpiarFiltros,
		nombrePropietario,
		setNombrePropietario,
		nombreSuplente,
		setNombreSuplente,
		entidadDetalle,
		setEntidadDetalle,
		descEntidadDetalle,
		setDescEntidadDetalle,
		deshabilitar,
		setDeshabilitar,
		deshabilitarSeccion,
		setDeshabilitarSeccion,
		deshabilitarCircunscripcion,
		setDeshabilitarCircunscripcion,
		idTipoCandidatura,
		setIdTipoCandidatura,
		municipio,
		setMunicipio,
	} = useBusquedaContext();

	const contenedorBusquedaRef = useRef(null);
	const contenedorResultadoRef = useRef(null);
	const executeScroll = () => contenedorBusquedaRef.current.scrollIntoView();
	const executeScrollResultado = () => contenedorResultadoRef.current.scrollIntoView();
	const [accionAfirmativa, setAccionAfirmativa] = useState(false);

	const [cambioTam, setCambioTam] = useState(false);
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		if (width <= 850) {
			setCambioTam(true);
		}

		if (width > 850) {
			setCambioTam(false);
		}
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [width]);

	const handleResize = () => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	};

	useEffect(() => {
		////console.log(screens);
		let expandir = false;
		Object.entries(screens)
			.filter((screen) => !screen[1] && screen[0] === 'md')
			.map((screen) => (expandir |= true));
		setExpandir(expandir);
	}, [screens]);

	useEffect(() => {
		if (jsonCargados) buscar();
	}, []);

	useEffect(() => {
		setActoresPoliticosFilter(actoresPoliticos);
	}, [actoresPoliticos]);

	useEffect(() => {
		if (consultarLocal)
			//  console.log("entro en el filtro1", consultarLocal)
			buscar();
	}, [
		consultarLocal,
		candidatosDMR,
		candidatosDRP, //candidatosGubernatura, candidatosPresidenteMunicipal,
		candidatosSMR,
		candidatosSRP,
		candidatosPresidente,
	]);

	useEffect(() => {
		if (
			consultar &&
			jsonCargados &&
			(candidatosDMR.length > 0 ||
				candidatosDRP.length > 0 ||
				//candidatosGubernatura.length > 0 ||
				//candidatosPresidenteMunicipal.length > 0 ||
				candidatosSMR.length > 0 ||
				candidatosSRP.length > 0 ||
				candidatosPresidente.length > 0)
		)
			setConsultarLocal(true);
	}, [
		consultar,
		candidatosDMR,
		candidatosDRP, //candidatosGubernatura, candidatosPresidenteMunicipal,
		jsonCargados,
		candidatosSMR,
		candidatosSRP,
		candidatosPresidente,
	]);

	useEffect(() => {
		////console.log(consultarLocal);
		if (
			jsonCargados &&
			(candidatosDMR.length > 0 ||
				candidatosDRP.length > 0 ||
				//candidatosGubernatura.length > 0 ||
				//candidatosPresidenteMunicipal.length > 0 ||
				candidatosSRP.length > 0 ||
				candidatosPresidente.length > 0 ||
				candidatosSMR.length > 0)
		) {
			if (!consultar) executeScroll();
			// setConsultarLocal(true);
		}
	}, [
		candidatosDMR,
		candidatosDRP, //candidatosGubernatura, candidatosPresidenteMunicipal,
		jsonCargados,
		candidatosSMR,
		candidatosSRP,
		candidatosPresidente,
	]);

	useEffect(() => {
		////console.log("tipoCandidatosBusqueda => ", tipoCandidatosBusqueda)
		buscar();
	}, [tipoCandidatosBusqueda]);

	useEffect(() => {
		if (tipoCandidatura && tipoCandidatura != '' && tiposCandidaturas.length > 0) {
			//    console.log("ENTRE A USE DE ACTORES", tiposCandidaturas, tipoCandidatura ==2,  tipoCandidatura ==='2',  tipoCandidatura =='2')
			let actoresPoliticosFilter = [];

			setDescTipoCandidatura(tiposCandidaturas.find((candidatura) => candidatura.idTipoCandidatura == tipoCandidatura).nombreCorto);

			let tipoCandidaturaR = tipoCandidatura;
			//  console.log("ENTRE A USE DE ACTORES", tipoCandidaturaR >1, tipoCandidaturaR ==2,  tipoCandidaturaR ==='2',  tipoCandidaturaR =='2')

			if (tipoCandidaturaR == 1) actoresPoliticosFilter = actoresPoliticosPresidente;
			else if (tipoCandidaturaR == 2) actoresPoliticosFilter = actoresPoliticosSMR;
			else if (tipoCandidaturaR == 3) actoresPoliticosFilter = actoresPoliticosSRP;
			else if (tipoCandidaturaR == 4) actoresPoliticosFilter = actoresPoliticosDMR;
			else if (tipoCandidaturaR == 5) actoresPoliticosFilter = actoresPoliticosDRP;

			/*
			if (actoresPoliticosSMR) actoresPoliticosFilter = actoresPoliticosSMR;
			if (actoresPoliticosSRP) actoresPoliticosFilter = actoresPoliticosSRP;
			if (actoresPoliticosDMR) actoresPoliticosFilter = actoresPoliticosDMR;
			if (actoresPoliticosDRP) actoresPoliticosFilter = actoresPoliticosDRP;
			if (actoresPoliticosPresidente) actoresPoliticosFilter = actoresPoliticosPresidente;

			if (tipoCandidaturaR == 7)
                actoresPoliticosFilter = actoresPoliticosDMR;
            else if (tipoCandidaturaR == 8)
                actoresPoliticosFilter = actoresPoliticosDRP;
            else if (tipoCandidaturaR == 6)
                actoresPoliticosFilter = actoresPoliticosGubernatura;
            else if (tipoCandidaturaR == 9)
                actoresPoliticosFilter = actoresPoliticosPresidenteMunicipal;
           */
			setActoresPoliticosFilter(actoresPoliticosFilter);
		}
	}, [tipoCandidatura, tiposCandidaturas, actoresPoliticosSMR, actoresPoliticosSRP, actoresPoliticosDMR, actoresPoliticosDRP, actoresPoliticosPresidente]);

	const seleccionaCircunscripcion = (circunscripcion) => {
		setCircunscripcion(circunscripcion);
		if (circunscripcion != null && circunscripcion != '') {
			let resultados = entidades.filter((entidad) => entidad.idCircunscripcion == circunscripcion);
			let nombreEstadosCircunscripcion = `Entidades que conforman la Circunscripción ${circunscripcion}: `;
			resultados.forEach((estado, index) => {
				////console.log(estado);
				nombreEstadosCircunscripcion += estado.nombreEstado;
				if (index < resultados.length - 2) nombreEstadosCircunscripcion += ', ';
				else if (index == resultados.length - 2) nombreEstadosCircunscripcion += ' y ';
			});
			setEstadosCircunscripcion(nombreEstadosCircunscripcion);
			setConsultarLocal(false);
			setDataCandidatos([]);
		} else setEstadosCircunscripcion('');
		setNombrePropietario(null);
		setNombreSuplente(null);
		setLimpiarFiltros(true);
	};
	// console.log("Actor politico1¨¨", actorPolitico)

	const seleccionaEntidad = (entidad) => {
		//setDescTipoCandidatura(tiposCandidaturas.find(candidatura => candidatura.idTipoCandidatura == tipoCandidatura).nombreCorto);
		limpiarFiltrosEntidad();
		if (entidad == 0) {
			setEntidad(null);
			form.resetFields(['accionAfirmativa']);
		} else {
			setEntidad(entidad);
		}
		setSeccionElectoral(null);
		setConsultarLocal(false);
		setDataCandidatos([]);
		//setTipoCandidatura(null);
		setMunicipio(null);
		setNombrePropietario(null);
		setNombreSuplente(null);
		setLimpiarFiltros(true);
		setCircunscripcion(null);
		// setTipoCandidatura(tipoCandidatura);
		setAccionAfirmativa(false);

		let circunscripcionesFilter;
		if (entidad != 0 && entidad != null && tipoCandidatura === '5') {
			circunscripcionesFilter = circunscripciones.filter((circunscripcion) => circunscripcion.idEstado == entidad);
			setCircunscripcion(circunscripcionesFilter[0].idCircunscripcion);

			let candidatosFiltrado = null;
			candidatosFiltrado = candidatosDRP;
			candidatosFiltrado = candidatosFiltrado.filter((candidato) => candidato.idCircunscripcionEleccion == circunscripcionesFilter[0].idCircunscripcion);
			if (candidatosFiltrado.length == '0') {
				setActoresPoliticosFilter([]);
			} else setActoresPoliticosFilter(actoresPoliticosDRP);
		}

		if (entidad == 0) {
			form.setFieldsValue({
				seccionElectoral: null,
				entidad: null,
				tipoCandidatura: tipoCandidatura,
				municipio: null,
			});
		} else {
			form.setFieldsValue({
				seccionElectoral: null,
				entidad: entidad,
				tipoCandidatura: tipoCandidatura,
				municipio: null,
			});
		}
	};

	const limpiarFiltrosEntidad = () => {
		setCircunscripcion(null);
		//setEntidad(null);
		setEstadosCircunscripcion(null);
		setSeccionElectoral(null);
		setActorPolitico('');
		setGradoAcademico(null);
		setGenero('');
		setRangoEdad(null);
		setNombrePropietario(null);
		setNombreSuplente(null);
		setAccionAfirmativa(false);
		form.resetFields(['accionAfirmativa']);
		form.setFieldsValue({
			//tipoCandidatura: null,
			circunscripcion: null,
			seccionElectoral: null,
			actorPolitico: '',
			gradoAcademico: null,
			genero: null,
			rangoEdad: null,
			municipio: null,
			accionAfirmativa: false,
		});
		setLimpiarFiltros(true);
	};

	const limpiarFiltrosTipoCandidaturaFunc = () => {
		setCircunscripcion(null);
		setEntidad(null);
		setEstadosCircunscripcion(null);
		setSeccionElectoral(null);
		setActorPolitico('');
		setTipoCandidatura(null);
		setGradoAcademico(null);
		setGenero('');
		setRangoEdad(null);
		setNombrePropietario(null);
		setNombreSuplente(null);
		setMunicipio(null);
		setAccionAfirmativa(false);
		//form.resetFields();
		form.resetFields(['accionAfirmativa']);
		form.setFieldsValue({
			entidad: null,
			circunscripcion: null,
			seccionElectoral: null,
			actorPolitico: '',
			gradoAcademico: null,
			genero: null,
			rangoEdad: null,
			accionAfirmativa: false,
		});
		setLimpiarFiltros(true);
	};

	const limpiarFiltrosFunc = () => {
		setCircunscripcion(null);
		setEntidad(null);
		setEstadosCircunscripcion(null);
		setSeccionElectoral(null);
		setActorPolitico('');
		setTipoCandidatura(null);
		setGradoAcademico(null);
		setGenero('');
		setRangoEdad(null);
		setNombrePropietario(null);
		setNombreSuplente(null);
		form.resetFields();
		form.setFieldsValue({
			tipoCandidatura: null,
			entidad: null,
			circunscripcion: null,
			seccionElectoral: null,
			actorPolitico: '',
			gradoAcademico: null,
			genero: null,
			rangoEdad: null,
		});
		setLimpiarFiltros(true);
		executeScroll();
	};

	const seleccionTipoCandidatura = (tipoCandidatura) => {
		//  console.log(tipoCandidatura , "tipo ENTRAR1", tiposCandidaturas, "tipos")
		if (!tipoCandidatura) {
			setLimpiarFiltros(true);
			return;
		}
		limpiarFiltrosTipoCandidaturaFunc();
		////console.log(tiposCandidaturas.find(candidatura => candidatura.idTipoCandidatura == tipoCandidatura));
		setDescTipoCandidatura(tiposCandidaturas.find((candidatura) => candidatura.idTipoCandidatura == tipoCandidatura).nombreCorto);
		let actoresPoliticosFilter = [];
		if (tipoCandidatura == 1) actoresPoliticosFilter = actoresPoliticosPresidente;
		else if (tipoCandidatura == 2) actoresPoliticosFilter = actoresPoliticosSMR;
		else if (tipoCandidatura == 3) actoresPoliticosFilter = actoresPoliticosSRP;
		else if (tipoCandidatura == 4) actoresPoliticosFilter = actoresPoliticosDMR;
		else if (tipoCandidatura == 5) {
			actoresPoliticosFilter = actoresPoliticosDRP;
			setActoresPoliticosFilter(actoresPoliticosFilter);
			/* 			let circunscripcionesFilter;
			if (entidad != null) {
				circunscripcionesFilter = circunscripciones.filter((circunscripcion) => circunscripcion.idEstado == entidad);

				setCircunscripcion(circunscripcionesFilter[0].idCircunscripcion);
			} */

			//if (circunscripcion != null || circunscripcion < 1) setCircunscripcion(1);
		} //else if (tipoCandidatura == 6) actoresPoliticosFilter = actoresPoliticosGubernatura;
		//else if (tipoCandidatura == 9) actoresPoliticosFilter = actoresPoliticosPresidenteMunicipal;

		setConsultarLocal(false);
		setDataCandidatos([]);
		setLimpiarFiltros(true);
		setTipoCandidatura(tipoCandidatura);
		setEntidad(null);

		form.setFieldsValue({
			tipoCandidatura: tipoCandidatura,
		});
	};

	const buscar = () => {
		if (!tipoCandidatura) return;
		let candidatosFiltrado;
		/*if (tipoCandidatura === '7') candidatosFiltrado = candidatosDMR;
		if (tipoCandidatura === '8') candidatosFiltrado = candidatosDRP;
		if (tipoCandidatura === '6') candidatosFiltrado = candidatosGubernatura;
		if (tipoCandidatura === '9') candidatosFiltrado = candidatosPresidenteMunicipal;*/

		//Se agrega para suplentes sin propietario.
		let suplentesSinPropietario = null;
		if (tipoCandidatura === '2') suplentesSinPropietario = candidatosSMR;
		if (tipoCandidatura === '3') suplentesSinPropietario = candidatosSRP;
		if (tipoCandidatura === '4') suplentesSinPropietario = candidatosDMR;
		if (tipoCandidatura === '5') suplentesSinPropietario = candidatosDRP;
		if (tipoCandidatura != null && tipoCandidatura != '1') {
			suplentesSinPropietario = suplentesSinPropietario.filter(
				(suplente) => suplente.tipoCandidato === 'S' && (suplente.nombreSuplente === 'Sin registro' || suplente.nombreCandidato === 'Sin registro')
			);
		}

		if (tipoCandidatura === '1') candidatosFiltrado = candidatosPresidente;
		if (tipoCandidatura === '2') candidatosFiltrado = candidatosSMR;
		if (tipoCandidatura === '3') candidatosFiltrado = candidatosSRP;
		if (tipoCandidatura === '4') candidatosFiltrado = candidatosDMR;
		if (tipoCandidatura === '5') candidatosFiltrado = candidatosDRP;
		if (tipoCandidatura != null) {
			candidatosFiltrado = candidatosFiltrado.filter((candidato) => candidato.tipoCandidato === tipoCandidatosBusqueda);

			if (suplentesSinPropietario !== null) {
				for (let ssp=0; ssp<suplentesSinPropietario.length; ssp++) {
					if (suplentesSinPropietario[ssp].nombreSuplente === 'Sin registro') {
						suplentesSinPropietario[ssp].nombreSuplente = suplentesSinPropietario[ssp].nombreCandidato;
						suplentesSinPropietario[ssp].nombreCandidato = 'Sin registro';
						suplentesSinPropietario[ssp].idCandidatoSup = suplentesSinPropietario[ssp].idCandidato;
						suplentesSinPropietario[ssp].idCandidato = undefined;
					}
					candidatosFiltrado.push(suplentesSinPropietario[ssp]);
				}
			}
			// TODO RECIENTEMENTE SE COMENTA
			//candidatosFiltrado = candidatosFiltrado.filter(candidato => candidato.idTipoCandidatura == tipoCandidatura);
			if (entidad != null && entidad != '' && tipoCandidatura != 5 && tipoCandidatura != 3) {
				if (tipoCandidatura === '1') candidatosFiltrado = candidatosFiltrado.filter((candidato) => candidato.idEstadoEleccion == 0);
				else candidatosFiltrado = candidatosFiltrado.filter((candidato) => candidato.idEstadoEleccion == entidad);
				let seccionesFiltradas = null;
				if (seccionElectoral != null) {
					//console.log("SECCIONES", secciones, "ENTIDAD", entidad, "elegÃ­ ", seccionElectoral);
					seccionesFiltradas = secciones.filter((seccion) => seccion.idEstado == entidad && seccion.seccion == seccionElectoral);
					if (seccionesFiltradas != null && (seccionesFiltradas.length == 0 || seccionesFiltradas.length > 1)) {
						setDataCandidatos([]);
						setConsultarLocal(false);
						//executeScroll();
						//contenedorBusquedaRef.current.scrollIntoView()
						return;
					}
				}

				//console.log("SECCIONES FILTRADAS", seccionesFiltradas);
				if (seccionesFiltradas && seccionesFiltradas.length > 0)
					candidatosFiltrado = candidatosFiltrado.filter(
						(candidato) =>
							candidato.idDistritoEleccion === seccionesFiltradas[0].idDistritoFederal &&
							candidato.idEstadoEleccion === seccionesFiltradas[0].idEstado,
					);

				if (municipio != null && municipio != '') {
					//console.log("MUNICIPIOS", municipio, candidatosFiltrado);
					candidatosFiltrado = candidatosFiltrado.filter((candidato) => candidato.nombreMunicipio == municipio);
				}
			}
			//console.log('FILTRADAS', circunscripcion);

			if (tipoCandidatura == 1) {
				//console.log("Entra a candidatura 1: "+tipoCandidatura);
				candidatosFiltrado = candidatosFiltrado.filter((candidato) => candidato.idEstadoEleccion == 0);
			}

			if (tipoCandidatura == 3) {
				//console.log("Entra en 3");
				candidatosFiltrado = candidatosFiltrado;
			}

			if (tipoCandidatura == 5) {
				if (circunscripcion != null && circunscripcion != '')
					candidatosFiltrado = candidatosFiltrado.filter((candidato) => candidato.idCircunscripcionEleccion == circunscripcion);
			}

			/* 			if (tipoCandidatura === 5) {
				if (circunscripcion != null && circunscripcion != '')
					candidatosFiltrado = candidatosFiltrado.filter((candidato) => candidato.idCircunscripcionEleccion == circunscripcion);
			} */

			// //console.log("circuncripcion1", circunscripcion)
		} else candidatosFiltrado = [];
		/*//console.log(gradoAcademico);
        //console.log(candidatosFiltrado);*/
		if (gradoAcademico != null && gradoAcademico != '') {
			if (gradoAcademico == 1)
				candidatosFiltrado = candidatosFiltrado.filter(
					(candidato) =>
						((candidato.idGrado != null) != candidato.idGrado) != undefined &&
						(candidato.idGrado == 1 || candidato.idGrado == 2 || candidato.idGrado == 3 || candidato.idGrado == 4),
				);
			if (gradoAcademico == 2)
				candidatosFiltrado = candidatosFiltrado.filter(
					(candidato) =>
						((candidato.idGrado != null) != candidato.idGrado) != undefined &&
						(candidato.idGrado == 5 || candidato.idGrado == 6 || candidato.idGrado == 7 || candidato.idGrado == 8),
				);
			if (gradoAcademico == 3)
				candidatosFiltrado = candidatosFiltrado.filter(
					(candidato) =>
						((candidato.idGrado != null) != candidato.idGrado) != undefined &&
						(candidato.idGrado == 9 || candidato.idGrado == 10 || candidato.idGrado == 11 || candidato.idGrado == 12),
				);
			if (gradoAcademico == 4)
				candidatosFiltrado = candidatosFiltrado.filter(
					(candidato) =>
						((candidato.idGrado != null) != candidato.idGrado) != undefined &&
						(candidato.idGrado == 13 || candidato.idGrado == 14 || candidato.idGrado == 15 || candidato.idGrado == 16),
				);
			if (gradoAcademico == 5)
				candidatosFiltrado = candidatosFiltrado.filter(
					(candidato) =>
						((candidato.idGrado != null) != candidato.idGrado) != undefined &&
						(candidato.idGrado == 17 || candidato.idGrado == 18 || candidato.idGrado == 19 || candidato.idGrado == 20),
				);
			if (gradoAcademico == 6)
				candidatosFiltrado = candidatosFiltrado.filter(
					(candidato) =>
						((candidato.idGrado != null) != candidato.idGrado) != undefined &&
						(candidato.idGrado == 21 || candidato.idGrado == 22 || candidato.idGrado == 23),
				);
			if (gradoAcademico == 7)
				candidatosFiltrado = candidatosFiltrado.filter(
					(candidato) =>
						((candidato.idGrado != null) != candidato.idGrado) != undefined &&
						(candidato.idGrado == 24 || candidato.idGrado == 25 || candidato.idGrado == 26),
				);
			if (gradoAcademico == 8)
				candidatosFiltrado = candidatosFiltrado.filter(
					(candidato) =>
						((candidato.idGrado != null) != candidato.idGrado) != undefined &&
						(candidato.idGrado == 27 || candidato.idGrado == 28 || candidato.idGrado == 29),
				);
			if (gradoAcademico == 9)
				candidatosFiltrado = candidatosFiltrado.filter(
					(candidato) =>
						((candidato.idGrado != null) != candidato.idGrado) != undefined &&
						(candidato.idGrado == 30 || candidato.idGrado == 31 || candidato.idGrado == 32),
				);
		}
		if (rangoEdad != null && rangoEdad != '') {
			////console.log(rangoEdad);
			let tokenizacion = rangoEdad.split('-');
			let limiteInferior = tokenizacion[0];
			let limiteSuperior = tokenizacion[1];
			if (limiteInferior != null && limiteInferior != undefined && limiteSuperior != null && limiteSuperior != undefined)
				candidatosFiltrado = candidatosFiltrado.filter(
					(candidato) => candidato.edad >= parseInt(limiteInferior) && candidato.edad <= parseInt(limiteSuperior),
				);
			else if (limiteInferior != null && limiteInferior != undefined)
				candidatosFiltrado = candidatosFiltrado.filter((candidato) => candidato.edad >= parseInt(limiteInferior));
		}
		if (genero !== '') candidatosFiltrado = candidatosFiltrado.filter((candidato) => candidato.sexo == genero);
		if (actorPolitico !== '') {
			let tokenizacion = actorPolitico.split('-');
			let asociacion = tokenizacion[0];
			let tipoAsociacion = tokenizacion[1];
			if (asociacion != null && asociacion != undefined && tipoAsociacion != null && tipoAsociacion != null)
				candidatosFiltrado = candidatosFiltrado.filter(
					(candidato) => candidato.idAsociacion == asociacion && candidato.tipoAsociacion == tipoAsociacion,
				);
		}

		if (nombrePropietario && nombrePropietario != '') {
			candidatosFiltrado = candidatosFiltrado.filter(
				(candidato) => candidato.nombreCandidato.includes(nombrePropietario.toUpperCase()) ||
											 candidato.nombreCandidato.includes(nombrePropietario.charAt(0).toUpperCase() + nombrePropietario.slice(1).toLowerCase())
			);
		}
		if (nombreSuplente && nombreSuplente != '') {
			candidatosFiltrado = candidatosFiltrado.filter(
				(candidato) => candidato.nombreSuplente && candidato.nombreSuplente != '' &&
											 (candidato.nombreSuplente.includes(nombreSuplente.toUpperCase()) ||
											  candidato.nombreSuplente.includes(nombreSuplente.charAt(0).toUpperCase() + nombreSuplente.slice(1).toLowerCase()))
			);
		}
		/*candidatosFiltrado.map(candidato => {
            candidato = {
                ...candidato,
                "entidad": entidades.find(entidad => candidato.idEstadoEleccion == candidato.idEstadoEleccion).nombreEstado
            };
        });*/
		////console.log(candidatosFiltrado);

		if (accionAfirmativa !== false)
			candidatosFiltrado = candidatosFiltrado.filter(
				(candidato) => candidato.candidaturaAccionAfirmativa != null && candidato.candidaturaAccionAfirmativa != 0,
			);

		if (jsonCargados && !limpiarFiltros) {
			////console.log("Ejecuta scroll");
			//executeScroll();
			setTimeout(
				function () {
					executeScrollResultado();
				}.bind(this),
				200,
			);
			setTimeout(
				function () {
					setDataCandidatos(candidatosFiltrado);
					setConsultarLocal(false);
				}.bind(this),
				200,
			);
		}
	};

	const buscarPropietario = (value) => {
		// console.log("entro en el filtro")
		setNombrePropietario(value);
		setTimeout(
			function () {
				setConsultarLocal(true);
			}.bind(this),
			200,
		);
	};

	const buscarSuplente = (value) => {
		setNombreSuplente(value);
		setTimeout(
			function () {
				setConsultarLocal(true);
			}.bind(this),
			200,
		);
	};

	const handleTableChange = (paginacion) => {
		setPaginacion(paginacion);
	};

	const handleTableChangeSup = (paginacion) => {
		setPaginacionSup(paginacion);
	};

	const onChangeTab = (tabKey) => {
		////console.log(tabKey);
		setTabSeleccionada(tabKey);
		switch (tabKey) {
			case '1':
				setTipoCandidatosBusqueda('P');
				break;
			case '2':
				setTipoCandidatosBusqueda('S');
				break;
			default:
				setTipoCandidatosBusqueda('P');
				break;
		}
	};

	const columnsBase = [
		{
			title: 'Actor Político',
			align: 'center',
			dataIndex: 'idAsociacion',
			key: 'idAsociacion',
			render: (_, record) => {
				let actorPolitico;
				if (tipoCandidatura == 1) {
					actorPolitico = actoresPoliticosPresidente.find(
						(actorPolitico) => actorPolitico.idAsociacion == record.idAsociacion && actorPolitico.idTipoAsociacion == record.tipoAsociacion,
					);
				}
				if (tipoCandidatura == 2) {
					actorPolitico = actoresPoliticosSMR.find(
						(actorPolitico) => actorPolitico.idAsociacion == record.idAsociacion && actorPolitico.idTipoAsociacion == record.tipoAsociacion,
					);
				}
				if (tipoCandidatura == 3) {
					actorPolitico = actoresPoliticosSRP.find(
						(actorPolitico) => actorPolitico.idAsociacion == record.idAsociacion && actorPolitico.idTipoAsociacion == record.tipoAsociacion,
					);
				}
				if (tipoCandidatura == 4) {
					actorPolitico = actoresPoliticosDMR.find(
						(actorPolitico) => actorPolitico.idAsociacion == record.idAsociacion && actorPolitico.idTipoAsociacion == record.tipoAsociacion,
					);
				}
				if (tipoCandidatura == 5) {
					actorPolitico = actoresPoliticosDRP.find(
						(actorPolitico) => actorPolitico.idAsociacion == record.idAsociacion && actorPolitico.idTipoAsociacion == record.tipoAsociacion,
					);
				}
				/*if (tipoCandidatura == 6) {
					actorPolitico = actoresPoliticosGubernatura.find(
						(actorPolitico) => actorPolitico.idAsociacion == record.idAsociacion && actorPolitico.idTipoAsociacion == record.tipoAsociacion,
					);
				}
				if (tipoCandidatura == 7) {
					actorPolitico = actoresPoliticosDMR.find(
						(actorPolitico) => actorPolitico.idAsociacion == record.idAsociacion && actorPolitico.idTipoAsociacion == record.tipoAsociacion,
					);
				}
				if (tipoCandidatura == 8) {
					actorPolitico = actoresPoliticosDRP.find(
						(actorPolitico) => actorPolitico.idAsociacion == record.idAsociacion && actorPolitico.idTipoAsociacion == record.tipoAsociacion,
					);
				}
				if (tipoCandidatura == 9) {
					actorPolitico = actoresPoliticosPresidenteMunicipal.find(
						(actorPolitico) => actorPolitico.idAsociacion == record.idAsociacion && actorPolitico.idTipoAsociacion == record.tipoAsociacion,
					);
				}*/
				if (actorPolitico != null)
					return (
						<img
							key={`emb-${record.idCandidato}-${actorPolitico.idAsociacion}-${actorPolitico.idTipoAsociacion}`}
							src={`${process.env.REACT_APP_API_URL}img/${actorPolitico.emblema}`}
							className={actorPolitico.idTipoAsociacion == 3 ? 'coalicion1' : ''}
							/* 							className={
								actorPolitico.idTipoAsociacion == 3 && actorPolitico.nombreAsociacion.includes('JUNTOS')
									? 'coalicion'
									: actorPolitico.idTipoAsociacion == 3 && !actorPolitico.nombreAsociacion.includes('JUNTOS')
									? 'coalicion1'
									: ''
							} */
							/* 							style={
								actorPolitico.idTipoAsociacion == 3 && actorPolitico.nombreAsociacion.includes('JUNTOS')
									? {width: '70px', textAlign: 'center', margin: 'auto', display: 'block'}
									: actorPolitico.idTipoAsociacion == 3 && !actorPolitico.nombreAsociacion.includes('JUNTOS')
									? {width: '105px', textAlign: 'center', margin: 'auto', display: 'block'}
									: {width: '35px', textAlign: 'center', margin: 'auto', display: 'block'}
							} */

							style={
								actorPolitico.idTipoAsociacion == 3
									? {height: '35px', width: '105px', textAlign: 'center', margin: 'auto', display: 'block'}
									: {width: '35px', textAlign: 'center', margin: 'auto', display: 'block'}
							}
						/>
					);
				return <p></p>;
			},
			width: '15%',
		},
		{
			render: (_, record) => {
				return (
					<div /*style={{ textAlign: "left" }}*/>
						<div className="div-FConsulta">
							<div className="FConsulta">¡Consulta!</div>
						</div>
						<Link
							key={record.idCandidato}
							to={{
								pathname: `/detalleCandidato/${record.idCandidato}/${record.idTipoCandidatura}`,
								state: {
									idCandidato: record.idCandidato,
									idTipoCandidatura: record.idTipoCandidatura,
								},
							}}
							onClick={() => setConsultar(true)}
							style={{}}
						>
							{_}
						</Link>
					</div>
				);
			},
			title: () => (
				<div style={{display: 'flex'}}>
					<span className="roboto-regular-16-blanco-33" style={{width: '100%'}}>
						<Input
							style={{textAlign: 'center'}}
							placeholder="Búsqueda por nombre:"
							width="100%"
							loading={consultarLocal}
							onPressEnter={(e) => buscarPropietario(e.target.value)}
							onChange={(e) => buscarPropietario(e.target.value)}
							value={nombrePropietario}
						/>
						{tipoCandidatosBusqueda === 'P' ? 'Persona propietaria' : 'Persona suplente'}
					</span>
				</div>
			),
			align: 'center',
			dataIndex: 'nombreCandidato',
			key: 'nombreCandidato',
			width: '28%',
			//sorter: (a, b) => (a.nombreCandidato.first > b.nombreCandidato.first ? 1 : -1),
		},
	];

	const columnsBaseDMR = [
		{
			title: 'Entidad',
			dataIndex: 'nombreEstado',
			align: 'center',
			key: 'nombreEstado',
			//sorter: (a, b) => (a.idEstadoEleccion.first > b.idEstadoEleccion.first ? 1 : -1),
			//fixed: 'left',
			width: '15%',
		},
		{
			title: 'Distrito',
			align: 'center',
			dataIndex: 'nombreDistrito',
			key: 'nombreDistrito',
			width: '14%',
		},
		...columnsBase,
	];

	const columnsBaseDRP = [
		{
			title: 'Circunscripción',
			dataIndex: 'idCircunscripcionEleccion',
			key: 'idCircunscripcionEleccion',
			align: 'center',
			width: '15%',
		},
		{
			title: 'Número de lista',
			dataIndex: 'numListaDiputado',
			key: 'numListaDiputado',
			align: 'center',
			width: '14%',
		},
		...columnsBase,
	];
	const columnsBasePM = [
		{
			title: 'Entidad',
			dataIndex: 'nombreEstado',
			align: 'center',
			key: 'nombreEstado',
			//sorter: (a, b) => (a.idEstadoEleccion.first > b.idEstadoEleccion.first ? 1 : -1),
			//fixed: 'left',
			width: '15%',
		},
		{
			title: 'Municipio',
			align: 'center',
			dataIndex: 'nombreMunicipio',
			key: 'nombreMunicipio',
			width: '14%',
		},
		...columnsBase,
	];

	const columnSuplente = {
		render: (_, record) => {
			return (
				<div>
					<div className="div-FConsulta">
						<div className="FConsulta">¡Consulta!</div>
					</div>
					<Link
						key={record.idCandidato}
						to={{
							pathname: `/detalleCandidato/${record.idCandidatoSup}/${record.idTipoCandidatura}`,
							state: {idCandidato: record.idCandidatoSup, idTipoCandidatura: record.idTipoCandidatura},
						}}
						onClick={() => setConsultar(true)}
						style={{}}
					>
						{_}
					</Link>
				</div>
			);
		},
		title: () => (
			<div style={{display: 'flex'}}>
				<span className="roboto-regular-16-blanco-33" style={{width: '100%'}}>
					<Input
						style={{textAlign: 'center'}}
						placeholder="Búsqueda por nombre:"
						width="100%"
						loading={consultarLocal}
						onPressEnter={(e) => buscarSuplente(e.target.value)}
						onChange={(e) => buscarSuplente(e.target.value)}
						value={nombreSuplente}
					/>
					{tipoCandidatosBusqueda === 'P' ? 'Persona suplente' : 'Persona propietaria'}
				</span>
			</div>
		),
		align: 'center',
		dataIndex: 'nombreSuplente',
		key: 'nombreSuplente',
		width: '28%',
	};

	const columnsBaseSMR = [
		{
			title: 'Entidad',
			dataIndex: 'nombreEstado',
			align: 'center',
			key: 'nombreEstado',
			//sorter: (a, b) => (a.idEstadoEleccion.first > b.idEstadoEleccion.first ? 1 : -1),
			width: '15%',
		},
		/* 		{
			title: 'No. de Fórmula',
			align: 'center',
			dataIndex: 'idNumFormula',
			key: 'idNumFormula',
			width: '14%',
		}, */
		{
			title: 'Número de lista',
			dataIndex: 'numListaDiputado',
			key: 'numListaDiputado',
			align: 'center',
			width: '14%',
		},
		...columnsBase,
	];

	const columnsBaseSRP = [
		/*{
			title: 'Estado',
			dataIndex: 'nombreEstado',
			align: 'center',
			key: 'nombreEstado',
			//sorter: (a, b) => (a.idEstadoEleccion.first > b.idEstadoEleccion.first ? 1 : -1),
			width: '15%',
		},
		{
			title: 'No. de Fórmula',
			align: 'center',
			dataIndex: 'idNumFormula',
			key: 'idNumFormula',
			width: '14%',
		},*/
		{
			title: 'Número de lista',
			dataIndex: 'numListaDiputado',
			key: 'numListaDiputado',
			align: 'center',
			width: '14%',
		},
		...columnsBase,
	];

	const columnsBasePresidente = [
		/*{
			/*
			title: 'No. de Fórmula',
			align: 'center',
			dataIndex: 'idNumFormula',
			key: 'idNumFormula',
			width: '14%',
	
		},*/
		...columnsBase,
	];
	const columnsDMR = [...columnsBaseDMR, columnSuplente];
	const columnsDRP = [...columnsBaseDRP, columnSuplente];
	const columnsSMR = [...columnsBaseSMR, columnSuplente];
	const columnsSRP = [...columnsBaseSRP, columnSuplente];
	const columnsPresidente = [...columnsBasePresidente];

	const columnsPM = [...columnsBasePM, columnSuplente];

	const formatearJSONExportar = (jsonOriginal) => {
		if (tipoCandidatura != null) {
			return jsonOriginal.map((item) => {
				if (tipoCandidatura == 5) {
					const elementoJSON = {};
					columnsDRP.map((columna) => {
						elementoJSON[columna.title] = item[columna.key] ? item[columna.key] : `${process.env.REACT_APP_ETIQUETA_SIN_INFORMACION}`;
					});
					return elementoJSON;
				} else {
					const elementoJSON = {};
					columnsDMR.map((columna) => {
						elementoJSON[columna.title] = item[columna.key] ? item[columna.key] : `${process.env.REACT_APP_ETIQUETA_SIN_INFORMACION}`;
					});
					return elementoJSON;
				}
			});
		}
		return [];
	};

	const enviaConsulta = () => {
		setLimpiarFiltros(false);
		setPaginacionSup({...paginacionSup, current: 1});
		setPaginacion({...paginacion, current: 1});
		setConsultarLocal(true);
	};
	const changeAccionAfirmativa = (e) => {
		setAccionAfirmativa(e.target.checked);
	};

	return (
		<>
			<div>
				{/*<div className="row">
				<div className="container">
						<div className="clearfix">&nbsp;</div>
						<Card className="cardResponsabilidadUTTyPDP">
							<Row>
								<Col span={19}>
									<span className="alineacion-justificada">
										<span className="gris2-mensaje-consulta">
											{' '}
											La información&nbsp;
											{
												//<span className="negro2-mensaje-consulta"> es proporcionada de manera obligatoria</span>
											}
											es proporcionada por las personas candidatas a la senaduría por mayoría relativa del estado de Tamaulipas, por lo
											tanto,
											<br />
											<span className="negro2-mensaje-consulta"> es responsabilidad de los actores políticos dicho contenido. </span>
											El Instituto únicamente apoya para su difusión.
										</span>
									</span>
								</Col>
								{jsonEtiquetas && (
									<Col span={5} style={{textAlign: 'center'}}>
										{jsonEtiquetas[0].etiquetaDescripcion}:&nbsp; <br />
										<span className="consulta-number">
											<Col style={{fontSize: '30px'}}>{jsonEtiquetas[0].cantidad}</Col>
										</span>
									</Col>
								)}
							</Row>
						</Card>
					</div>
				</div>*/}

				{cambioTam === false && (
					<div className="container">
						<div className="clearfix">&nbsp;</div>
						<Row>
							<Col span={18} className="col-consultas" style={{paddingRight: '14px'}}>
								<Card className="cardResponsabilidadUTTyPDP">
									<span className="alineacion-justificada">
										<span className="gris2-mensaje-consulta">
											<div style={{height: '104px'}}>
												{' '}
												{
													//<span className="negro2-mensaje-consulta"> es proporcionada de manera obligatoria</span>
												}
												La información de este portal fue proporcionada de forma obligatoria por las personas candidatas que participan
												en el Proceso Electoral Federal 2024, por lo tanto, es
												<b className="negro2-mensaje-consulta"> responsabilidad de los actores políticos dicho contenido. </b>
												El Instituto únicamente promueve su difusión.
												<br />
												&nbsp;
											</div>
										</span>
									</span>
								</Card>
							</Col>
							{jsonEtiquetas && (
								<Col span={6} className="col-consultas">
									<Card className="cardResponsabilidadUTTyPDP2 col-consultas" style={{textAlign: 'center', border: '0px'}}>
										<div>
											<div>{jsonEtiquetas[0].etiquetaDescripcion}:</div>
											<div style={{fontSize: '24px'}}>{jsonEtiquetas[0].cantidad}</div>
										</div>
									</Card>
								</Col>
							)}
						</Row>
					</div>
				)}
				{cambioTam === true && (
					<div>
						<div className="container">
							<div className="clearfix">&nbsp;</div>
							<Row>
								<Col span={24}>
									<Card className="cardResponsabilidadUTTyPDP">
										<Col span={24} style={{alignItems: 'justify', justifyContent: 'justify'}}>
											La información de este portal fue proporcionada de forma obligatoria por las personas candidatas que participan en
											el Proceso Electoral Federal 2024, por lo tanto, es
											<b className="negro2-mensaje-consulta"> responsabilidad de los actores políticos dicho contenido. </b>
											El Instituto únicamente promueve su difusión.
											<br />
											&nbsp;
										</Col>
									</Card>
								</Col>
							</Row>
							<br />
							<Row>
								{jsonEtiquetas && (
									<Col>
										<Card className="cardResponsabilidadUTTyPDP2 " style={{textAlign: 'center', border: '0px'}}>
											<div>
												<div>{jsonEtiquetas[0].etiquetaDescripcion}:</div>
												<div style={{fontSize: '24px'}}>{jsonEtiquetas[0].cantidad}</div>
											</div>
										</Card>
									</Col>
								)}
							</Row>
						</div>
					</div>
				)}
				<div ref={contenedorBusquedaRef}>
					{jsonCargados && !cargadoExcel ? (
						<div className="container">
							<div className="row">
								<div className="col-xs-12 col-md-10 espaciador-top hide-pntr">
									<span className="info">
										El sistema te permite hacer búsquedas específicas, o bien,
										<a
											className="info1"
											href={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_URL_DATABASE}`}
										>
											&nbsp;exportar la base de datos.
										</a>
										&nbsp;
									</span>
									{/*  y te desplegará las siguientes opciones: </span>
                                    <a className="info1" href={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_URL_DATABASE}`}>
                                        <u> exportar la base de datos.</u>
                                    </a> 
                                    <span className="alineacion-justificada">
                                        <ul>
                                            <li>Tipo de candidatura (mayoría relativa o representación proporcional)</li>
                                            <li>Sección electoral</li>
                                            <li>Grado académico</li>
                                            <li>Rango de edad</li>
                                            <li>Sexo</li>
                                            <li>Actor político (partidos o candidaturas independientes)</li>
                                            <li>Por grupo en situación de discriminación (personas indígenas, personas de la diversidad sexual, personas afromexicanas,
                                                personas migrantes, personas con discapacidad, personas jóvenes, personas mayores y rangos de ingreso mensual).</li>
                                        </ul>
                                    </span>
                                    <span className="gris2-mensaje-consulta">¡Solo tienes que llenar los campos!</span>
                                    <br />*/}
									<br />
								</div>
							</div>
							<div className="row">
								<div className="col-xs-12 col-md-10 espaciador-top hide-pntr">
									<span className="titulo-seccion">Búsqueda por:</span>
									<br />
								</div>
							</div>
							<Row align="left">
								<Text>Los campos con </Text>
								<Text type="danger">&nbsp; * &nbsp;</Text>
								<Text> son obligatorios.</Text>
							</Row>
							<br />
							{/*<Divider />*/}
							<div className="row">
								<Form form={form} name="busqueda-form" scrollToFirstError layout="vertical" onFinish={() => enviaConsulta()}>
									<div className="col-xs-12">
										<div id="filtros">
											<div className="row">
												{
													//*entidad === null ? (*/
												}
												<>
													<>
														<div className="col-xs-12 col-sm-6 col-md-4">
															<div className="form-group">
																<Form.Item
																	name="tipoCandidatura"
																	label="Cargo:"
																	hasFeedback
																	rules={[{required: true, message: 'Debe seleccionar un cargo.'}]}
																	initialValue={tipoCandidatura}
																>
																	<Select
																		placeholder="Selecciona tipo de candidatura"
																		style={{width: '100%'}}
																		//disabled
																		value={tipoCandidatura}
																		onSelect={(tipoCandidatura) => {
																			seleccionTipoCandidatura(tipoCandidatura);
																		}}
																		//mode="multiple"
																		//onChange={(sistemasBandeja) => cambiaSistemaBandeja(sistemasBandeja)}
																		//disabled={consultar}
																	>
																		{/*<Option key="" className="selected-option">
																			Selecciona tipo de candidatura
																			</Option>*/}
																		{tiposCandidaturas.map((item) => {
																			return (
																				<Option key={item.idTipoCandidatura} className="selected-option">
																					{item.nombreCombo}
																				</Option>
																			);
																		})}
																		{/*entidad == 10
																			? tipoCandidaturaDurango.map((item) => {
																					return (
																						<Option key={item.idTipoCandidatura} className="selected-option">
																							{item.nombreCorto}
																						</Option>
																					);
																			  })
																			: entidad == 28
																			? tipoCandidaturaTamaulipas.map((item) => {
																					return (
																						<Option key={item.idTipoCandidatura} className="selected-option">
																							{item.nombreCorto}
																						</Option>
																					);
																			  })
																			: c*/}
																	</Select>
																</Form.Item>
															</div>
														</div>

														{tipoCandidatura !== null && tipoCandidatura !== '1' && tipoCandidatura !== '3' && (
															<div className="col-xs-12 col-sm-6 col-md-4 filtro">
																<div className="form-group">
																	<Form.Item
																		name="entidad"
																		label="Entidad:"
																		hasFeedback
																		//rules={[{required: true, message: 'Debe seleccionar una entidad.'}]}
																		initialValue={entidad}
																	>
																		<Select
																			placeholder="Selecciona entidad"
																			style={{width: '100%'}}
																			value={entidad}
																			onSelect={(entidad) => seleccionaEntidad(entidad)}
																		>
																			<Option key={0} className="selected-option">
																				{'Selecciona entidad'}
																			</Option>

																			{entidades.map((item) => {
																				return (
																					<Option key={item.idEstado} className="selected-option">
																						{item.nombreEstado}
																					</Option>
																				);
																			})}
																		</Select>
																	</Form.Item>
																</div>
															</div>
														)}
														{/*  <div className="col-xs-12 col-sm-6 col-md-3 filtro">
                                                            <div className="row h-100">
                                                                <div className="col-xs-12">
                                                                    <div className="form-group">
                                                                        <Form.Item
                                                                            name="seccionElectoral"
                                                                            label={
                                                                                <span>
                                                                                    Sección electoral:
                                                                                    <QuestionCircleFilled
                                                                                        className="icono-rosa"
                                                                                        onClick={() => setModalVisible(true)}
                                                                                    />
                                                                                </span>
                                                                            }
                                                                            hasFeedback
                                                                          
                                                                            rules={[{ type: 'number', message: "La sección electoral es un número." }]}
                                                                            initialValue={seccionElectoral}
                                                                        >
                                                                            <InputNumber
                                                                                placeholder="Introduce sección"
                                                                                className="form-control"
                                                                                value={seccionElectoral}
                                                                                disabled={entidad == null || entidad === ''}
                                                                                style={{ width: '100%' }}
                                                                                min={1}
                                                                                 onChange={(e) => setSeccionElectoral(e)}
                                                                            
                                                                            />
                                                                        </Form.Item>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
													</>
													{
														//console.log("tipoCandidatura1", tipoCandidatura)
													}
													{entidad !== null && //&& entidad === '28'
														tipoCandidatura !== null &&
														tipoCandidatura === '4' && (
															<>
																<div className="col-xs-12 col-sm-6 col-md-3 filtro">
																	<div className="row h-100">
																		<div className="col-xs-12">
																			<div className="form-group">
																				<Form.Item
																					name="seccionElectoral"
																					label={
																						<span>
																							Sección electoral:
																							<QuestionCircleFilled
																								className="icono-rosa"
																								onClick={() => setModalVisible(true)}
																							/>
																						</span>
																					}
																					hasFeedback
																					//label="Sección electoral"
																					rules={[{type: 'number', message: 'La sección electoral es un número.'}]}
																					initialValue={seccionElectoral}
																				>
																					<InputNumber
																						placeholder="Introduce sección"
																						className="form-control"
																						value={seccionElectoral}
																						//disabled={entidad == null || entidad === ''}
																						disabled={entidad == null || entidad === ''}
																						style={{width: '100%'}}
																						min={1}
																						/*loading={consultar}
                                                                                disabled={consultar}
                                                                                value={usuario}
                                                                                onSearch={(value) => buscarUsuario(value)}*/
																						onChange={(e) => setSeccionElectoral(e)}
																						/*enterButton*/
																					/>
																					{
																						//console.log("SECCIÓN", seccionElectoral)
																					}
																				</Form.Item>
																			</div>
																		</div>
																	</div>
																</div>
															</>
														)}
													{/* console.log('c2', circunscripcion) */}
													{entidad !== null && tipoCandidatura === '5' && circunscripcion !== null && (
														<>
															<div className="col-xs-12 col-sm-6 col-md-4 filtro">
																<div className="form-group">
																	<Form.Item name="circunscripcion" label="Circunscripción:" initialValue={circunscripcion}>
																		<TextArea
																			id="text"
																			rows={1}
																			autoSize={{minRows: 1, maxRows: 1}}
																			disabled
																			value={circunscripcion}
																			readOnly={true}
																		/>

																		{console.log('')}
																	</Form.Item>
																</div>
															</div>
														</>
													)}
													{entidad !== null && tipoCandidatura !== null && tipoCandidatura == 9 ? (
														<>
															<div className="col-xs-12 col-sm-6 col-md-4 filtro">
																<div className="form-group">
																	<Form.Item name="municipio" label="Municipio:" initialValue={municipio}>
																		<Select
																			placeholder="Selecciona municipio"
																			style={{width: '100%'}}
																			value={municipio}
																			//disabled={deshabilitarSeccion}
																			onSelect={(municipio) => {
																				setMunicipio(municipio);
																			}}
																		>
																			<Option key="" className="selected-option">
																				Selecciona municipio
																			</Option>
																			{
																				////console.log("ITEMs", municipios),
																				municipios.map((item) => {
																					// //console.log("ITEM", item)
																					return item.idEstado == entidad ? (
																						<Option key={item.nombreMunicipio} className="selected-option">
																							{item.nombreMunicipio}
																						</Option>
																					) : (
																						''
																					);
																				})
																			}
																		</Select>
																	</Form.Item>
																</div>
															</div>
														</>
													) : (
														''
													)}{' '}
												</>
												{/*		) : (
													''
												)}           */}
												<div className="clearfix"></div>
												{/*<div className="col-xs-12 espaciador-bottom-xs filtro">
													<div id="mensaje-filtros">
														* La opción de búsqueda Grado académico solamente presenta los datos capturados por las y los
														candidatos.
													</div>
                                                </div>*/}
												<div className="col-xs-12 espaciador-bottom-xs filtro">
													<div id="mensaje-filtros">
														<InfoCircleFilled className="icono-verde" /> La opción de búsqueda por grado académico solamente
														presenta los datos capturados por las personas candidatas.
													</div>
												</div>
												<div className="col-xs-12 col-sm-6 col-md-3 filtro">
													<div className="form-group">
														<Form.Item name="gradoAcademico" label="Grado académico:" initialValue={gradoAcademico}>
															<Select
																placeholder="Selecciona grado académico"
																style={{width: '100%'}}
																value={gradoAcademico}
																onSelect={(gradoAcademico) => {
																	setGradoAcademico(gradoAcademico);
																	setDataCandidatos([]);
																	setNombrePropietario(null);
																	setNombreSuplente(null);
																	setLimpiarFiltros(true);
																}}
															>
																<Option key="" className="selected-option">
																	Selecciona grado académico
																</Option>
																{grado.map((item) => {
																	return (
																		<Option key={item.idGrado} className="selected-option">
																			<>
																				{item.descripcionGrado} {/*item.estatus*/}
																			</>
																		</Option>
																	);
																})}
															</Select>
														</Form.Item>
													</div>
												</div>
												<div className="col-xs-12 col-sm-6 col-md-2 filtro">
													<div className="form-group">
														<Form.Item name="rangoEdad" label="Rango de edad:" initialValue={rangoEdad}>
															<Select
																placeholder="Selecciona rango de edad"
																style={{width: '100%'}}
																value={rangoEdad}
																onSelect={(rangoEdad) => {
																	setRangoEdad(rangoEdad);
																	setDataCandidatos([]);
																	setNombrePropietario(null);
																	setNombreSuplente(null);
																	setLimpiarFiltros(true);
																}}
															>
																<Option key="" className="selected-option">
																	Selecciona rango de edad
																</Option>
																{rangosEdades.map((item) => {
																	return (
																		<Option key={item.idRangoEdad} className="selected-option">
																			<>
																				{item.descRangoEdad} {item.estatus}
																			</>
																		</Option>
																	);
																})}
															</Select>
														</Form.Item>
													</div>
												</div>
												<div className="col-xs-12 col-sm-6 col-md-4 filtro" style={{paddingRight: '0px', width: '300px'}}>
													<div className="form-group">
														<Form.Item name="genero" label="Sexo:" initialValue={genero}>
															<Radio.Group
																className="radio-inline"
																options={generos}
																onChange={(e) => {
																	setGenero(e.target.value);
																	setDataCandidatos([]);
																	setNombrePropietario(null);
																	setNombreSuplente(null);
																	setLimpiarFiltros(true);
																}}
																value={genero}
															/>
														</Form.Item>
													</div>
												</div>
												<div className="col-xs-12 col-sm-6 col-md-3 filtro" style={{paddingRight: '0px'}}>
													<div className="form-group">
														<Form.Item name="accionAfirmativa">
															<Checkbox
																//checked={checkTodasConfig}
																//value={accionAfirmativa}
																onChange={changeAccionAfirmativa}
																style={{paddingTop: '30px'}}
															>
																Candidaturas por acción afirmativa{' '}
															</Checkbox>{' '}
															<Tooltip color="#A0005F" title={'Candidaturas por acción afirmativa'}>
																<QuestionCircleFilled className="icono-rosa" />
															</Tooltip>
														</Form.Item>
													</div>
												</div>
												<div className="clearfix"></div>
											</div>
										</div>
									</div>
									{/*<Divider />*/}
									{/*<div className="col-xs-12">
										<label>Actor político</label>
										
											//console.log("actorPolitico", actorPolitico, "++actores", actoresPoliticosFilter)
										
									</div>*/}
									<div className="col-xs-12 col-md-10 espaciador-top-xs hide-pntr">
										<span className="titulo-seccion-partidos">
											Partidos Políticos, Agrupaciones Políticas y Candidaturas Independientes:
										</span>
										<br />
									</div>
									<div className="col-xs-12">
										<div className="filtros">
											<div id="contenedor-actores-politicos" className="row">
												<Form.Item name="actorPolitico" initialValue={actorPolitico}>
													<Radio.Group
														value={actorPolitico}
														onChange={(e) => {
															setActorPolitico(e.target.value);
															setDataCandidatos([]);
															setNombrePropietario(null);
															setNombreSuplente(null);
															setLimpiarFiltros(true);
														}}
														className="radio-buttom"
													>
														<div className="col-xs-12 col-sm-6 col-md-4">
															<div className="radio">
																<Radio value="">TODOS</Radio>
															</div>
														</div>
														{/* console.log(
															'hola**',
															entidad,
															tipoCandidatura,
															entidad !== null,
															tipoCandidatura !== null,
															actoresPoliticosFilter,
														) */}

														{entidad === null &&
															tipoCandidatura !== null &&
															(tipoCandidatura === '1' || tipoCandidatura === '3') &&
															actoresPoliticosFilter.map((actorPolitico) => {
																// console.log("hola")
																let actorFilter = [];
																//if (tipoCandidatura === '1' || tipoCandidatura === '3' || tipoCandidatura === '5')
																//if ( tipoCandidatura === '1' || tipoCandidatura === '3')
																actorFilter = actorPolitico.idEstadoParticipante.find((i) => i == 0);
																//else actorFilter = actorPolitico.idEstadoParticipante.find((i) => i == entidad);

																//console.log('actoresPoliticosFilter**2', actorFilter);

																return actorFilter != undefined && actorFilter != null ? (
																	<div
																		key={`div-${actorPolitico.idAsociacion}-${actorPolitico.idTipoAsociacion}`}
																		className="col-xs-12 col-sm-6 col-md-4"
																	>
																		<div className="radio">
																			<Radio value={`${actorPolitico.idAsociacion}-${actorPolitico.idTipoAsociacion}`}>
																				<img
																					className="row-card-input-col-img"
																					key={`emb-${actorPolitico.idAsociacion}-${actorPolitico.idTipoAsociacion}`}
																					src={`${process.env.REACT_APP_API_URL}img/${actorPolitico.emblema}`}
																					className={actorPolitico.idTipoAsociacion == 3 ? 'coalicion1' : ''}
																				/>

																				{actorPolitico.nombreAsociacion.includes('JUNTOS') ? (
																					<>
																						<span>{actorPolitico.nombreAsociacion.slice(0, 23)}</span>
																						<br />
																						<span style={{paddingLeft: '103px', paddingTop: '-10px'}}>
																							{actorPolitico.nombreAsociacion.slice(23)}
																						</span>
																					</>
																				) : (
																					<span>{actorPolitico.nombreAsociacion}</span>
																				)}
																			</Radio>
																		</div>
																	</div>
																) : (
																	<p></p>
																);
															})}

														{entidad !== null &&
															tipoCandidatura !== null &&
															(tipoCandidatura === '2' || tipoCandidatura === '4' || tipoCandidatura === '5') &&
															actoresPoliticosFilter.map((actorPolitico) => {
																// console.log("hola")
																let actorFilter = [];
																if (tipoCandidatura === '5')
																	actorFilter = actorPolitico.idEstadoParticipante.find((i) => i == 0);
																else actorFilter = actorPolitico.idEstadoParticipante.find((i) => i == entidad);

																//console.log('actoresPoliticosFilter**2', actorFilter);

																return actorFilter != undefined && actorFilter != null ? (
																	<div
																		key={`div-${actorPolitico.idAsociacion}-${actorPolitico.idTipoAsociacion}`}
																		className="col-xs-12 col-sm-6 col-md-4"
																	>
																		<div className="radio">
																			<Radio value={`${actorPolitico.idAsociacion}-${actorPolitico.idTipoAsociacion}`}>
																				<img
																					className="row-card-input-col-img"
																					key={`emb-${actorPolitico.idAsociacion}-${actorPolitico.idTipoAsociacion}`}
																					src={`${process.env.REACT_APP_API_URL}img/${actorPolitico.emblema}`}
																					className={actorPolitico.idTipoAsociacion == 3 ? 'coalicion1' : ''}
																					/* 																					
																					className={
																						actorPolitico.idTipoAsociacion == 3 &&
																						actorPolitico.nombreAsociacion.includes('JUNTOS')
																							? 'coalicion'
																							: actorPolitico.idTipoAsociacion == 3 &&
																							  !actorPolitico.nombreAsociacion.includes('JUNTOS')
																							? 'coalicion1'
																							: ''
																					} */
																				/>

																				{actorPolitico.nombreAsociacion.includes('JUNTOS') ? (
																					<>
																						<span>{actorPolitico.nombreAsociacion.slice(0, 23)}</span>
																						<br />
																						<span style={{paddingLeft: '103px', paddingTop: '-10px'}}>
																							{actorPolitico.nombreAsociacion.slice(23)}
																						</span>
																					</>
																				) : (
																					<span>{actorPolitico.nombreAsociacion}</span>
																				)}
																			</Radio>
																		</div>
																	</div>
																) : (
																	<p></p>
																);
															})}

														{entidad === null &&
															tipoCandidatura !== null &&
															(tipoCandidatura === '2' || tipoCandidatura === '4' || tipoCandidatura === '5') &&
															actoresPoliticosFilter.map((actorPolitico) => {
																// console.log("hola")
																let actorFilter = [];
																if (tipoCandidatura === '5')
																	actorFilter = actorPolitico.idEstadoParticipante.find((i) => i == 0);
																//else actorFilter = actorPolitico.idEstadoParticipante.find((i) => i == entidad);

																//console.log('actoresPoliticosFilter**2', actorFilter);

																//return actorFilter != undefined && actorFilter != null ? (
																return (
																	<div
																		key={`div-${actorPolitico.idAsociacion}-${actorPolitico.idTipoAsociacion}`}
																		className="col-xs-12 col-sm-6 col-md-4"
																	>
																		<div className="radio">
																			<Radio value={`${actorPolitico.idAsociacion}-${actorPolitico.idTipoAsociacion}`}>
																				<img
																					className="row-card-input-col-img"
																					key={`emb-${actorPolitico.idAsociacion}-${actorPolitico.idTipoAsociacion}`}
																					src={`${process.env.REACT_APP_API_URL}img/${actorPolitico.emblema}`}
																					className={actorPolitico.idTipoAsociacion == 3 ? 'coalicion1' : ''}
																					/* 																					
																					className={
																						actorPolitico.idTipoAsociacion == 3 &&
																						actorPolitico.nombreAsociacion.includes('JUNTOS')
																							? 'coalicion'
																							: actorPolitico.idTipoAsociacion == 3 &&
																							  !actorPolitico.nombreAsociacion.includes('JUNTOS')
																							? 'coalicion1'
																							: ''
																					} */
																				/>

																				{actorPolitico.nombreAsociacion.includes('JUNTOS') ? (
																					<>
																						<span>{actorPolitico.nombreAsociacion.slice(0, 23)}</span>
																						<br />
																						<span style={{paddingLeft: '103px', paddingTop: '-10px'}}>
																							{actorPolitico.nombreAsociacion.slice(23)}
																						</span>
																					</>
																				) : (
																					<span>{actorPolitico.nombreAsociacion}</span>
																				)}
																			</Radio>
																		</div>
																	</div>
																);
															})}
													</Radio.Group>
												</Form.Item>
											</div>
										</div>
									</div>
									<div className="col-xs-12 text-center" style={{marginTop: '30px'}}></div>
									{/*<Divider />*/}
									<div className="col-xs-12 text-center">
										<span style={{marginLeft: '20px', marginTop: '20px'}}>
											<Button
												key="limpiar"
												type="primary"
												htmlType="button"
												className="secondary-button"
												onClick={limpiarFiltrosFunc}
												//loading={enviarCorreoListado}
											>
												<ClearOutlined />
												Limpiar campos
											</Button>
											&nbsp;&nbsp;
											<Button
												key="buscar"
												type="primary"
												htmlType="submit"
												className="primary-button"
												//loading={enviarCorreoListado}
												/* 												disabled={
													tipoCandidatura == 1 || tipoCandidatura == 3
														? false
														: (tipoCandidatura == 2 || tipoCandidatura == 4 || tipoCandidatura == 5) && entidad == null
														? true
														: false
												} */
											>
												<SearchOutlined />
												Consultar
											</Button>
										</span>
									</div>
								</Form>
							</div>
							{!limpiarFiltros && (
								<div>
									<div>
										<div id="contenedor-resultados" className="col-xs-12 espaciador-top" ref={contenedorResultadoRef}>
											<div className="row">
												{/*<div className="col-xs-12">
													<p id="titulo-resultados" className="info">
														Resultados para {descTipoCandidatura}:
													</p>
                                                </div>*/}
												<div className="col-xs-12 col-md-10 espaciador-top-xs hide-pntr">
													<span className="titulo-seccion">Resultados para {descTipoCandidatura}:</span>
													<br />
												</div>
											</div>
											<Table
												rowKey={(record) => record.idCandidato}
												columns={
													tipoCandidatura != null && tipoCandidatura == 1
														? columnsPresidente
														: tipoCandidatura == 2
														? columnsSMR
														: tipoCandidatura == 3
														? columnsSRP
														: tipoCandidatura == 4
														? columnsDMR
														: columnsDRP
													/*tipoCandidatura == 8
														? columnsDRP
														: tipoCandidatura == 7
														? columnsDMR
														: tipoCandidatura == 9
														? columnsPM
														: columnsSMR*/
												}
												dataSource={dataCandidatos}
												pagination={paginacion}
												onChange={handleTableChange}
												scroll={{x: 900}}
												loading={{
													spinning: consultarLocal,
													indicator: <Spin spinning={consultarLocal} />,
												}}
												locale={{
													emptyText: (
														<div className="ant-empty ant-empty-normal">
															<div className="ant-empty-image">
																<svg
																	className="ant-empty-img-simple"
																	width="64"
																	height="41"
																	viewBox="0 0 64 41"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<g transform="translate(0 1)" fill="none" fill-rule="evenodd">
																		<ellipse
																			className="ant-empty-img-simple-ellipse"
																			cx="32"
																			cy="33"
																			rx="32"
																			ry="7"
																		></ellipse>
																		<g className="ant-empty-img-simple-g" fill-rule="nonzero">
																			<path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
																			<path
																				d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
																				class="ant-empty-img-simple-path"
																			></path>
																		</g>
																	</g>
																</svg>
															</div>
															<div className="ant-empty-description">No se han encontrado resultados para tu búsqueda</div>
														</div>
													),
												}}
											/>
										</div>
										<div className="clearfix">&nbsp;</div>
										{true && (
											<div className="col-xs-12 text-right">
												{
													<ExportCSV
														csvData={dataCandidatos}
														fileName={
															(tipoCandidatura != null
																? tipoCandidatura == 1
																	? 'Presidencia-'
																	: tipoCandidatura == 2
																	? 'SMR-'
																	: tipoCandidatura == 3
																	? 'SRP-'
																	: tipoCandidatura == 4
																	? 'DMR-'
																	: tipoCandidatura == 5
																	? 'DRP-'
																	: ''
																: /* tipoCandidatura == 8
																? 'DRP-'
																: tipoCandidatura == 7
																? 'DMR-'
																: tipoCandidatura == 6
																? 'Gubernatura-'
																: tipoCandidatura == 9
																? 'Presidencia Municipal-'
																: ''*/
																  '') + Date.now()
														}
														tipoCandidatosBusqueda={tipoCandidatosBusqueda}
														tipoCandidatura={tipoCandidatura}
														setCargandoExcel={setCargandoExcel}
													/>
												}
											</div>
										)}
									</div>
								</div>
							)}
							<div className="clearfix">&nbsp;</div>
							<Modal
								centered
								visible={modalVisible}
								title={
									<div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
										<span className="roboto-medium-20-rosa" style={{textAlign: 'center'}}>
											¿Dónde consultar tu sección electoral?
										</span>
									</div>
								}
								footer={null}
								onCancel={() => {
									setModalVisible(false);
								}}
								width={'50%'}
							>
								<div className="modal-body text-center">
									<div className="img-modal-seccion">
										<img className="img-responsiva" src={IneSeccion1} />
										<img className="img-responsiva" src={IneSeccion2} />
									</div>
									<div className="img-modal-seccion">
										<img className="img-responsiva" src={IneSeccion3} />
										<img className="img-responsiva" src={IneSeccion4} />
									</div>
									<br />
									<Button key="cerrar" type="primary" onClick={() => setModalVisible(false)} className="primary-button">
										<CloseCircleOutlined />
										Cerrar
									</Button>
								</div>
							</Modal>
							<Graphics entidad={entidad} />
						</div>
					) : (
						<div>
							<div id="imprimir" className="container candidatos">
								<div className="clearfix">&nbsp;</div>
								<div id="contenedor-cargando" className="col-xs-12 text-center">
									<Spin spinning={true} />
								</div>
								<div className="clearfix">&nbsp;</div>
							</div>
						</div>
					)}
				</div>
			</div>

			<div>
				{/* Contenido de la página principal */}
				{showModal && <ModalDos onClose={handleCloseModal} />}
			</div>
		</>
	);
	executeScroll();
};

export default Busqueda;
