import React from 'react';
import useDataContext from '../../context/useDataContext';

const Tema = (props) => {

	const {
		jsonLigasConsulta
	} = useDataContext();

	return (
		<section id="tema-area">
			<div className="container">
				<div className="row">
					<div className="col-xs-12 col-md-12 col-lg-8">
						<i className="fa fa-map-pin" aria-hidden="true"></i>&nbsp; Tema:{' '}
						<a href="https://portal.ine.mx/voto-y-elecciones/" target="_blank">
							VOTO Y ELECCIONES
						</a>
						&nbsp;|&nbsp;
						<a href={jsonLigasConsulta && jsonLigasConsulta.votoElecciones2024} target="_blank">
							ELECCIONES 2024
						</a>{' '}
						| &nbsp;CANDIDATAS Y CANDIDATOS
					</div>
					<div className="col-xs-12 col-md-4 col-lg-2">
						<i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp; Área:{' '}
						<a href="https://portal.ine.mx/estructura-ine/uttpdp/" target="_blank">
							UTTyPDP
						</a>
					</div>
					<div className="col-xs-12 col-md-4 col-lg-2">
						&nbsp;&nbsp;&nbsp;<a href="/preguntasFrecuentes">Preguntas frecuentes</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Tema;
