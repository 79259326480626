import React from 'react';
import {Row, Col} from 'antd';
import Banner from '../../assets/img/banner_pef2024.svg';
import Banner2 from '../../assets/img/barralogos.png';
import Banner3 from '../../assets/img/banner_movil_pef2024.png';
import Banner4 from '../../assets/img/BANNER_tablet_ConT.svg';
import Banner5 from '../../assets/img/Banner_CONOCELES.png';
import Banner6 from '../../assets/img/Banner_CONOCELES-EDITABLE-02.png';

const Bienvenida = (props) => {
	return (
		<Row>
			<Col xs={{span: 0}} md={{span: 24}}>
				<img src={Banner5} style={{width: '100%'}} usemap="#mapa1" border="0" />
				<map name="mapa1">
					{/* <area alt="Elecciones 2021" shape="circle" coords="920,350,100" href="https://portal.ine.mx/voto-y-elecciones/elecciones-2021/" target="_blank" hidefocus="true" />  */}
					<area alt="Sale y vale" shape="circle" coords="1130,350,40" href="https://portal.ine.mx/sale-y-vale/" target="_blank" hidefocus="true" />
				</map>
				{/*<section id="bienvenida">
                <div className="container banner-texto">
                    <div className="row">
                        <div className="col-xs-10 col-md-5">
                            <div className="text-center">
                                <h1>Candidatas y Candidatos, Conóceles</h1>
                            </div>
                            <div className="text-justify letra">
                                <p>Consulta la trayectoria política y profesional, las propuestas de
                                   campaña y la autoidentificación a determinados grupos en
                                   situación de discriminación de las personas candidatas que participan
                                   en la Elección Federal en México.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/}
			</Col>
			{/*<Col xs={{ span: 12 }} md={{ span: 12}}>
            <img src={Banner4} style={{ width: "100%" }} usemap="#mapa1" border="0"/> 
            <map name="mapa1">
                   <area alt="Elecciones 2021" shape="circle" coords="920,350,100" href="https://portal.ine.mx/voto-y-elecciones/elecciones-2021/" target="_blank" hidefocus="true" />  
                  <area alt="Sale y vale" shape="circle" coords="1130,350,40" href="https://portal.ine.mx/sale-y-vale/" target="_blank" hidefocus="true"/> 
            </map>
            </Col>*/}
			<Col xs={{span: 24}} md={{span: 0}}>
				<img src={Banner6} style={{width: '100%'}} usemap="#mapa1" border="0" />
				<map name="mapa1">
					{/* <area alt="Elecciones 2021" shape="circle" coords="920,350,100" href="https://portal.ine.mx/voto-y-elecciones/elecciones-2021/" target="_blank" hidefocus="true" />  */}
					<area alt="Sale y vale" shape="circle" coords="1130,350,40" href="https://portal.ine.mx/sale-y-vale/" target="_blank" hidefocus="true" />
				</map>
			</Col>
			{/* <Col xs={{ span: 24 }} md={{ span: 0 }}>
                <section id="bienvenida">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <h1>¡Candidatas y Candidatos, Conóceles!</h1>
                              <div className="letra"><p>
                                    Te damos la bienvenida al sistema de
                                    consulta de información proporcionada por
                                    las y los candidatos que participan en la
                                    contienda electoral a nivel federal.
                                </p></div>
                            </div>
                        </div>
                    </div>
                </section>
                <img src={Banner2} style={{ width: "100%" }} usemap="#mapa2"/> 
                <map name="mapa2">
                  <area alt="Elecciones 2021" shape="circle" coords="350,30,60" href="https://portal.ine.mx/voto-y-elecciones/elecciones-2021/" target="_blank" hidefocus="true"/> 
                  <area alt="Sale y vale" shape="circle" coords="500,30,50" href="https://portal.ine.mx/sale-y-vale/" target="_blank" hidefocus="true"/> 
                </map>
            </Col> */}
		</Row>
	);
};
export default Bienvenida;
