import React, { useEffect, useRef } from 'react';
import Header from '../ui/Header'
import Navigation from '../ui/Navigation'
import Footer from '../ui/Footer'
import DetalleCandidato from '../ui/DetalleCandidato'

const useMountEffect = fun => useEffect(fun, []);

const Ficha = (props) => {

    const contenedorViewRef = useRef(null);
    const executeScroll = () => contenedorViewRef.current.scrollIntoView();
    useMountEffect(executeScroll);

    return (
        <div ref={contenedorViewRef}>
            <Header />
            <Navigation />
            <DetalleCandidato {...props} />
            <Footer />
        </div>
    );

}

export default Ficha;