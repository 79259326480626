import React, {useEffect, useState} from 'react';
import Hombre from '../../assets/img/hombre.jpg';
import Mujer from '../../assets/img/mujer.jpg';
import NoDisponible from '../../assets/img/nodisponible.jpg';
import Spin from '../ui/Spin';
import {/*Icon,*/ TwitterOutlined, InstagramOutlined, YoutubeOutlined, FacebookOutlined, CheckCircleOutlined, DownloadOutlined} from '@ant-design/icons';
import {Card, /*Space,*/ Avatar, Row, Col, Button} from 'antd';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router';
import Tipo1 from '../../assets/img/tipo1.png';
import Tipo4 from '../../assets/img/Logo-VI-UNAM-365x95-1.png';
import Tipo5 from '../../assets/img/tipo5.png';
import Tipo6 from '../../assets/img/tipo6.png';
import Tipo7 from '../../assets/img/tipo7.png';
import tiktok from '../../assets/img/tiktokazul.png';
import useBusquedaContext from '../../context/useBusquedaContext';

import useDataContext from '../../context/useDataContext';
let descripcionAccionAfirmativa = '';
const DetalleCandidato = (props) => {
	//console.log("PROPS detalle", props);

	const {
		tiposCandidaturas,
		setTiposCandidaturas,
		entidades,
		gradosAcademicos,
		generos,
		actoresPoliticos,
		candidatosDMR,
		candidatosDRP,
		candidatosSMR,
		candidatosSRP,
		candidatosPresidente,
		candidatosGubernatura,
		candidatosPresidenteMunicipal,
		hipervinculosDMR,
		hipervinculosDRP,
		hipervinculosGubernatura,
		hipervinculosPresidenteMunicipal,
		hipervinculosSMR,
		hipervinculosSRP,
		hipervinculosPresidente,
		redesSocialesDMR,
		redesSocialesDRP,
		redesSocialesGubernatura,
		redesSocialesSMR,
		redesSocialesPresidenteMunicipal,
		redesSocialesSRP,
		redesSocialesPresidente,
		cursosCandidatosDMR,
		cursosCandidatosDRP,
		cursosCandidatosGubernatura,
		cursosCandidatosPresidenteMunicipal,
		cursosCandidatosSMR,
		cursosCandidatosSRP,
		cursosCandidatosPresidente,
		regresar,
		setRegresar,
		actoresPoliticosPresidenteMunicipal,
		setActoresPoliticosPresidenteMunicipal,
		actoresPoliticosGubernatura,
		setActoresPoliticosGubernatura,
		actoresPoliticosDMR,
		setActoresPoliticosDMR,
		actoresPoliticosDRP,
		setActoresPoliticosDRP,
		actoresPoliticosSMR,
		setActoresPoliticosSMR,
		actoresPoliticosSRP,
		setActoresPoliticosSRP,
		actoresPoliticosPresidente,
		setActoresPoliticosPresidente,
		accionesAfirmativas,
	} = useDataContext();

	//console.log("Regresar2", regresar);
	const [candidato, setCandidato] = useState();
	const [redesSocialesCandidato, setRedesSocialesCandidato] = useState(null);
	const [hipervinculosCandidato, setHipervinculosCandidato] = useState(null);
	const [cursosCandidato, setCursosCandidato] = useState(null);
	const [srcFoto, setSrcFoto] = useState();
	const [srcEmblema, setSrcEmblema] = useState();
	const [propietarioSuplente, setpropietarioSuplente] = useState();
	const [nombreAsociacion, setNombreAsociacion] = useState();
	const [nombreEstadoCamp, setNombreEstadoCamp] = useState(null);
	const [nombreEstadoCand, setNombreEstadCand] = useState(null);
	const [grado, setGrado] = useState(null);
	const [buscar, setBuscar] = useState({
		//Hook para almacenar la información de la búsqueda
		infoBuscar: null,
	});

	let {idCandidato, idTipoCandidatura} = useParams();

	const [idCandidatoB, setIdCandidatoB] = useState(
		props.location.state && props.location.state.idCandidato ? props.location.state.idCandidato : idCandidato ? parseInt(idCandidato) : 0,
	);
	const [idTipoCandidaturaB, setIdTipoCandidaturaB] = useState(
		props.location.state && props.location.state.idTipoCandidatura
			? props.location.state.idTipoCandidatura
			: idTipoCandidatura
			? parseInt(idTipoCandidatura)
			: 0,
	);

	const fechaActual = new Date();
	const dia = fechaActual.getDate();
	const anio = fechaActual.getFullYear();
	const mes = fechaActual.getMonth();
	let meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

	useEffect(() => {}, []);

	//Reasignación para suplentes sin propietario
	const suplentesSinPropietario = (candidato) => {
		if (candidato !== undefined) {
			candidato.nombreCandidato = candidato.nombreSuplente;
			candidato.nombreSuplente = 'Sin registro';
			candidato.idCandidato = candidato.idCandidatoSup;
			candidato.idCandidatoSup = undefined;
		}
	}

	useEffect(() => {
		setRegresar(true);

		////console.log("idCandidatoB:=>", idCandidatoB);
		////console.log("idTipoCandidaturaB:=>", idTipoCandidaturaB);
		//if (idTipoCandidaturaB === 7) {
		if (idTipoCandidaturaB === 4) {
			////console.log(props.location.state.idCandidato);
			let candidato = candidatosDMR.find((candidato) => candidato.idCandidato == idCandidatoB);
			if (candidato === undefined) {
				candidato = candidatosDMR.find((candidato) => candidato.idCandidatoSup == idCandidatoB);
				suplentesSinPropietario(candidato);
			}
			if (candidato && candidato.tipoCandidato && candidato.tipoCandidato === 'S') {
				let nomPropietario = candidato.nombreSuplente;
				setpropietarioSuplente(nomPropietario);
			}
			setCandidato(candidato ? candidato : undefined);
			////console.log(candidato);
			//setCandidato(candidatos.find(candidato => candidato.idCandidato == idCandidatoB));
			descripcionAccionAfirmativa = burcarAccionAfirmatibaDesc(candidato) !== undefined ? burcarAccionAfirmatibaDesc(candidato).Descripcion : null;
		}
	}, [candidatosDMR]);

	useEffect(() => {
		//if (idTipoCandidaturaB === 8) {
		if (idTipoCandidaturaB === 5) {
			////console.log(idCandidatoB);
			let candidato = candidatosDRP.find((candidato) => candidato.idCandidato == idCandidatoB);
			if (candidato === undefined) {
				candidato = candidatosDRP.find((candidato) => candidato.idCandidatoSup == idCandidatoB);
				suplentesSinPropietario(candidato);
			}
			if (candidato && candidato.tipoCandidato && candidato.tipoCandidato === 'S') {
				let nomPropietario = candidato.nombreSuplente;
				setpropietarioSuplente(nomPropietario);
			}
			setCandidato(candidato ? candidato : undefined);
			////console.log(candidato);
			//setCandidato(candidatos.find(candidato => candidato.idCandidato == idCandidatoB));
			descripcionAccionAfirmativa = burcarAccionAfirmatibaDesc(candidato) !== undefined ? burcarAccionAfirmatibaDesc(candidato).Descripcion : null;
		}
	}, [candidatosDRP]);

	useEffect(() => {
		////console.log("idCandidatoB:=>", idCandidatoB);
		////console.log("idTipoCandidaturaB:=>", idTipoCandidaturaB);
		if (idTipoCandidaturaB === 6) {
			////console.log(props.location.state.idCandidato);
			let candidato = candidatosGubernatura.find((candidato) => candidato.idCandidato == idCandidatoB);
			////console.log(candidato);
			setCandidato(candidato ? candidato : undefined);
			if (candidato && candidato.tipoCandidato && candidato.tipoCandidato === 'S') {
				let propietarioSuplente = candidatosGubernatura.find((propietario) => propietario.idCandidatoSup == idCandidatoB);
				let nomPropietario = propietarioSuplente.nombreCandidato;
				setpropietarioSuplente(nomPropietario);
				//   console.log("PROPIETARIO", nomPropietario);
			}
			//console.log("Candidato encontrado*****", candidato, idCandidatoB);
			//setCandidato(candidatos.find(candidato => candidato.idCandidato == idCandidatoB));
		}
	}, [candidatosGubernatura]);

	useEffect(() => {
		if (idTipoCandidaturaB === 9) {
			////console.log(idCandidatoB);
			let candidato = candidatosPresidenteMunicipal.find((candidato) => candidato.idCandidato == idCandidatoB);
			////console.log(candidato);
			setCandidato(candidato ? candidato : undefined);
			// console.log("PROPIETARIO", candidato.tipoCandidato);
			if (candidato && candidato.tipoCandidato && candidato.tipoCandidato === 'S') {
				let propietarioSuplente = candidatosPresidenteMunicipal.find((propietario) => propietario.idCandidatoSup == idCandidatoB);
				let nomPropietario = propietarioSuplente.nombreCandidato;
				setpropietarioSuplente(nomPropietario);
				console.log('PROPIETARIO', nomPropietario);
			}
			////console.log(candidato);
			//setCandidato(candidatos.find(candidato => candidato.idCandidato == idCandidatoB));
		}
	}, [candidatosPresidenteMunicipal]);

	useEffect(() => {
		if (idTipoCandidaturaB === 2) {
			////console.log(idCandidatoB);
			let candidato = candidatosSMR.find((candidato) => candidato.idCandidato == idCandidatoB);
			if (candidato === undefined) {
				candidato = candidatosSMR.find((candidato) => candidato.idCandidatoSup == idCandidatoB);
				suplentesSinPropietario(candidato);
			}
			if (candidato && candidato.tipoCandidato && candidato.tipoCandidato === 'S') {
				let nomPropietario = candidato.nombreSuplente;
				setpropietarioSuplente(nomPropietario);
			}
			setCandidato(candidato ? candidato : undefined);
			////console.log(candidato);
			//setCandidato(candidatos.find(candidato => candidato.idCandidato == idCandidatoB));

			descripcionAccionAfirmativa = burcarAccionAfirmatibaDesc(candidato) !== undefined ? burcarAccionAfirmatibaDesc(candidato).Descripcion : null;
		}
	}, [candidatosSMR]);

	useEffect(() => {
		if (idTipoCandidaturaB === 3) {
			////console.log(idCandidatoB);
			let candidato = candidatosSRP.find((candidato) => candidato.idCandidato == idCandidatoB);
			if (candidato === undefined) {
				candidato = candidatosSRP.find((candidato) => candidato.idCandidatoSup == idCandidatoB);
				suplentesSinPropietario(candidato);
			}
			if (candidato && candidato.tipoCandidato && candidato.tipoCandidato === 'S') {
				let nomPropietario = candidato.nombreSuplente;
				setpropietarioSuplente(nomPropietario);
			}
			setCandidato(candidato ? candidato : undefined);
			////console.log(candidato);
			//setCandidato(candidatos.find(candidato => candidato.idCandidato == idCandidatoB));

			descripcionAccionAfirmativa = burcarAccionAfirmatibaDesc(candidato) !== undefined ? burcarAccionAfirmatibaDesc(candidato).Descripcion : null;
		}
	}, [candidatosSRP]);

	useEffect(() => {
		if (idTipoCandidaturaB === 1) {
			////console.log(idCandidatoB);
			let candidato = candidatosPresidente.find((candidato) => candidato.idCandidato == idCandidatoB);
			if (candidato && candidato.tipoCandidato && candidato.tipoCandidato === 'S') {
				let nomPropietario = candidato.nombreSuplente;
				setpropietarioSuplente(nomPropietario);
			}
			setCandidato(candidato ? candidato : undefined);
			////console.log(candidato);
			//setCandidato(candidatos.find(candidato => candidato.idCandidato == idCandidatoB));

			descripcionAccionAfirmativa = burcarAccionAfirmatibaDesc(candidato) !== undefined ? burcarAccionAfirmatibaDesc(candidato).Descripcion : null;
		}
	}, [candidatosPresidente]);

	useEffect(() => {
		//   console.log("Candidatos",candidato);
		////console.log("aCTORES POLITICOS EMBLEMA",actoresPoliticos);
		if (candidato && actoresPoliticos) {
			let actorPolitico;
			/*if (idTipoCandidaturaB == 6) {
				actorPolitico = actoresPoliticosGubernatura.find(
					(actorPolitico) => actorPolitico.idAsociacion == candidato.idAsociacion && actorPolitico.idTipoAsociacion == candidato.tipoAsociacion,
				);
			}*/
			//if (idTipoCandidaturaB == 7) {
			if (idTipoCandidaturaB == 4) {
				actorPolitico = actoresPoliticosDMR.find(
					(actorPolitico) => actorPolitico.idAsociacion == candidato.idAsociacion && actorPolitico.idTipoAsociacion == candidato.tipoAsociacion,
				);
			}
			//if (idTipoCandidaturaB == 8) {
			if (idTipoCandidaturaB == 5) {
				actorPolitico = actoresPoliticosDRP.find(
					(actorPolitico) => actorPolitico.idAsociacion == candidato.idAsociacion && actorPolitico.idTipoAsociacion == candidato.tipoAsociacion,
				);
			}
			/*if (idTipoCandidaturaB == 9) {
				actorPolitico = actoresPoliticosPresidenteMunicipal.find(
					(actorPolitico) => actorPolitico.idAsociacion == candidato.idAsociacion && actorPolitico.idTipoAsociacion == candidato.tipoAsociacion,
				);
			}*/
			if (idTipoCandidaturaB == 2) {
				actorPolitico = actoresPoliticosSMR.find(
					(actorPolitico) => actorPolitico.idAsociacion == candidato.idAsociacion && actorPolitico.idTipoAsociacion == candidato.tipoAsociacion,
				);
			}
			if (idTipoCandidaturaB == 3) {
				actorPolitico = actoresPoliticosSRP.find(
					(actorPolitico) => actorPolitico.idAsociacion == candidato.idAsociacion && actorPolitico.idTipoAsociacion == candidato.tipoAsociacion,
				);
			}
			if (idTipoCandidaturaB == 1) {
				actorPolitico = actoresPoliticosPresidente.find(
					(actorPolitico) => actorPolitico.idAsociacion == candidato.idAsociacion && actorPolitico.idTipoAsociacion == candidato.tipoAsociacion,
				);
			}
			if (actorPolitico != null) {
				var url_image = `${process.env.REACT_APP_API_URL}img${actorPolitico.emblema}`;
				setSrcEmblema(url_image);
				setNombreAsociacion(actorPolitico.nombreAsociacion);
			} else setSrcEmblema(NoDisponible);
		}
	}, [candidato, actoresPoliticos]);

	useEffect(() => {
		//if (hipervinculosDMR && idTipoCandidaturaB === 7) {
		if (hipervinculosDMR && idTipoCandidaturaB === 4) {
			let hipervinculosCandidato = hipervinculosDMR.filter((hipervinculo) => hipervinculo.idCandidato === idCandidatoB);
			if (hipervinculosCandidato) setHipervinculosCandidato([...hipervinculosCandidato]);
			////console.log("hipervinculos=>", [...hipervinculosCandidato]);
		}
	}, [hipervinculosDMR]);

	useEffect(() => {
		//if (hipervinculosDRP && idTipoCandidaturaB === 8) {
		if (hipervinculosDRP && idTipoCandidaturaB === 5) {
			let hipervinculosCandidato = hipervinculosDRP.filter((hipervinculo) => hipervinculo.idCandidato === idCandidatoB);
			if (hipervinculosCandidato) setHipervinculosCandidato([...hipervinculosCandidato]);
			////console.log("hipervinculos=>", [...hipervinculosCandidato]);
		}
	}, [hipervinculosDRP]);

	useEffect(() => {
		if (hipervinculosGubernatura && idTipoCandidaturaB === 6) {
			let hipervinculosCandidato = hipervinculosGubernatura.filter((hipervinculo) => hipervinculo.idCandidato === idCandidatoB);
			if (hipervinculosCandidato) setHipervinculosCandidato([...hipervinculosCandidato]);
			////console.log("hipervinculos=>", [...hipervinculosCandidato]);
		}
	}, [hipervinculosGubernatura]);

	useEffect(() => {
		if (hipervinculosPresidenteMunicipal && idTipoCandidaturaB === 9) {
			let hipervinculosCandidato = hipervinculosPresidenteMunicipal.filter((hipervinculo) => hipervinculo.idCandidato === idCandidatoB);
			if (hipervinculosCandidato) setHipervinculosCandidato([...hipervinculosCandidato]);
			////console.log("hipervinculos=>", [...hipervinculosCandidato]);
		}
	}, [hipervinculosPresidenteMunicipal]);

	useEffect(() => {
		if (hipervinculosSMR && idTipoCandidaturaB === 2) {
			let hipervinculosCandidato = hipervinculosSMR.filter((hipervinculo) => hipervinculo.idCandidato === idCandidatoB);
			if (hipervinculosCandidato) setHipervinculosCandidato([...hipervinculosCandidato]);
			////console.log("hipervinculos=>", [...hipervinculosCandidato]);
		}
	}, [hipervinculosSMR]);

	useEffect(() => {
		if (hipervinculosSRP && idTipoCandidaturaB === 3) {
			let hipervinculosCandidato = hipervinculosSRP.filter((hipervinculo) => hipervinculo.idCandidato === idCandidatoB);
			if (hipervinculosCandidato) setHipervinculosCandidato([...hipervinculosCandidato]);
			////console.log("hipervinculos=>", [...hipervinculosCandidato]);
		}
	}, [hipervinculosSRP]);

	useEffect(() => {
		if (hipervinculosPresidente && idTipoCandidaturaB === 1) {
			let hipervinculosCandidato = hipervinculosPresidente.filter((hipervinculo) => hipervinculo.idCandidato === idCandidatoB);
			if (hipervinculosCandidato) setHipervinculosCandidato([...hipervinculosCandidato]);
			////console.log("hipervinculos=>", [...hipervinculosCandidato]);
		}
	}, [hipervinculosPresidente]);

	useEffect(() => {
		//if (redesSocialesDMR && idTipoCandidaturaB === 7) {
		if (redesSocialesDMR && idTipoCandidaturaB === 4) {
			let redesSocialesCandidato = redesSocialesDMR.filter((redSocial) => redSocial.idCandidato === idCandidatoB);
			if (redesSocialesCandidato) setRedesSocialesCandidato([...redesSocialesCandidato]);
			////console.log("redesSociales=>", [...redesSocialesCandidato]);
		}
	}, [redesSocialesDMR]);

	useEffect(() => {
		//if (redesSocialesDRP && idTipoCandidaturaB === 8) {
		if (redesSocialesDRP && idTipoCandidaturaB === 5) {
			let redesSocialesCandidato = redesSocialesDRP.filter((redSocial) => redSocial.idCandidato === idCandidatoB);
			if (redesSocialesCandidato) setRedesSocialesCandidato([...redesSocialesCandidato]);
			////console.log("redesSociales=>", [...redesSocialesCandidato]);
		}
	}, [redesSocialesDRP]);

	useEffect(() => {
		if (redesSocialesGubernatura && idTipoCandidaturaB === 6) {
			let redesSocialesCandidato = redesSocialesGubernatura.filter((redSocial) => redSocial.idCandidato === idCandidatoB);
			if (redesSocialesCandidato) setRedesSocialesCandidato([...redesSocialesCandidato]);
			////console.log("redesSociales=>", [...redesSocialesCandidato]);
		}
	}, [redesSocialesGubernatura]);

	useEffect(() => {
		if (redesSocialesPresidenteMunicipal && idTipoCandidaturaB === 9) {
			let redesSocialesCandidato = redesSocialesPresidenteMunicipal.filter((redSocial) => redSocial.idCandidato === idCandidatoB);
			if (redesSocialesCandidato) setRedesSocialesCandidato([...redesSocialesCandidato]);
			////console.log("redesSociales=>", [...redesSocialesCandidato]);
		}
	}, [redesSocialesPresidenteMunicipal]);

	useEffect(() => {
		if (redesSocialesSMR && idTipoCandidaturaB === 2) {
			let redesSocialesCandidato = redesSocialesSMR.filter((redSocial) => redSocial.idCandidato === idCandidatoB);
			if (redesSocialesCandidato) setRedesSocialesCandidato([...redesSocialesCandidato]);
			////console.log("redesSociales=>", [...redesSocialesCandidato]);
		}
	}, [redesSocialesSMR]);

	useEffect(() => {
		if (redesSocialesSRP && idTipoCandidaturaB === 3) {
			let redesSocialesCandidato = redesSocialesSRP.filter((redSocial) => redSocial.idCandidato === idCandidatoB);
			if (redesSocialesCandidato) setRedesSocialesCandidato([...redesSocialesCandidato]);
			////console.log("redesSociales=>", [...redesSocialesCandidato]);
		}
	}, [redesSocialesSRP]);

	useEffect(() => {
		if (redesSocialesPresidente && idTipoCandidaturaB === 1) {
			let redesSocialesCandidato = redesSocialesPresidente.filter((redSocial) => redSocial.idCandidato === idCandidatoB);
			if (redesSocialesCandidato) setRedesSocialesCandidato([...redesSocialesCandidato]);
			////console.log("redesSociales=>", [...redesSocialesCandidato]);
		}
	}, [redesSocialesPresidente]);

	useEffect(() => {
		if (candidato && candidato.urlFoto && candidato.estatusVal) {
			////console.log(candidato.idCandidato);
			//var url_image = `${process.env.REACT_APP_API_URL}img/fotocandidato/${candidato.idCandidato}.jpg`;
			var urlTmp = candidato.urlFoto.replace(`${process.env.REACT_APP_RUTA_ELIMINAR_IMAGENES}`, '');
			var url_image = `${process.env.REACT_APP_API_URL}${urlTmp}`;
			setSrcFoto(url_image);
		} else if (candidato) {
			if (candidato.sexo == 'M') setSrcFoto(Mujer);
			else setSrcFoto(Hombre);
		}
		if (candidato && candidato.idGrado) {
			let grado = gradosAcademicos.find((ga) => ga.idGrado === candidato.idGrado);
			////console.log("gradoAcademico:=>", gradoAcademico);
			if (grado) setGrado(grado);
		}
	}, [candidato]);

	useEffect(() => {
		if (candidato && candidato.idGrado) {
			let grado = gradosAcademicos.find((ga) => ga.idGrado === candidato.idGrado);
			////console.log("gradoAcademico:=>", gradoAcademico);
			if (grado) setGrado(grado);
		}
	}, [candidato, gradosAcademicos]);

	useEffect(() => {
		if (candidato) {
			//console.log("CANDIDATO ESTADO", candidato);
			if (candidato.idEstadoEleccion) {
				let entidadEleccion = entidades.find((entidad) => entidad.idEstado === candidato.idEstadoEleccion);

				if (entidadEleccion) {
					//console.log("CANDIDATO ESTADO1", candidato)

					setNombreEstadCand(entidadEleccion.nombreEstado);
					//console.log("CANDIDATO ESTADO1", nombreEstadoCand)
				} else setNombreEstadCand(true);
			}
			if (candidato.entidadFederativa) {
				let entidadCamp = entidades.find((entidad) => entidad.idEstado === candidato.entidadFederativa);
				if (entidadCamp) setNombreEstadoCamp(entidadCamp.nombreEstado.toLowerCase());
				else setNombreEstadoCamp(true);
			}
		}
	}, [candidato, entidades]);

	useEffect(() => {
		//if (cursosCandidatosDMR && idTipoCandidaturaB === 7) {
		if (cursosCandidatosDMR && idTipoCandidaturaB === 4) {
			let cursosCandidato = cursosCandidatosDMR.filter((curso) => curso.idCandidato === idCandidatoB);
			if (cursosCandidato) setCursosCandidato(cursosCandidato);
			else setCursosCandidato(true);
		}
	}, [cursosCandidatosDMR]);

	useEffect(() => {
		//if (cursosCandidatosDRP && idTipoCandidaturaB === 8) {
		if (cursosCandidatosDRP && idTipoCandidaturaB === 5) {
			let cursosCandidato = cursosCandidatosDRP.filter((curso) => curso.idCandidato === idCandidatoB);
			if (cursosCandidato) setCursosCandidato(cursosCandidato);
			else setCursosCandidato(true);
		}
	}, [cursosCandidatosDRP]);

	useEffect(() => {
		if (cursosCandidatosGubernatura && idTipoCandidaturaB === 6) {
			let cursosCandidato = cursosCandidatosGubernatura.filter((curso) => curso.idCandidato === idCandidatoB);
			if (cursosCandidato) setCursosCandidato(cursosCandidato);
			else setCursosCandidato(true);
		}
	}, [cursosCandidatosGubernatura]);

	useEffect(() => {
		if (cursosCandidatosPresidenteMunicipal && idTipoCandidaturaB === 9) {
			let cursosCandidato = cursosCandidatosPresidenteMunicipal.filter((curso) => curso.idCandidato === idCandidatoB);
			if (cursosCandidato) setCursosCandidato(cursosCandidato);
			else setCursosCandidato(true);
		}
	}, [cursosCandidatosPresidenteMunicipal]);

	useEffect(() => {
		if (cursosCandidatosSMR && idTipoCandidaturaB === 2) {
			let cursosCandidato = cursosCandidatosSMR.filter((curso) => curso.idCandidato === idCandidatoB);
			if (cursosCandidato) setCursosCandidato(cursosCandidato);
			else setCursosCandidato(true);
		}
	}, [cursosCandidatosSMR]);

	useEffect(() => {
		//if (cursosCandidatosSRP && idTipoCandidaturaB === 2) {
		if (cursosCandidatosSRP && idTipoCandidaturaB === 3) {
			let cursosCandidato = cursosCandidatosSRP.filter((curso) => curso.idCandidato === idCandidatoB);
			if (cursosCandidato) setCursosCandidato(cursosCandidato);
			else setCursosCandidato(true);
		}
	}, [cursosCandidatosSRP]);

	useEffect(() => {
		if (cursosCandidatosPresidente && idTipoCandidaturaB === 1) {
			let cursosCandidato = cursosCandidatosPresidente.filter((curso) => curso.idCandidato === idCandidatoB);
			if (cursosCandidato) setCursosCandidato(cursosCandidato);
			else setCursosCandidato(true);
		}
	}, [cursosCandidatosPresidente]);

	const renderHipervinculos = (idTipoHiper) => {
		switch (idTipoHiper) {
			case 1:
				return <img key={`hiper-img-${idTipoHiper}`} className="img-responsive" alt="Únete a nuestra comunidad" src={Tipo1} />;
			case 4:
				return <img key={`hiper-img-${idTipoHiper}`} className="img-responsive" alt="Únete a nuestra comunidad" src={Tipo4} height="80px" />;
			case 5:
				return <img key={`hiper-img-${idTipoHiper}`} className="img-responsive" alt="Únete a nuestra comunidad" src={Tipo5} />;
			case 6:
				return <img key={`hiper-img-${idTipoHiper}`} className="img-responsive" alt="Únete a nuestra comunidad" src={Tipo6} />;
			case 7:
				return <img key={`hiper-img-${idTipoHiper}`} className="img-responsive" alt="Únete a nuestra comunidad" src={Tipo7} />;
			default:
				return <div key={`hiper-img-${idTipoHiper}`} style={{minHeight: '80px'}}></div>;
		}
	};

	const buildHipervinculo = (hipervinculo) => {
		//console.log("hipervinculo", hipervinculo)
		switch (hipervinculo.idTipoHiper) {
			case 8:
				//  //console.log("hipervinculo", hipervinculo.hipervinculo);
				////console.log("hipervinculo1", `${process.env.REACT_APP_API_URL}` + hipervinculo.hipervinculo.replace(`${process.env.REACT_APP_RUTA_ELIMINAR_HIPERVINCULOS}`, ""));
				return `${process.env.REACT_APP_API_URL}` + hipervinculo.hipervinculo.replace(`${process.env.REACT_APP_RUTA_ELIMINAR_HIPERVINCULOS}`, '');
			case 7:
				////console.log("hipervinculo2", `${process.env.REACT_APP_API_URL}` + hipervinculo.hipervinculo.replace(`${process.env.REACT_APP_RUTA_ELIMINAR_HIPERVINCULOS}`, ""));
				return `${process.env.REACT_APP_API_URL}` + hipervinculo.hipervinculo.replace(`${process.env.REACT_APP_RUTA_ELIMINAR_HIPERVINCULOS}`, '');
			case 5:
				//  //console.log("hipervinculo3", `${process.env.REACT_APP_API_URL}` + hipervinculo.hipervinculo.replace(`${process.env.REACT_APP_RUTA_ELIMINAR_HIPERVINCULOS}`, ""));
				return `${process.env.REACT_APP_API_URL}` + hipervinculo.hipervinculo.replace(`${process.env.REACT_APP_RUTA_ELIMINAR_HIPERVINCULOS}`, '');

			default:
				return hipervinculo.hipervinculo;
		}
	};

	const buildRutaFicha = (candidato) => {
		return (
			`${process.env.REACT_APP_API_URL}` +
			`${process.env.REACT_APP_RUTA_ARCHIVOS}` +
			candidato.nombreCandidato.replaceAll(' ', '_') +
			'_' +
			candidato.idCandidato +
			'.pdf'
		);
	};

	const burcarAccionAfirmatibaDesc = (candidato) => {
		if (candidato != null && candidato != undefined)
			return accionesAfirmativas.find((item) => item.idAccionAfirmativa === candidato.candidaturaAccionAfirmativa);
		else return '';
	};

	return (
		<>
			<div className="container">
				<div className="clearfix ">&nbsp;</div>
				<div className="row">
					<div className=" col-xs-12 ">
						<div className="breadcrumb" style={{backgroundColor: 'white'}}>
							<li>
								<Link to="/">Volver a la búsqueda</Link>
							</li>
							<li> Elecciones </li>
							<li>Candidatas y Candidatos, Conóceles</li>
							<li className="active">Ficha candidatura</li>
							<div className="divider"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<Card className="cardResponsabilidadUTTyPDP">
						<Row>
							<Col>
								<span className="alineacion-justificada">
									<span className="gris2-mensaje-consulta">
										{' '}
										{
											//<span className="negro2-mensaje-consulta"> es proporcionada de manera obligatoria</span>
										}
										La información de este portal fue proporcionada de forma obligatoria por las personas candidatas que participan en el Proceso Electoral Federal 2024, por lo tanto,
										es <span className="negro2-mensaje-consulta">responsabilidad de los actores políticos dicho contenido.</span> El Instituto únicamente promueve su difusión.
									</span>
								</span>
							</Col>
						</Row>
					</Card>
				</div>
			</div>
			{candidato != null && candidato != undefined && !candidato && !srcFoto && !srcEmblema && !redesSocialesCandidato && !cursosCandidato && (
				<div id="imprimir" className="container candidatos">
					<div className="clearfix">&nbsp;</div>
					<div id="contenedor-cargando" className="col-xs-12 text-center">
						<Spin spinning={true} />
					</div>
				</div>
			)}
			{candidato != null && candidato != undefined && candidato && srcFoto && srcEmblema && redesSocialesCandidato && cursosCandidato && (
				<>
					<div id="imprimir" className="container candidatos">
						<div className="clearfix">&nbsp;</div>
						<div className="row candidato-infos">
							<div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 text-center">
								<div className="clearfix">&nbsp;</div>
								<div>
									<Avatar
										size={200}
										style={{border: '6px solid #b6b6b6'}}
										src={
											<img
												src={srcFoto}
												key="foto"
												onError={(e) => {
													if (candidato.sexo == 'M') e.target.src = Mujer;
													else e.target.src = Hombre;
												}}
											/>
										}
									/>
								</div>
								<div className="clearfix">&nbsp;</div>
								<div>
									{candidato.tipoAsociacion == 5 || candidato.tipoAsociacion == 3 ? (
										<img
											src={srcEmblema}
											key="emblema"
											onError={(e) => {
												e.target.src = NoDisponible;
											}}
											style={
												candidato.tipoAsociacion == 3 && nombreAsociacion.includes('JUNTOS')
													? {width: '160px'}
													: candidato.tipoAsociacion == 3 && !nombreAsociacion.includes('JUNTOS')
													? {width: '240px'}
													: {width: '80px'}
											}
										/>
									) : (
										<Avatar
											shape="square"
											size={80}
											src={
												<img
													src={srcEmblema}
													key="emblema"
													onError={(e) => {
														e.target.src = NoDisponible;
													}}
												/>
											}
											//  style={{ width: candidato.tipoAsociacion !== 1 ? "250px" : "70px" }}
										/>
									)}
									{
										//console.log ("entidad asociación ", candidato.tipoAsociacion,  candidato.idEstadoEleccion)
									}
								</div>
								<div>
									<span className="gris-ficha" data-det="nombreCandidato" style={{marginTop: '25px'}}>
										{candidato.nombreCandidato ? candidato.nombreCandidato : `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
									</span>
								</div>
								<div>
									<span data-det="nombreCandidato" style={{marginTop: '15px'}}>
										{nombreAsociacion ? nombreAsociacion : `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
									</span>
								</div>
								<div className="clearfix">&nbsp;</div>
							</div>
							<div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 border-left-separacion">
								<Card className="cardSeccion">
									<div className="row">
										<div className="col-lg-12">
											<div className="clearfix">&nbsp;</div>
											<span className="gris-ficha">Datos Generales</span>
										</div>
										<div className="col-sm-6 col-md-6 col-lg-4">
											<div className="label-data propietario">Persona propietaria: </div>
											{candidato.tipoCandidato === 'P' ? (
												<div data-det="nombreCandidato">
													{candidato.nombreCandidato ? candidato.nombreCandidato : `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
												</div>
											) : candidato.tipoCandidato === 'S' ? (
												<div data-det="nombreCandidato">
													{propietarioSuplente ? propietarioSuplente : `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
												</div>
											) : (
												<div data-det="nombreCandidato">
													{candidato.nombreSuplente ? candidato.nombreSuplente : `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
												</div>
											)}
											<div className="clearfix">&nbsp;</div>
										</div>
										{candidato.idTipoCandidatura != null && candidato.idTipoCandidatura != 1 && (
											<div className="col-sm-6 col-md-6 col-lg-4">
												<div className="label-data">Persona suplente: </div>
												{candidato.tipoCandidato === 'P' ? (
													<div data-det="nombreCandidato">
														{candidato.nombreSuplente ? candidato.nombreSuplente : `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
													</div>
												) : (
													<div data-det="nombreCandidato">
														{candidato.nombreCandidato
															? candidato.nombreCandidato
															: `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
													</div>
												)}
												<div className="clearfix">&nbsp;</div>
											</div>
										)}
										<div className="col-sm-6 col-md-6 col-lg-4">
											<div className="label-data">Cargo: </div>
											<div data-det="nombreCandidato">
												{candidato.nombreCorto ? candidato.nombreCorto : `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
											</div>
											<div className="clearfix">&nbsp;</div>
										</div>
										<div className="col-sm-6 col-md-6 col-lg-4">
											<div className="label-data">Edad: </div>
											<div data-det="nombreCandidato">
												{candidato.edad ? candidato.edad : `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
											</div>
											<div className="clearfix">&nbsp;</div>
										</div>
										{candidato.idTipoCandidatura != null && candidato.idTipoCandidatura == 8 && (
											<>
												<div className="col-sm-6 col-md-12 col-lg-8 circunscripcion">
													<div className="label-data">Circunscripción:</div>
													<div data-det="idCircunscripcionEleccion">
														{candidato.idCircunscripcionEleccion
															? candidato.idCircunscripcionEleccion
															: `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
													</div>
													<div className="clearfix">&nbsp;</div>
												</div>
											</>
										)}
										{candidato.idTipoCandidatura != null && (
											<>
												{((candidato.idTipoCandidatura !== 1) && (candidato.idTipoCandidatura !== 3)) && (
													<div className="col-sm-6 col-md-6 col-lg-4 entidad">
														<div className="label-data">Estado:</div>
														<div data-det="nombreEstado">
															{candidato.idEstadoEleccion ? nombreEstadoCand : `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
														</div>
														<div className="clearfix">&nbsp;</div>
													</div>
												)}
												{
													//console.log("tipo y municipio ",  candidato.idTipoCandidatura, candidato.nombreMunicipio )
												}
												{candidato.idTipoCandidatura === 7 ? (
													<div className="col-sm-6 col-md-6 col-lg-4">
														<div className="label-data">Distrito:</div>
														<div className="label-distrito" data-det="nombreDistrito">
															{candidato.nombreDistrito
																? candidato.nombreDistrito
																: `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
														</div>
														<div className="clearfix">&nbsp;</div>
													</div>
												) : (
													candidato.idTipoCandidatura === 9 && (
														<div className="col-sm-6 col-md-6 col-lg-4">
															<div className="label-data">Municipio:</div>
															<div className="label-distrito" data-det="nombreDistrito">
																{candidato.nombreMunicipio
																	? candidato.nombreMunicipio
																	: `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
															</div>
															<div className="clearfix">&nbsp;</div>
														</div>
													)
												)}
											</>
										)}
										<div className="col-sm-6 col-md-6 col-lg-4">
											<div className="label-data">Sexo: </div>
											<div data-det="sexo">
												&nbsp;
												{candidato.sexo
													? candidato.sexo === 'H'
														? 'HOMBRE'
														: candidato.sexo === 'M'
														? 'MUJER'
														: 'NO BINARIO'
													: `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
											</div>
											<div className="clearfix">&nbsp;</div>
										</div>
										{candidato.idNumFormula !== null && candidato.idNumFormula < 100000 && (
											<div className="col-sm-6 col-md-6 col-lg-4">
												<div className="label-data">Número de fórmula:</div>
												<div data-det="numListaSenador">
													&nbsp;{candidato.idNumFormula ? candidato.idNumFormula : `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
												</div>
												<div className="clearfix">&nbsp;</div>
											</div>
										)}
										{descripcionAccionAfirmativa !== null && descripcionAccionAfirmativa !== undefined && (
											<div className="col-sm-6 col-md-6 col-lg-4">
												<div className="label-data">Acción afirmativa:</div>
												<div data-det="numListaSenador">
													&nbsp;
													{descripcionAccionAfirmativa !== null && descripcionAccionAfirmativa !== undefined
														? descripcionAccionAfirmativa
														: `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
												</div>
												<div className="clearfix">&nbsp;</div>
											</div>
										)}
									</div>
								</Card>
								<div className="clearfix">&nbsp;</div>
								<Card className="cardSeccion">
									<div className="row">
										<div className="col-lg-12">
											<div>
												<span className="gris-ficha"> Medios de contacto</span>
											</div>
										</div>
										<div className="col-lg-9">
											<div className="row">
												<div className="col-xs-12 col-md-12 col-lg-6">
													<div className="label-data">Dirección de casa de campaña: </div>
													<div>
														<span data-det="calle">
															{candidato.calle ? (
																<> {'Calle:' + candidato.calle}</>
															) : (
																`${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`
															)}
														</span>
														<span className="num-ext" data-det="numExt">
															{candidato.calle && candidato.numExt ? <>&nbsp;{candidato.numExt}</> : ''}
														</span>
														<span className="num-int" data-det="numInt">
															{candidato.calle && candidato.numInt ? <>,&nbsp;Num. Int. {candidato.numInt}</> : ''}
														</span>
														<span className="colonia" data-det="colonia">
															{candidato.calle && candidato.colonia ? <>,&nbsp;Col. {candidato.colonia}</> : ''}
														</span>
														<span>{candidato.calle ? <>,&nbsp;</> : ''}</span>
														<span data-det="nombreMunicipio" style={{textTransform: 'capitalize'}}>
															{candidato.calle && candidato.casaCampaniaMunicipio ? (
																<>{candidato.casaCampaniaMunicipio.toLowerCase() + ','}</>
															) : (
																''
															)}
														</span>
														<span className="cp" data-det="cp">
															{candidato.calle && candidato.cp ? <>&nbsp; CP. {candidato.cp}</> : ''}
														</span>
														<span className="nombre-estado" data-det="nombreEstado">
															{candidato.calle && candidato.entidadFederativa && nombreEstadoCamp !== true ? <>,</> : ''}
														</span>
														<span className="nombre-estado" data-det="nombreEstado" style={{textTransform: 'capitalize'}}>
															{candidato.calle && candidato.entidadFederativa ? <>&nbsp; {nombreEstadoCamp}</> : ''}
														</span>
													</div>
													<div className="clearfix">&nbsp;</div>
												</div>
												<div className="col-sm-6 col-md-6 col-lg-6">
													{!candidato.telefonoPublico2 || !candidato.telefonoPublico3 ? (
														<div className="label-data">Teléfono público: </div>
													) : (
														candidato.telefonoPublico &&
														(candidato.telefonoPublico2 || candidato.telefonoPublico3) && (
															<div className="label-data">Teléfonos públicos: </div>
														)
													)}
													<div>
														{/*<span className="lada" data-det="lada">{candidato.telefonoPublico && candidato.lada ? <>{candidato.lada}&nbsp;</> : ""}</span>*/}

														<span data-det="telefonoPublico">
															{candidato.telefonoPublico
																? candidato.telefonoPublico
																: `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
														</span>
														{candidato.telefonoPublico2 ? (
															<>
																<br /> <span data-det="telefonoPublico">{candidato.telefonoPublico2}</span>
															</>
														) : (
															''
														)}
														{candidato.telefonoPublico3 ? (
															<>
																<br /> <span data-det="telefonoPublico">{candidato.telefonoPublico3}</span>
															</>
														) : (
															''
														)}
													</div>
													<div className="clearfix">&nbsp;</div>
												</div>
												<div className="col-sm-6 col-md-6 col-lg-6">
													<div className="label-data">Correo electrónico público: </div>
													<div>
														<span data-det="correoElecPublico" style={{wordBreak: 'break-all'}}>
															{candidato.correoElecPublico
																? candidato.correoElecPublico
																: `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
														</span>
													</div>
													<div className="clearfix">&nbsp;</div>
												</div>
												<div className="col-sm-6 col-md-6 col-lg-6">
													<div className="label-data">Página web: </div>
													<div>
														<span data-det="correoElecPublico">
															{candidato.paginaWeb ? (
																<a target="_blank" href={candidato.paginaWeb}>
																	{candidato.paginaWeb}
																</a>
															) : (
																`${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`
															)}
														</span>
													</div>
													<div className="clearfix">&nbsp;</div>
												</div>
											</div>
										</div>
										<div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
											<div className="row">
												<div className="col-xs-12">
													<div className="label-data">Redes sociales: </div>
												</div>
												<div className="col-xs-6 col-sm-6 col-md-6 col-lg-12">
													{redesSocialesCandidato.length ? (
														<div className="row" style={{width: '200px'}}>
															{redesSocialesCandidato.map((element) => {
																if (element.idTipoRed === 1)
																	return (
																		<div className="col-xs-3">
																			<a key="facebook" href={element.descripcionRed} target="_blank">
																				<FacebookOutlined className="icono-red" />
																			</a>
																		</div>
																	);
																if (element.idTipoRed === 2)
																	return (
																		<div className="col-xs-3">
																			<a key="twitter" href={element.descripcionRed} target="_blank">
																				<TwitterOutlined className="icono-red" />
																			</a>
																		</div>
																	);
																if (element.idTipoRed === 3)
																	return (
																		<div className="col-xs-3">
																			<a key="instagram" href={element.descripcionRed} target="_blank">
																				<InstagramOutlined className="icono-red" />
																			</a>
																		</div>
																	);
																if (element.idTipoRed === 4)
																	return (
																		<div className="col-xs-3">
																			<a key="youtube" href={element.descripcionRed} target="_blank">
																				<YoutubeOutlined className="icono-red" />
																			</a>
																		</div>
																	);
																if (element.idTipoRed === 5)
																	return (
																		<div className="col-xs-3">
																			<a key="tiktok" href={element.descripcionRed} target="_blank">
																				<img
																					className="icono-red"
																					style={{width: '28px', height: '28px'}}
																					src={tiktok}
																				/>
																				;
																			</a>
																		</div>
																	);
															})}
														</div>
													) : (
														<span data-det="redesSociales">La candidatura no proporcionó información</span>
													)}
													<div className="clearfix">&nbsp;</div>
												</div>
											</div>
										</div>
									</div>
								</Card>
							</div>
						</div>
					</div>
					<div className="clearfix">&nbsp;</div>
					{candidato && (candidato.validaCuestionario !== undefined || candidato.validaCuestionario) ? (
						<>
							{candidato.AF1 || candidato.AF2 || candidato.AF3 || candidato.AF4 || candidato.AF5 || candidato.AF7 || candidato.AF6 ? (
								<>
									<div className="containerAF">
										<div id="imprimir" className="container candidatos">
											{candidato.AF1 && (
												<div className="col-xs-12 col-sm-6 col-md-6">
													<div className="icon-container-AF">
														<span style={{paddingTop: '1px'}}>
															<CheckCircleOutlined className="icono-af" />
														</span>
														<span className="textAf">Candidatura con autoadscripción indígena</span>
													</div>
												</div>
											)}
											{candidato.AF2 && (
												<div className="col-xs-12 col-sm-6 col-md-6">
													<div className="icon-container-AF">
														<span style={{paddingTop: '1px'}}>
															<CheckCircleOutlined className="icono-af" />
														</span>
														<span className="textAf">Candidatura con discapacidad</span>
													</div>
												</div>
											)}
											{candidato.AF3 && (
												<div className="col-xs-12 col-sm-6 col-md-6">
													<div className="icon-container-AF">
														<span style={{paddingTop: '1px'}}>
															<CheckCircleOutlined className="icono-af" />
														</span>
														<span className="textAf">Candidatura afromexicana</span>
													</div>
												</div>
											)}
											{candidato.AF4 && (
												<div className="col-xs-12 col-sm-6 col-md-6">
													<div className="icon-container-AF">
														<span style={{paddingTop: '1px'}}>
															<CheckCircleOutlined className="icono-af" />
														</span>
														<span className="textAf">Candidatura perteneciente a la comunidad LGBTTTIQ+</span>
													</div>
												</div>
											)}
											{candidato.AF5 && (
												<div className="col-xs-12 col-sm-6 col-md-6">
													<div className="icon-container-AF">
														<span style={{paddingTop: '1px'}}>
															<CheckCircleOutlined className="icono-af" />
														</span>
														<span className="textAf">Candidatura mexicana migrante</span>
													</div>
												</div>
											)}
											{candidato.AF6 && (
												<div className="col-xs-12 col-sm-6 col-md-6">
													<div className="icon-container-AF">
														<span style={{paddingTop: '1px'}}>
															<CheckCircleOutlined className="icono-af" />
														</span>
														<span className="textAf">Candidatura perteneciente a la población de personas jóvenes</span>
													</div>
												</div>
											)}
											{candidato.AF7 && (
												<div className="col-xs-12 col-sm-6 col-md-6">
													<div className="icon-container-AF">
														<span style={{paddingTop: '1px'}}>
															<CheckCircleOutlined className="icono-af" />
														</span>
														<span className="textAf">Candidatura perteneciente a la población de personas mayores</span>
													</div>
												</div>
											)}
										</div>
									</div>
								</>
							) : null}
						</>
					) : null}
					<div id="imprimir" className="container candidatos">
						<div className="clearfix">&nbsp;</div>
						{candidato && candidato.estatusVal == 1 ? (
							<div>
								<Card className="cardSeccionBlack">
									<div className="row">
										<div className="col-lg-12">
											<div>
												<span className="tituloBlack">¿Por qué quiero ocupar un cargo público?</span>
											</div>
										</div>
										<div className="col-lg-12">
											<span className="contenidoBlack">
												{candidato.descripcionCandidato
													? candidato.descripcionCandidato
													: `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
											</span>
										</div>
									</div>
								</Card>
								<div className="clearfix">&nbsp;</div>
								<Card className="cardSeccion">
									<div className="row">
										<div className="col-lg-12">
											<div>
												<span className="gris-ficha">Dos principales propuestas</span>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="label-data">Propuesta 1: </div>
											<div>
												<span className="ajustar">
													{candidato.propuesta1 ? candidato.propuesta1 : `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
												</span>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="label-data">Propuesta 2: </div>
											<div>
												<span className="ajustar">
													{candidato.propuesta2 ? candidato.propuesta2 : `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
												</span>
											</div>
										</div>
									</div>
								</Card>
								<div className="clearfix">&nbsp;</div>
								<Card className="cardSeccion">
									<div className="row">
										<div className="col-lg-12">
											<div>
												<span className="gris-ficha">
													Propuesta en materia de género o del grupo en situación de discriminación que representa
												</span>
											</div>
										</div>
										<div className="col-lg-12">
											<span className="ajustar">{candidato.propuesta3 ? candidato.propuesta3 : 'No se cuenta con propuesta'}</span>
										</div>
									</div>
								</Card>
								<div className="clearfix">&nbsp;</div>
								<Card className="cardSeccion">
									<div className="row">
										<div className="col-lg-12">
											<div>
												<span className="gris-ficha">Trayectoria política y/o participación social</span>
											</div>
										</div>
										<div className="col-lg-12 ">
											<span className="ajustar">
												{candidato.descripcionTP ? candidato.descripcionTP : `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
											</span>
										</div>
									</div>
								</Card>
								<div className="clearfix">&nbsp;</div>
								<Card className="cardSeccion">
									<div className="row">
										<div className="col-lg-12">
											<div>
												<span className="gris-ficha">Historia profesional y/o laboral</span>
											</div>
										</div>
										<div className="col-lg-12">
											<span className="ajustar">
												{candidato.descripcionHLC ? candidato.descripcionHLC : `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
											</span>
										</div>
									</div>
								</Card>
								<div className="clearfix">&nbsp;</div>
								<Row gutter={[16, 16]}>
									<Col xs={{span: 24}} lg={{span: 24}}>
										<Card className="cardSeccionBlack">
											<div>
												<span className="tituloBlack">Estudios</span>
											</div>
											<div>
												<span className="contenidoBlack">
													<b>Grado máximo de estudios:</b>{' '}
													{candidato.idGrado && grado ? grado.descripcionGrado : `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
												</span>
											</div>
											<div>
												<span className="contenidoBlack">
													<b>Estatus:</b>{' '}
													{candidato.idGrado && grado ? grado.estatus : `${process.env.REACT_APP_ETIQUETA_SIN_REGISTRO}`}
												</span>
											</div>
										</Card>
									</Col>
								</Row>
								<div className="clearfix">&nbsp;</div>
								<Row gutter={[16, 16]}>
									<Col xs={{span: 24}} lg={{span: 24}}>
										<Card className="cardSeccionBlack">
											<div>
												<span className="tituloBlack">Cursos</span>
											</div>
											<div>
												{cursosCandidato.length > 0 ? (
													<>
														{cursosCandidato.length == 1 ? (
															<span className="contenidoBlack">{cursosCandidato[0].descripcion}</span>
														) : (
															<>
																<ol id={`curso-${cursosCandidato[0]}`} className="contenidoBlack">
																	{cursosCandidato.map((item) => {
																		return <li>{item.descripcion}</li>;
																	})}
																</ol>
															</>
														)}
													</>
												) : (
													<span className="contenidoBlack">La candidatura no proporcionó información</span>
												)}
											</div>
										</Card>
									</Col>
								</Row>
							</div>
						) : null}
						<div className="clearfix">&nbsp;</div>
						{/*hipervinculosCandidato.length > 0 && (
							<Card className="cardSeccion">
								<Row gutter={[16, 16]}>
									<Col xs={{span: 24}} lg={{span: 24}}>
										<span className="gris-ficha">Consulta los sitios de interés con información relacionada de las y los candidatos</span>
									</Col>
									{hipervinculosCandidato.map((item) => {
										return (
											<Col
												key={`col-hiper-${item.idTipoHiper}`}
												xs={{span: 24}}
												sm={{span: 12}}
												md={{span: 8}}
												lg={{span: 8}}
												className="text-center hiperviculuos-link"
											>
												<a key={`hiper-a-${item.idTipoHiper}`} href={buildHipervinculo(item)} target="_blank">
													<div className="container-hipervinculos">{renderHipervinculos(item.idTipoHiper)}</div>
													<div style={{paddingTop: '10px'}}>{item.descripcionHiper}</div>
												</a>
											</Col>
										);
									})}
								</Row>
							</Card>
						)*/}
						{/*<div className="clearfix ">&nbsp;</div>
                        <div className="row">
                            <div className="col-xs-12 text-left">
                                <span className="genero-informa">
                                    Fecha de actualización: Candidaturas vigentes al {dia} de {meses[mes]} de {anio} INE/CG337/2021.
                                </span>
                            </div>
            			      </div>*/}
						<div className="clearfix">&nbsp;</div>
						<Row>
							<Col xs={{span: 12}}>
								<div style={{display: 'flex', justifyContent: 'flex-start'}}>
									<Link
										to={{
											pathname: '/',
											state: {regresar: regresar},
										}}
									>
										<Button key="regresar" type="primary" className="secondary-button">
											Regresar
										</Button>
									</Link>
								</div>
							</Col>
							{(candidato && (candidato.estatusVal == 1 || candidato.validaCuestionario == 1)) ||
							(hipervinculosCandidato && hipervinculosCandidato.length > 0) ? (
								<Col xs={{span: 12}}>
									<div style={{display: 'flex', justifyContent: 'flex-end'}}>
										<Button key="buscar" type="link" className="primary-button" target="_blank" href={buildRutaFicha(candidato)}>
											<DownloadOutlined />
											Descargar
										</Button>
									</div>
								</Col>
							) : (
								<></>
							)}
						</Row>
					</div>
				</>
			)}
			{candidato === null ||
				(candidato === undefined && (
					<>
						<Row style={{padding: '50px', paddingLeft: '200px'}}>
							<Col>
								<div style={{display: 'flex', justifyContent: 'flex-start'}}>
									<Link
										to={{
											pathname: '/',
											state: {regresar: regresar},
										}}
									>
										<Button key="regresar" type="primary" className="secondary-button">
											Regresar
										</Button>
									</Link>
								</div>
							</Col>
							<Col style={{paddingLeft: '300px'}}>
								<div style={{fontSize: '20px'}}>
									<b>Sin datos.</b>
								</div>
							</Col>
						</Row>
					</>
				))}
			<div className="clearfix">&nbsp;</div>
			<div className="clearfix">&nbsp;</div>
		</>
	);
};

export default DetalleCandidato;
