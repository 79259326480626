import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {DownloadOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import PropTypes from 'prop-types';

const ExportCSV = ({csvData, fileName, tipoCandidatosBusqueda, tipoCandidatura, setCargandoExcel}) => {
	//console.log("TIPO CANDIDATURAX", tipoCandidatura);
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const fileExtension = '.xlsx';

	const buildRutaFicha = (candidato) => {
		if (candidato.estatusVal == 1 || candidato.validaCuestionario == 1)
			return (
				`${process.env.REACT_APP_EXCEL_URL}` +
				`${process.env.REACT_APP_RUTA_ARCHIVOS}` +
				candidato.nombreCandidato.replaceAll(' ', '_') +
				'_' +
				candidato.idCandidato +
				'.pdf'
			);
		return '';
	};
	const buildRutaFichaSup = (candidato) => {
		if (candidato.estatusValSup == 1 || candidato.validaCuestionarioSup == 1)
			return (
				`${process.env.REACT_APP_EXCEL_URL}` +
				`${process.env.REACT_APP_RUTA_ARCHIVOS}` +
				candidato.nombreSuplente.replaceAll(' ', '_') +
				'_' +
				candidato.idCandidatoSup +
				'.pdf'
			);
		return '';
	};
	const buildRutaFichaSupSinPro = (candidato) => {
		if (candidato.estatusVal == 1 || candidato.validaCuestionario == 1)
			return (
				`${process.env.REACT_APP_EXCEL_URL}` +
				`${process.env.REACT_APP_RUTA_ARCHIVOS}` +
				candidato.nombreSuplente.replaceAll(' ', '_') +
				'_' +
				candidato.idCandidatoSup +
				'.pdf'
			);
		return '';
	};

	const exportToCSV = (csvData, fileName, tipoCandidatosBusqueda, tipoCandidatura) => {
		//console.log("datos csv", csvData, fileName, tipoCandidatosBusqueda, tipoCandidatura)
		setCargandoExcel(true);
		let csvDataShow = [];
		let header = [];
		if (tipoCandidatura == 4) {
			// Diputados MR
			if (tipoCandidatosBusqueda === 'P') {
				csvData.forEach((element) => {
					csvDataShow = [
						...csvDataShow,
						{
							Entidad: element.nombreEstado,
							Distrito: element.nombreDistrito,
							'Actor Político': element.nombreAsociacion,
							'Persona Propietaria': element.nombreCandidato,
							'CurriculumV Persona propietaria': element.tipoCandidato === 'P' ? buildRutaFicha(element) : null,
							'Persona Suplente': element.nombreSuplente,
							'CurriculumV Persona Suplente': element.tipoCandidato === 'P' ? buildRutaFichaSup(element) : buildRutaFichaSupSinPro(element),
						},
					];
				});
				header = [
					'Entidad',
					'Distrito',
					'Actor Político',
					'Persona Propietaria',
					'CurriculumV Persona propietaria',
					'Persona Suplente',
					'CurriculumV Persona Suplente',
				];
			} else {
				csvData.forEach((element) => {
					csvDataShow = [
						...csvDataShow,
						{
							Entidad: element.nombreEstado,
							Distrito: element.nombreDistrito,
							'Actor Político': element.nombreAsociacion,
							'Persona Propietaria': element.nombreCandidato,
							'CurriculumV Persona propietaria': buildRutaFicha(element),
							'Persona Suplente': element.nombreSuplente,
							'CurriculumV Persona Suplente': buildRutaFichaSup(element),
						},
					];
				});
				header = [
					'Entidad',
					'Distrito',
					'Actor Político',
					'Persona Propietaria',
					'CurriculumV Persona propietaria',
					'Persona Suplente',
					'CurriculumV Persona Suplente',
				];
			}
		} else if (tipoCandidatura == 5) {
			// Diputados RP
			if (tipoCandidatosBusqueda === 'P') {
				csvData.forEach((element) => {
					csvDataShow = [
						...csvDataShow,
						{
							Circunscripción: element.idCircunscripcionEleccion,
							'No. Lista': element.numListaDiputado,
							'Actor Político': element.nombreAsociacion,
							'Persona Propietaria': element.nombreCandidato,
							'CurriculumV Persona propietaria': element.tipoCandidato === 'P' ? buildRutaFicha(element) : null,
							'Persona Suplente': element.nombreSuplente,
							'CurriculumV Persona Suplente': element.tipoCandidato === 'P' ? buildRutaFichaSup(element) : buildRutaFichaSupSinPro(element),
						},
					];
				});
				header = [
					'Circunscripción',
					'No. Lista',
					'Actor Político',
					'Persona Propietaria',
					'CurriculumV Persona propietaria',
					'Persona Suplente',
					'CurriculumV Persona Suplente',
				];
			} else {
				csvData.forEach((element) => {
					csvDataShow = [
						...csvDataShow,
						{
							Circunscripción: element.idCircunscripcionEleccion,
							'No. Lista': element.numListaDiputado,
							'Actor Político': element.nombreAsociacion,
							'Persona Propietaria': element.nombreCandidato,
							'CurriculumV Persona propietaria': buildRutaFicha(element),
							'Persona Suplente': element.nombreSuplente,
							'CurriculumV Persona Suplente': buildRutaFichaSup(element),
						},
					];
				});
				header = [
					'Circunscripción',
					'No. Lista',
					'Actor Político',
					'Persona Propietaria',
					'CurriculumV Persona propietaria',
					'Persona Suplente',
					'CurriculumV Persona Suplente',
				];
			}
		}
		if (tipoCandidatura == 2) {
			// Senadores
			//console.log('csvData:::::', csvData);
			if (tipoCandidatosBusqueda === 'P') {
				csvData.forEach((element) => {
					csvDataShow = [
						...csvDataShow,
						{
							Entidad: element.nombreEstado,
							//'No. de Fórmula': element.idNumFormula,
							'No. Lista': element.numListaDiputado,
							'Actor Político': element.nombreAsociacion,
							'Persona Propietaria': element.nombreCandidato,
							'CurriculumV Persona propietaria': element.tipoCandidato === 'P' ? buildRutaFicha(element) : null,
							'Persona Suplente': element.nombreSuplente,
							'CurriculumV Persona Suplente': element.tipoCandidato === 'P' ? buildRutaFichaSup(element) : buildRutaFichaSupSinPro(element),
						},
					];
				});
				header = [
					'Entidad',
					'No. Lista',
					'Actor Político',
					'Persona Propietaria',
					'CurriculumV Persona propietaria',
					'Persona Suplente',
					'CurriculumV Persona Suplente',
				];
			} else {
				csvData.forEach((element) => {
					csvDataShow = [
						...csvDataShow,
						{
							Entidad: element.nombreEstado,
							//'No. de Fórmula': element.idNumFormula,
							'No. Lista': element.numListaDiputado,
							'Actor Político': element.nombreAsociacion,
							'Persona Propietaria': element.nombreCandidato,
							'CurriculumV Persona propietaria': buildRutaFicha(element),
							'Persona Suplente': element.nombreSuplente,
							'CurriculumV Persona Suplente': buildRutaFichaSup(element),
						},
					];
				});
				header = [
					'Entidad',
					'No. Lista',
					'Actor Político',
					'Persona Propietaria',
					'CurriculumV Persona propietaria',
					'Persona Suplente',
					'CurriculumV Persona Suplente',
				];
			}
		}
		if (tipoCandidatura == 3) {
			// Senadores
			if (tipoCandidatosBusqueda === 'P') {
				csvData.forEach((element) => {
					csvDataShow = [
						...csvDataShow,
						{
							//Estado: element.nombreEstado,
							//'No. de Fórmula': element.idNumFormula,
							'No. Lista': element.numListaDiputado,
							'Actor Político': element.nombreAsociacion,
							'Persona Propietaria': element.nombreCandidato,
							'CurriculumV Persona propietaria': element.tipoCandidato === 'P' ? buildRutaFicha(element) : null,
							'Persona Suplente': element.nombreSuplente,
							'CurriculumV Persona Suplente': element.tipoCandidato === 'P' ? buildRutaFichaSup(element) : buildRutaFichaSupSinPro(element),
						},
					];
				});
				header = [
					'No. Lista',
					'Actor Político',
					'Persona Propietaria',
					'CurriculumV Persona propietaria',
					'Persona Suplente',
					'CurriculumV Persona Suplente',
				];
			} else {
				csvData.forEach((element) => {
					csvDataShow = [
						...csvDataShow,
						{
							//Estado: element.nombreEstado,
							//'No. de Fórmula': element.idNumFormula,
							'No. Lista': element.numListaDiputado,
							'Actor Político': element.nombreAsociacion,
							'Persona Propietaria': element.nombreCandidato,
							'CurriculumV Persona propietaria': buildRutaFicha(element),
							'Persona Suplente': element.nombreSuplente,
							'CurriculumV Persona Suplente': buildRutaFichaSup(element),
						},
					];
				});
				header = [
					'No. Lista',
					'Actor Político',
					'Persona Propietaria',
					'CurriculumV Persona propietaria',
					'Persona Suplente',
					'CurriculumV Persona Suplente',
				];
			}
		}
		if (tipoCandidatura == 1) {
			// Senadores
			if (tipoCandidatosBusqueda === 'P') {
				csvData.forEach((element) => {
					csvDataShow = [
						...csvDataShow,
						{
							'Actor Político': element.nombreAsociacion,
							'Persona Propietaria': element.nombreCandidato,
							'CurriculumV Persona Propietaria': buildRutaFicha(element),
						},
					];
				});
				header = ['Actor Político', 'Persona Propietaria', 'CurriculumV Persona Propietaria'];
			}
		}
		/* 		if (tipoCandidatura == 6) { 
			if (tipoCandidatosBusqueda === 'P') {
				csvData.forEach(element => {
					csvDataShow = [...csvDataShow, {
						"Estado": element.nombreEstado,
						"No. de Fórmula": element.idNumFormula,
						"Actor Político": element.nombreAsociacion,
						"Propietario": element.nombreCandidato,
						'CurriculumV Propietario': buildRutaFicha(element),
						"Suplente": element.nombreSuplente,
						"CurriculumV Suplente": buildRutaFichaSup(element),
					}];
				});
				header = ['Estado', 'No. de Fórmula', 'Actor Político', 'Propietario', 'CurriculumV Propietario', 'Suplente','CurriculumV Suplente'];
			} else {
				csvData.forEach(element => {
					csvDataShow = [...csvDataShow, {
						"Estado": element.nombreEstado,
						"No. de Fórmula": element.idNumFormula,
						"Actor Político": element.nombreAsociacion,
						"Propietario": element.nombreCandidato,
						'CurriculumV Propietario': buildRutaFicha(element),
						"Suplente": element.nombreSuplente,
						"CurriculumV Suplente": buildRutaFichaSup(element),
					}];
				});
				header = ['Estado', 'No. de Fórmula', 'Actor Político', 'Propietario', 'CurriculumV', 'Suplente','CurriculumV Suplente'];
			}
		}
		if (tipoCandidatura == 9) { 
			if (tipoCandidatosBusqueda === 'P') {
				csvData.forEach(element => {
					csvDataShow = [...csvDataShow, {
						"Estado": element.nombreEstado,
						"No. de Fórmula": element.idNumFormula,
						"Actor Político": element.nombreAsociacion,
						"Propietario": element.nombreCandidato,
						'CurriculumV Propietario': buildRutaFicha(element),
						"Suplente": element.nombreSuplente,
						"CurriculumV Suplente": buildRutaFichaSup(element),
					}];
				});
				header = ['Estado', 'No. de Fórmula', 'Actor Político', 'Propietario', 'CurriculumV Propietario', 'Suplente','CurriculumV Suplente'];
			} else {
				csvData.forEach(element => {
					csvDataShow = [...csvDataShow, {
						"Estado": element.nombreEstado,
						"No. de Fórmula": element.idNumFormula,
						"Actor Político": element.nombreAsociacion,
						"Propietario": element.nombreCandidato,
						'CurriculumV Propietario': buildRutaFicha(element),
						"Suplente": element.nombreSuplente,
						"CurriculumV Suplente": buildRutaFichaSup(element),
					}];
				});
				header = ['Estado', 'No. de Fórmula', 'Actor Político', 'Propietario', 'CurriculumV', 'Suplente','CurriculumV Suplente'];
			}
		}
 */

		const ws = XLSX.utils.json_to_sheet(csvDataShow, {header});
		//const ws = XLSX.utils.json_to_sheet(csvData);
		//XLSX.utils.sheet_add_aoa(ws, [["CurriculumV"]], { origin: "F1" });
		//XLSX.utils.sheet_add_aoa(ws, [[rutasExcel]], { origin: "F2" });
		//XLSX.utils.sheet_add_aoa(ws, [["https://cycc-prueba.ine.mx/documentos/ficha/"+${nombreCandidato}+'_'+${idCandidatura}+'.pdf']], {origin:"F2"});

		const wb = {Sheets: {data: ws}, SheetNames: ['data']};

		const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
		const data = new Blob([excelBuffer], {type: fileType});
		FileSaver.saveAs(data, fileName + fileExtension);
		setCargandoExcel(false);
	};

	return (
		<div>
			<Button
				key="descargar"
				type="primary"
				onClick={(e) => {
					exportToCSV(csvData, fileName, tipoCandidatosBusqueda, tipoCandidatura);
				}}
				className="primary-button"
			>
				<DownloadOutlined />
				Descargar
			</Button>
		</div>
	);
};

ExportCSV.propTypes = {};

export default ExportCSV;
