import React, {useState, useEffect, createContext} from 'react';

export const DataContext = createContext();
var elementoJSON = [];

const jsonGrado = [
	{idGrado: 1, descripcionGrado: 'Postdoctorado'},
	{idGrado: 2, descripcionGrado: 'Doctorado'},
	{idGrado: 3, descripcionGrado: 'Maestría'},
	{idGrado: 4, descripcionGrado: 'Especialidad'},
	{idGrado: 5, descripcionGrado: 'Licenciatura'},
	{idGrado: 6, descripcionGrado: 'Preparatoria'},
	{idGrado: 9, descripcionGrado: 'Técnica'},
	{idGrado: 7, descripcionGrado: 'Secundaria'},
	{idGrado: 8, descripcionGrado: 'Primaria'},
];

const jsonCircunscripciones = [
	{idEstado: 14, idCircunscripcion: 1, descripcionCircunscripcion: 'Circunscripción 1'},
	{idEstado: 25, idCircunscripcion: 1, descripcionCircunscripcion: 'Circunscripción 1'},
	{idEstado: 18, idCircunscripcion: 1, descripcionCircunscripcion: 'Circunscripción 1'},
	{idEstado: 10, idCircunscripcion: 1, descripcionCircunscripcion: 'Circunscripción 1'},
	{idEstado: 26, idCircunscripcion: 1, descripcionCircunscripcion: 'Circunscripción 1'},
	{idEstado: 8, idCircunscripcion: 1, descripcionCircunscripcion: 'Circunscripción 1'},
	{idEstado: 2, idCircunscripcion: 1, descripcionCircunscripcion: 'Circunscripción 1'},
	{idEstado: 3, idCircunscripcion: 1, descripcionCircunscripcion: 'Circunscripción 1'},

	{idEstado: 24, idCircunscripcion: 2, descripcionCircunscripcion: 'Circunscripción 2'},
	{idEstado: 11, idCircunscripcion: 2, descripcionCircunscripcion: 'Circunscripción 2'},
	{idEstado: 1, idCircunscripcion: 2, descripcionCircunscripcion: 'Circunscripción 2'},
	{idEstado: 28, idCircunscripcion: 2, descripcionCircunscripcion: 'Circunscripción 2'},
	{idEstado: 5, idCircunscripcion: 2, descripcionCircunscripcion: 'Circunscripción 2'},
	{idEstado: 32, idCircunscripcion: 2, descripcionCircunscripcion: 'Circunscripción 2'},
	{idEstado: 19, idCircunscripcion: 2, descripcionCircunscripcion: 'Circunscripción 2'},

	{idEstado: 7, idCircunscripcion: 3, descripcionCircunscripcion: 'Circunscripción 3'},
	{idEstado: 4, idCircunscripcion: 3, descripcionCircunscripcion: 'Circunscripción 3'},
	{idEstado: 31, idCircunscripcion: 3, descripcionCircunscripcion: 'Circunscripción 3'},
	{idEstado: 30, idCircunscripcion: 3, descripcionCircunscripcion: 'Circunscripción 3'},
	{idEstado: 27, idCircunscripcion: 3, descripcionCircunscripcion: 'Circunscripción 3'},
	{idEstado: 23, idCircunscripcion: 3, descripcionCircunscripcion: 'Circunscripción 3'},
	{idEstado: 20, idCircunscripcion: 3, descripcionCircunscripcion: 'Circunscripción 3'},

	{idEstado: 9, idCircunscripcion: 4, descripcionCircunscripcion: 'Circunscripción 4'},
	{idEstado: 21, idCircunscripcion: 4, descripcionCircunscripcion: 'Circunscripción 4'},
	{idEstado: 17, idCircunscripcion: 4, descripcionCircunscripcion: 'Circunscripción 4'},
	{idEstado: 12, idCircunscripcion: 4, descripcionCircunscripcion: 'Circunscripción 4'},
	{idEstado: 29, idCircunscripcion: 4, descripcionCircunscripcion: 'Circunscripción 4'},
	{idEstado: 13, idCircunscripcion: 4, descripcionCircunscripcion: 'Circunscripción 4'},

	{idEstado: 15, idCircunscripcion: 5, descripcionCircunscripcion: 'Circunscripción 5'},
	{idEstado: 6, idCircunscripcion: 5, descripcionCircunscripcion: 'Circunscripción 5'},
	{idEstado: 16, idCircunscripcion: 5, descripcionCircunscripcion: 'Circunscripción 5'},
	{idEstado: 22, idCircunscripcion: 5, descripcionCircunscripcion: 'Circunscripción 5'},
];
/*
const jsonCircunscripciones = [
	{idEstado: 1, idCircunscripcion: 1, descripcionCircunscripcion: 'Circunscripción 1'},
	{idEstado: 10, idCircunscripcion: 1, descripcionCircunscripcion: 'Circunscripción 1'},
	{idEstado: 13, idCircunscripcion: 1, descripcionCircunscripcion: 'Circunscripción 1'},
	{idEstado: 23, idCircunscripcion: 1, descripcionCircunscripcion: 'Circunscripción 1'},
	{idEstado: 20, idCircunscripcion: 1, descripcionCircunscripcion: 'Circunscripción 1'},
	{idEstado: 28, idCircunscripcion: 1, descripcionCircunscripcion: 'Circunscripción 1'},

	/*   { "idEstado": 2, "idCircunscripcion": 1, "descripcionCircunscripcion": "Circunscripción 1" },
           { "idEstado": 3, "idCircunscripcion": 1, "descripcionCircunscripcion": "Circunscripción 1" },
           { "idEstado": 8, "idCircunscripcion": 1, "descripcionCircunscripcion": "Circunscripción 1" },
           { "idEstado": 10, "idCircunscripcion": 1, "descripcionCircunscripcion": "Circunscripción 1" },
           { "idEstado": 14, "idCircunscripcion": 1, "descripcionCircunscripcion": "Circunscripción 1" },
           { "idEstado": 18, "idCircunscripcion": 1, "descripcionCircunscripcion": "Circunscripción 1" },
           { "idEstado": 25, "idCircunscripcion": 1, "descripcionCircunscripcion": "Circunscripción 1" },
           { "idEstado": 26, "idCircunscripcion": 1, "descripcionCircunscripcion": "Circunscripción 1" },
           { "idEstado": 1, "idCircunscripcion": 2, "descripcionCircunscripcion": "Circunscripción 2" },
           { "idEstado": 5, "idCircunscripcion": 2, "descripcionCircunscripcion": "Circunscripción 2" },
           { "idEstado": 11, "idCircunscripcion": 2, "descripcionCircunscripcion": "Circunscripción 2" },
           { "idEstado": 19, "idCircunscripcion": 2, "descripcionCircunscripcion": "Circunscripción 2" },
           { "idEstado": 22, "idCircunscripcion": 2, "descripcionCircunscripcion": "Circunscripción 2" },
           { "idEstado": 24, "idCircunscripcion": 2, "descripcionCircunscripcion": "Circunscripción 2" },
           { "idEstado": 28, "idCircunscripcion": 2, "descripcionCircunscripcion": "Circunscripción 2" },
           { "idEstado": 32, "idCircunscripcion": 2, "descripcionCircunscripcion": "Circunscripción 2" },
           { "idEstado": 4, "idCircunscripcion": 3, "descripcionCircunscripcion": "Circunscripción 3" },
           { "idEstado": 7, "idCircunscripcion": 3, "descripcionCircunscripcion": "Circunscripción 3" },
           { "idEstado": 20, "idCircunscripcion": 3, "descripcionCircunscripcion": "Circunscripción 3" },
           { "idEstado": 23, "idCircunscripcion": 3, "descripcionCircunscripcion": "Circunscripción 3" },
           { "idEstado": 27, "idCircunscripcion": 3, "descripcionCircunscripcion": "Circunscripción 3" },
           { "idEstado": 30, "idCircunscripcion": 3, "descripcionCircunscripcion": "Circunscripción 3" },
           { "idEstado": 31, "idCircunscripcion": 3, "descripcionCircunscripcion": "Circunscripción 3" },
           { "idEstado": 9, "idCircunscripcion": 4, "descripcionCircunscripcion": "Circunscripción 4" },
           { "idEstado": 12, "idCircunscripcion": 4, "descripcionCircunscripcion": "Circunscripción 4" },
           { "idEstado": 17, "idCircunscripcion": 4, "descripcionCircunscripcion": "Circunscripción 4" },
           { "idEstado": 21, "idCircunscripcion": 4, "descripcionCircunscripcion": "Circunscripción 4" },
           { "idEstado": 29, "idCircunscripcion": 4, "descripcionCircunscripcion": "Circunscripción 4" },
           { "idEstado": 6, "idCircunscripcion": 5, "descripcionCircunscripcion": "Circunscripción 5" },
           { "idEstado": 13, "idCircunscripcion": 5, "descripcionCircunscripcion": "Circunscripción 5" },
           { "idEstado": 15, "idCircunscripcion": 5, "descripcionCircunscripcion": "Circunscripción 5" },
           { "idEstado": 16, "idCircunscripcion": 5, "descripcionCircunscripcion": "Circunscripción 5" }
   
];
*/
const jsonRangoEdades = [
	{idRangoEdad: '17-20', descRangoEdad: 'Menor de 21'},//Por registros con 17 años en bd
	{idRangoEdad: '21-29', descRangoEdad: '21 a 29 años'},
	{idRangoEdad: '30-40', descRangoEdad: '30 a 40 años'},
	{idRangoEdad: '41-50', descRangoEdad: '41 a 50 años'},
	{idRangoEdad: '51-60', descRangoEdad: '51 a 60 años'},
	{idRangoEdad: '61', descRangoEdad: 'Mayor de 60'},
];

const jsonGeneros = [
	{label: 'Hombre', value: 'H'},
	{label: 'Mujer', value: 'M'},
	{label: 'No binario', value: 'NB'},
];

export default function DataProvider(props) {
	const {children} = props;
	let idactores = [];
	const [tiposCandidaturas, setTiposCandidaturas] = useState([]);
	const [entidades, setEntidades] = useState([]);
	const [circunscripciones, setCircunscripciones] = useState(jsonCircunscripciones);
	const [gradosAcademicos, setGradosAcademicos] = useState([]);
	const [rangosEdades, setRangosEdades] = useState(jsonRangoEdades);
	const [grado, setGrado] = useState(jsonGrado);
	const [generos, setGeneros] = useState(jsonGeneros);
	const [actoresPoliticos, setActoresPoliticos] = useState([]);
	const [secciones, setSecciones] = useState([]);

	const [candidatosDMR, setCandidatosDMR] = useState([]);
	const [candidatosDRP, setCandidatosDRP] = useState([]);
	const [candidatosSMR, setCandidatosSMR] = useState([]);
	const [candidatosSRP, setCandidatosSRP] = useState([]);
	const [candidatosPresidente, setCandidatosPresidente] = useState([]);

	const [redesSocialesDMR, setRedesSocialesDMR] = useState(null);
	const [redesSocialesDRP, setRedesSocialesDRP] = useState(null);
	const [redesSocialesSMR, setRedesSocialesSMR] = useState(null);
	const [redesSocialesSRP, setRedesSocialesSRP] = useState(null);
	const [redesSocialesPresidente, setRedesSocialesPresidente] = useState(null);

	const [hipervinculosDMR, setHipervinculosDMR] = useState(null);
	const [hipervinculosDRP, setHipervinculosDRP] = useState(null);
	const [hipervinculosSMR, setHipervinculosSMR] = useState(null);
	const [hipervinculosSRP, setHipervinculosSRP] = useState(null);
	const [hipervinculosPresidente, setHipervinculosPresidente] = useState(null);

	const [actoresPoliticosDMR, setActoresPoliticosDMR] = useState(null);
	const [actoresPoliticosDRP, setActoresPoliticosDRP] = useState(null);
	const [actoresPoliticosSMR, setActoresPoliticosSMR] = useState(null);
	const [actoresPoliticosSRP, setActoresPoliticosSRP] = useState(null);
	const [actoresPoliticosPresidente, setActoresPoliticosPresidente] = useState(null);

	const [cursosCandidatosDRP, setCursosCandidatosDRP] = useState(null);
	const [cursosCandidatosDMR, setCursosCandidatosDMR] = useState(null);
	const [cursosCandidatosSMR, setCursosCandidatosSMR] = useState(null);
	const [cursosCandidatosSRP, setCursosCandidatosSRP] = useState(null);
	const [cursosCandidatosPresidente, setCursosCandidatosPresidente] = useState(null);

	const [jsonDMR, setJsonDMR] = useState(null);
	const [jsonDRP, setJsonDRP] = useState(null);
	const [jsonSMR, setJsonSMR] = useState(null);
	const [jsonSRP, setJsonSRP] = useState(null);
	const [jsonPresidentes, setJsonPresidentes] = useState(null);

	const [jsonPresidenteGraficaPorcentaje, setJsonPresidenteGraficaPorcentaje] = useState();
	const [jsonDiputadosGraficaPorcentaje, setJsonDiputadosGraficaPorcentaje] = useState();
	const [jsonGubernaturaGraficaPorcentaje, setJsonGubernaturaGraficaPorcentaje] = useState();
	const [jsonEstadosGraficaPorcentaje, setJsonEstadosGraficaPorcentaje] = useState();
	const [jsonPresidentesGrafica, setJsonPresidentesGrafica] = useState();
	const [jsonSenadoresGraficaPorcentaje, setJsonSenadoresGraficaPorcentaje] = useState();
	const [jsonEtiquetas, setJsonEtiquetas] = useState();
	const [jsonLigasConsulta, setJsonLigasConsulta] = useState();
	const [jsonGraficaRangoEdad, setJsonGraficaRangoEdad] = useState();
	const [jsonGraficaSexo, setJsonGraficaSexo] = useState();
	const [jsonGraficaGradoAcademico, setJsonGraficaGradoAcademico] = useState();
	const [jsonGraficaNivelSE, setJsonGraficaNivelSE] = useState();
	const [jsonGraficasCandidatos, setJsonGraficasCandidatos] = useState();
	const [jsonGraficasPartidos, setJsonGraficasPartidos] = useState();
	const [jsonGraficasAccionesAfirmativas, setJsonGraficasAccionesAfirmativas] = useState();

	const [jsonCargados, setJsonCargados] = useState(false);
	const [entidadesDetalleProceso, setEntidadesDetalleProceso] = useState([]);

	const [candidatosGubernatura, setCandidatosGubernatura] = useState([]);
	const [jsonGubernatura, setJsonGubernatura] = useState(null);
	const [hipervinculosGubernatura, setHipervinculosGubernatura] = useState(null);
	const [redesSocialesGubernatura, setRedesSocialesGubernatura] = useState(null);
	const [cursosCandidatosGubernatura, setCursosCandidatosGubernatura] = useState(null);
	const [actoresPoliticosGubernatura, setActoresPoliticosGubernatura] = useState(null);

	const [candidatosPresidenteMunicipal, setCandidatosPresidenteMunicipal] = useState([]);
	const [jsonPresidenteMunicipal, setJsonPresidenteMunicipal] = useState(null);
	const [hipervinculosPresidenteMunicipal, setHipervinculosPresidenteMunicipal] = useState(null);
	const [redesSocialesPresidenteMunicipal, setRedesSocialesPresidenteMunicipal] = useState(null);
	const [cursosCandidatosPresidenteMunicipal, setCursosCandidatosPresidenteMunicipal] = useState(null);
	const [actoresPoliticosPresidenteMunicipal, setActoresPoliticosPresidenteMunicipal] = useState(null);
	const [municipios, setMunicipios] = useState([]);
	const [regresar, setRegresar] = useState(false);
	//console.log("circuncripción", circunscripciones);
	const [accionesAfirmativas, setAccionesAfirmativas] = useState([]);

	useEffect(() => {
		////console.log("Entro al provider data");
		let dateCache = localStorage.getItem(`${process.env.REACT_APP_LOCAL_CACHE_DATE}`);
		let jsonEstadosEleccion = localStorage.getItem(`${process.env.REACT_APP_LOCAL_JSON_ESTADOS_ELECCION}`);
		let newDate = new Date().getTime();
		let consumirJson2 = false;
		if (dateCache === null || dateCache === undefined) {
			consumirJson2 = true;
			dateCache = newDate;
			localStorage.setItem(`${process.env.REACT_APP_LOCAL_CACHE_DATE}`, dateCache);
		} else dateCache = parseInt(dateCache);
		let restaFechas = newDate / 86400 / 1000 - dateCache / 86400 / 1000;
		if (restaFechas < 0 || restaFechas > parseInt(`${process.env.REACT_APP_LOCAL_CACHE_EXPIRATION}`)) {
			localStorage.setItem(`${process.env.REACT_APP_LOCAL_CACHE_DATE}`, newDate);
			consumirJson2 = true;
		}
		if (!consumirJson2 && (jsonEstadosEleccion === null || jsonEstadosEleccion === undefined)) {
			consumirJson2 = true;
		} else if (!consumirJson2) {
			try {
				jsonEstadosEleccion = JSON.parse(jsonEstadosEleccion);
			} catch (e) {
				consumirJson2 = true;
			}
		}

		if (consumirJson2) {
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/catalogoEstadosSecciones.json`)
				.then((r) => r.json())
				.then((data) => {
					////console.log(data);
					//setEntidades(data.estados);
					setSecciones(data.secciones);
					localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_ESTADOS_ELECCION}`, JSON.stringify(data));
					////console.log(data.estados.find(estado => estado.idEstado === 32));
				});
		} else {
			//setEntidades(jsonEstadosEleccion.estados);
			setSecciones(jsonEstadosEleccion.secciones);
		}

		let consumirJson = true;
		if (consumirJson) {
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/catalogoTiposCandidaturas.json`)
				.then((r) => r.json())
				.then((data) => {
					//console.log("data", `${process.env.REACT_APP_API_URL}documentos/json/catalogoTiposCandidaturas.json`);
					setTiposCandidaturas(data);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_TIPOS_CANDIDATURAS}`, JSON.stringify(data));
					console.log('tipo =>', tiposCandidaturas);
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/catalogoGradoAcademico.json`)
				.then((r) => r.json())
				.then((data) => {
					////console.log(data);
					setGradosAcademicos(data);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_GRADOS_ACADEMICOS}`, JSON.stringify(data));
					////console.log(data.estados.find(estado => estado.idEstado === 32));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/presidentes.json`)
				.then((r) => r.json())
				.then((data) => {
					setJsonPresidentes(data);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_DIPUTADOS_MR}`, JSON.stringify(data));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/senadoresRP.json`)
				.then((r) => r.json())
				.then((data) => {
					/* data.candidatos.forEach(actorPolitico => {
                         let actorestmp;
                         actorPolitico.nombreEstado== "AGUASCALIENTES"?
                             idactores=actorpolitico.idAsociacion:""
                     });
                     actorestmp = idactores.filter((item,index)=>{
                         return idactores.indexOf(item) === index;
                       })
                      //console.log("Actores", actorestmp)*/
					/*setActoresPoliticos(data.actoresPoliticos);
                    setCandidatos(data.candidatos);
                    setHipervinculos(data.hipervinculos);*/
					setJsonSRP(data);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_DIPUTADOS_MR}`, JSON.stringify(data));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/senadoresMR.json`)
				.then((r) => r.json())
				.then((data) => {
					/* data.candidatos.forEach(actorPolitico => {
                         let actorestmp;
                         actorPolitico.nombreEstado== "AGUASCALIENTES"?
                             idactores=actorpolitico.idAsociacion:""
                     });
                     actorestmp = idactores.filter((item,index)=>{
                         return idactores.indexOf(item) === index;
                       })
                      //console.log("Actores", actorestmp)*/
					/*setActoresPoliticos(data.actoresPoliticos);
                    setCandidatos(data.candidatos);
                    setHipervinculos(data.hipervinculos);*/
					setJsonSMR(data);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_DIPUTADOS_MR}`, JSON.stringify(data));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/diputadosMR.json`)
				.then((r) => r.json())
				.then((data) => {
					/* data.candidatos.forEach(actorPolitico => {
                         let actorestmp;
                         actorPolitico.nombreEstado== "AGUASCALIENTES"?
                             idactores=actorpolitico.idAsociacion:""
                     });
                     actorestmp = idactores.filter((item,index)=>{
                         return idactores.indexOf(item) === index;
                       })
                      //console.log("Actores", actorestmp)*/
					/*setActoresPoliticos(data.actoresPoliticos);
                    setCandidatos(data.candidatos);
                    setHipervinculos(data.hipervinculos);*/
					setJsonDMR(data);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_DIPUTADOS_MR}`, JSON.stringify(data));
				});

			fetch(`${process.env.REACT_APP_API_URL}documentos/json/diputadosRP.json`)
				.then((r) => r.json())
				.then((data) => {
					setJsonDRP(data);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_DIPUTADOS_RP}`, JSON.stringify(data));
				});
			/*fetch(`${process.env.REACT_APP_API_URL}documentos/json/senadoresFederalesMR.json`)
                .then((r) => r.json())
                .then((data) => {
                    setJsonSMR(data);
                    //localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
                });*/
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/senadoresGraficaPorcentaje.json`)
				.then((r) => r.json())
				.then((data) => {
					setJsonSenadoresGraficaPorcentaje(data);
					// console.log("dip graficas", jsonDiputadosGraficaPorcentaje)
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/diputadosGraficaPorcentaje.json`)
				.then((r) => r.json())
				.then((data) => {
					setJsonDiputadosGraficaPorcentaje(data);
					// console.log("dip graficas", jsonDiputadosGraficaPorcentaje)
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/presidentesGraficaPorcentaje.json`)
				.then((r) => r.json())
				.then((data) => {
					setJsonPresidenteGraficaPorcentaje(data);
					// console.log("dip graficas", jsonDiputadosGraficaPorcentaje)
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/gubernaturaGraficaPorcentaje.json`)
				.then((r) => r.json())
				.then((data) => {
					// console.log("data1", data)
					const dataout = JSON.parse(JSON.stringify(data));
					setJsonGubernaturaGraficaPorcentaje(JSON.parse(JSON.stringify(dataout)));
					// console.log("jsonGubernaturaGraficaPorcentaje2x", jsonGubernaturaGraficaPorcentaje.desglose);
					//console.log("jsonDiputaciones1", jsonDiputadosGraficaPorcentaje.desglose);
					// console.log("gobinicial graficas", jsonGubernaturaGraficaPorcentaje)
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/presidentesMunicipalesGraficaPorcentaje.json`)
				.then((r) => r.json())
				.then((data) => {
					setJsonPresidentesGrafica(data);
					//          console.log("jsonpresidentes1", jsonPresidentesGrafica);

					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});
			/* fetch(`${process.env.REACT_APP_API_URL}documentos/json/gubernaturaPorcentajePorEntidad.json`)
                .then((r) => r.json())
                .then((data) => {
                    // console.log("data1", data)
                   // setJsonEstadosGraficaPorcentaje(data.Aguascalientes);
                   const dataout = JSON.parse(JSON.stringify(data));
                   setJsonEstadosGraficaPorcentaje(data);
                   //console.log("jsonEstadosGraficaPorcentaje2x1", jsonEstadosGraficaPorcentaje +".1", data, "data",dataout);
                    //console.log("jsonDiputaciones1", jsonDiputadosGraficaPorcentaje.desglose);
                    //{data.hidalgo.desglose.map(element => {
                      //console.log("jsonGraficaPorcentaje3", element)
                  // })
                   //}
                    // console.log("gobinicial graficas", jsonGubernaturaGraficaPorcentaje)
                    //localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
                });*/
			/*fetch(`${process.env.REACT_APP_API_URL}documentos/json/senadoresGraficaPorcentaje.json`)
                .then((r) => r.json())
                .then((data) => {
                    setJsonSenadoresGraficaPorcentaje(data);
                    //localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
                });*/
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/etiquetas.json`)
				.then((r) => r.json())
				.then((data) => {
					setJsonEtiquetas(data);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/parametros.json`)
				.then((r) => r.json())
				.then((data) => {
					setJsonLigasConsulta(data);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/graficaSexo.json`)
				.then((r) => r.json())
				.then((data) => {
					setJsonGraficaSexo(data);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/graficaRangoEdad.json`)
				.then((r) => r.json())
				.then((data) => {
					setJsonGraficaRangoEdad(data);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/gradoAcademicoPorcentaje.json`)
				.then((r) => r.json())
				.then((data) => {
					setJsonGraficaGradoAcademico(data);
					// console.log("prueba SE2", data);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/datoSocioEconomicoPorcentaje.json`)
				.then((r) => r.json())
				.then((data) => {
					setJsonGraficaNivelSE(data.datoSocioEconomicoPorcentaje);
					//console.log("prueba SE1", data.datoSocioEconomicoPorcentaje );
					//  console.log("prueba SE", jsonGraficaNivelSE);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});

			fetch(`${process.env.REACT_APP_API_URL}documentos/json/graficaCICandidatos.json`)
				.then((r) => r.json())
				.then((data) => {
					setJsonGraficasCandidatos(data);
					//console.log("GRAFICAS CUESTIONARIO",data, "TAMAÑO", data.length);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/graficaCIPartidos.json`)
				.then((r) => r.json())
				.then((data) => {
					setJsonGraficasPartidos(data);
					////console.log(data);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/graficasAccionesAfirmativas.json`)
				.then((r) => r.json())
				.then((data) => {
					setJsonGraficasAccionesAfirmativas(data);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});
			/*fetch(`${process.env.REACT_APP_API_URL}documentos/json/entidadesDetalleProceso.json`)
				.then((r) => r.json())
				.then((data) => {
					setEntidades(data.entidades);
					//setEntidadesDetalleProceso(data.entidades)
					////console.log(data);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});*/
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/catalogoEstadosSecciones.json`)
				.then((r) => r.json())
				.then((data) => {
					setEntidades(data.estados);
					//setEntidadesDetalleProceso(data.entidades)
					////console.log(data);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});

			fetch(`${process.env.REACT_APP_API_URL}documentos/json/gubernatura.json`)
				.then((r) => r.json())
				.then((data) => {
					setJsonGubernatura(data);
					//setEntidadesDetalleProceso(data.entidades)
					//console.log("GUBERNATURA",data.actoresPoliticos);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/presidenteMunicipal.json`)
				.then((r) => r.json())
				.then((data) => {
					setJsonPresidenteMunicipal(data);
					//setEntidadesDetalleProceso(data.entidades)
					// console.log("Presidentes Municipales", jsonPresidenteMunicipal);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/municipiosEleccion.json`)
				.then((r) => r.json())
				.then((data) => {
					setMunicipios(data.municipios);
					//setEntidadesDetalleProceso(data.entidades)
					//console.log("data municipio", data.municipios);
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});
			fetch(`${process.env.REACT_APP_API_URL}documentos/json/cAccionesAfirmativas.json`)
				.then((r) => r.json())
				.then((data) => {
					setAccionesAfirmativas(data.accionesAfirmativas);
					//setEntidadesDetalleProceso(data.entidades)
					//localStorage.setItem(`${process.env.REACT_APP_LOCAL_JSON_SENADORES_MR}`, JSON.stringify(data));
				});
		}
		/*else {
            setEntidades(jsonEstadosEleccion.estados);
            setSecciones(jsonEstadosEleccion.secciones);
            setGradosAcademicos(jsonGradosAcademicos);
            setTiposCandidaturas(jsonTiposCandidaturas);
            setJsonDMR(jsonDiputadosMR);
            setJsonDRP(jsonDiputadosRP);
            setJsonSMR(jsonSenadoresMR);
        }*/
	}, []);

	/*    useEffect(() => {

       let actoresPoliticosTMP = [];
        if (jsonDMR && jsonDRP) {
            actoresPoliticosTMP = [...jsonDMR.actoresPoliticos];
            jsonDRP.actoresPoliticos.forEach(actorPolitico1 => {
                let actorPolitico2 = jsonDMR.actoresPoliticos.find(actorPolitico2 =>
                    actorPolitico1.idAsociacion == actorPolitico2.idAsociacion &&
                    actorPolitico1.idTipoAsociacion === actorPolitico2.idTipoAsociacion
                );
                if (actorPolitico2 === null || actorPolitico2 === undefined)
                    actoresPoliticosTMP = [...actoresPoliticosTMP, actorPolitico1]
            });
            ////console.log("actoresPoliticosTMP", actoresPoliticosTMP);
            setActoresPoliticos(actoresPoliticosTMP);
            ////console.log("candidatosTMP=>", candidatosTMP);
            setJsonCargados(true);
        }


    }, [jsonDMR, jsonDRP]);*/

	useEffect(() => {
		let actoresPoliticosTMP = [];
		if (jsonSMR) {
			actoresPoliticosTMP = [...jsonSMR.actoresPoliticos];

			////console.log("actoresPoliticosTMP", actoresPoliticosTMP);
			setActoresPoliticos(actoresPoliticosTMP);
			////console.log("candidatosTMP=>", candidatosTMP);
			setJsonCargados(true);
		}
	}, [jsonSMR]);

	useEffect(() => {
		if (jsonDMR) {
			////console.log("actoresPoliticosTMP", actoresPoliticosTMP);
			setCandidatosDMR(jsonDMR.candidatos);
			setHipervinculosDMR(jsonDMR.hipervinculos);
			setRedesSocialesDMR(jsonDMR.redesSociales);
			setCursosCandidatosDMR(jsonDMR.cursosCandidatos);
			setActoresPoliticosDMR(jsonDMR.actoresPoliticos);

			////console.log("candidatosTMP=>", candidatosTMP);
		}
	}, [jsonDMR]);

	useEffect(() => {
		if (jsonDRP) {
			////console.log("actoresPoliticosTMP", actoresPoliticosTMP);
			setCandidatosDRP(jsonDRP.candidatos);
			setHipervinculosDRP(jsonDRP.hipervinculos);
			setRedesSocialesDRP(jsonDRP.redesSociales);
			setCursosCandidatosDRP(jsonDRP.cursosCandidatos);
			setActoresPoliticosDRP(jsonDRP.actoresPoliticos);
			////console.log("candidatosTMP=>", candidatosTMP);
		}
	}, [jsonDRP]);

	useEffect(() => {
		if (jsonSMR) {
			////console.log("actoresPoliticosTMP", actoresPoliticosTMP);
			setCandidatosSMR(jsonSMR.candidatos);
			setHipervinculosSMR(jsonSMR.hipervinculos);
			setRedesSocialesSMR(jsonSMR.redesSociales);
			setCursosCandidatosSMR(jsonSMR.cursosCandidatos);
			setActoresPoliticosSMR(jsonSMR.actoresPoliticos);
			////console.log("candidatosTMP=>", candidatosTMP);
		}
	}, [jsonSMR]);

	useEffect(() => {
		if (jsonSRP) {
			////console.log("actoresPoliticosTMP", actoresPoliticosTMP);
			setCandidatosSRP(jsonSRP.candidatos);
			setHipervinculosSRP(jsonSRP.hipervinculos);
			setRedesSocialesSRP(jsonSRP.redesSociales);
			setCursosCandidatosSRP(jsonSRP.cursosCandidatos);
			setActoresPoliticosSRP(jsonSRP.actoresPoliticos);
			////console.log("candidatosTMP=>", candidatosTMP);
		}
	}, [jsonSRP]);

	useEffect(() => {
		if (jsonPresidentes) {
			////console.log("actoresPoliticosTMP", actoresPoliticosTMP);
			setCandidatosPresidente(jsonPresidentes.candidatos);
			setHipervinculosPresidente(jsonPresidentes.hipervinculos);
			setRedesSocialesPresidente(jsonPresidentes.redesSociales);
			setCursosCandidatosPresidente(jsonPresidentes.cursosCandidatos);
			setActoresPoliticosPresidente(jsonPresidentes.actoresPoliticos);
			////console.log("candidatosTMP=>", candidatosTMP);
		}
	}, [jsonPresidentes]);

	useEffect(() => {
		if (jsonGubernatura) {
			////console.log("actoresPoliticosTMP", actoresPoliticosTMP);
			setCandidatosGubernatura(jsonGubernatura.candidatos);
			setHipervinculosGubernatura(jsonGubernatura.hipervinculos);
			setRedesSocialesGubernatura(jsonGubernatura.redesSociales);
			setCursosCandidatosGubernatura(jsonGubernatura.cursosCandidatos);
			setActoresPoliticosGubernatura(jsonGubernatura.actoresPoliticos);
			//console.log("candidatosGubernatura=>", actoresPoliticosGubernatura);
		}
	}, [jsonGubernatura]);

	useEffect(() => {
		if (jsonPresidenteMunicipal) {
			////console.log("actoresPoliticosTMP", actoresPoliticosTMP);
			setCandidatosPresidenteMunicipal(jsonPresidenteMunicipal.candidatos);
			setHipervinculosPresidenteMunicipal(jsonPresidenteMunicipal.hipervinculos);
			setRedesSocialesPresidenteMunicipal(jsonPresidenteMunicipal.redesSociales);
			setCursosCandidatosPresidenteMunicipal(jsonPresidenteMunicipal.cursosCandidatos);
			setActoresPoliticosPresidenteMunicipal(jsonPresidenteMunicipal.actoresPoliticos);
			//   console.log("candidatosTMP=>", candidatosPresidenteMunicipal);
		}
	}, [jsonPresidenteMunicipal]);

	//Se le manda la información del usuario a todos los componentes hijos
	return (
		<DataContext.Provider
			value={{
				tiposCandidaturas,
				setTiposCandidaturas,
				entidades,
				setEntidades,
				circunscripciones,
				setCircunscripciones,
				gradosAcademicos,
				setGradosAcademicos,
				rangosEdades,
				setRangosEdades,
				generos,
				setGeneros,
				grado,
				setGrado,
				actoresPoliticos,
				setActoresPoliticos,
				actoresPoliticosPresidenteMunicipal,
				setActoresPoliticosPresidenteMunicipal,
				actoresPoliticosGubernatura,
				setActoresPoliticosGubernatura,
				actoresPoliticosDMR,
				setActoresPoliticosDMR,
				actoresPoliticosSMR,
				setActoresPoliticosSMR,
				actoresPoliticosDRP,
				setActoresPoliticosDRP,
				candidatosDMR,
				setCandidatosDMR,
				candidatosDRP,
				setCandidatosDRP,
				candidatosSMR,
				setCandidatosSMR,
				secciones,
				setSecciones,
				hipervinculosDMR,
				setHipervinculosDMR,
				hipervinculosDRP,
				setHipervinculosDRP,
				hipervinculosSMR,
				setHipervinculosSMR,
				redesSocialesDMR,
				setRedesSocialesDMR,
				redesSocialesDRP,
				setRedesSocialesDRP,
				redesSocialesSMR,
				setRedesSocialesSMR,
				cursosCandidatosDMR,
				setCursosCandidatosDMR,
				cursosCandidatosDRP,
				setCursosCandidatosDRP,
				cursosCandidatosSMR,
				setCursosCandidatosSMR,
				jsonDiputadosGraficaPorcentaje,
				jsonSenadoresGraficaPorcentaje,
				jsonGubernaturaGraficaPorcentaje,
				jsonEstadosGraficaPorcentaje,
				jsonPresidentesGrafica,
				jsonEtiquetas,
				jsonLigasConsulta,
				jsonGraficaRangoEdad,
				jsonGraficaSexo,
				jsonGraficaGradoAcademico,
				jsonGraficaNivelSE,
				jsonCargados,
				setJsonCargados,
				jsonGraficasCandidatos,
				setJsonGraficasCandidatos,
				jsonGraficasPartidos,
				setJsonGraficasPartidos,
				jsonGraficasAccionesAfirmativas,
				entidadesDetalleProceso,
				setEntidadesDetalleProceso,
				candidatosGubernatura,
				setCandidatosGubernatura,
				hipervinculosGubernatura,
				setHipervinculosGubernatura,
				redesSocialesGubernatura,
				setRedesSocialesGubernatura,
				cursosCandidatosGubernatura,
				setCursosCandidatosGubernatura,
				candidatosPresidenteMunicipal,
				setCandidatosPresidenteMunicipal,
				hipervinculosPresidenteMunicipal,
				setHipervinculosPresidenteMunicipal,
				redesSocialesPresidenteMunicipal,
				setRedesSocialesPresidenteMunicipal,
				cursosCandidatosPresidenteMunicipal,
				setCursosCandidatosPresidenteMunicipal,
				municipios,
				setMunicipios,
				regresar,
				setRegresar,
				accionesAfirmativas,
				setAccionesAfirmativas,
				jsonPresidentes,
				setJsonPresidentes,
				jsonSRP,
				setJsonSRP,
				candidatosSRP,
				setCandidatosSRP,
				candidatosPresidente,
				setCandidatosPresidente,
				hipervinculosSRP,
				setHipervinculosSRP,
				hipervinculosPresidente,
				setHipervinculosPresidente,
				redesSocialesSRP,
				setRedesSocialesSRP,
				redesSocialesPresidente,
				setRedesSocialesPresidente,
				cursosCandidatosSRP,
				setCursosCandidatosSRP,
				cursosCandidatosPresidente,
				setCursosCandidatosPresidente,
				actoresPoliticosSRP,
				setActoresPoliticosSRP,
				actoresPoliticosPresidente,
				setActoresPoliticosPresidente,
				jsonPresidenteGraficaPorcentaje,
				setJsonPresidenteGraficaPorcentaje,
			}}
		>
			{children}
		</DataContext.Provider>
	);
}
