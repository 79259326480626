import React, { useEffect, useRef } from 'react';
import Header from '../ui/Header'
import Navigation from '../ui/Navigation'
import Footer from '../ui/Footer'
import { Tabs } from 'antd';
import Glosario from '../ui/Glosario';
import Informate from '../ui/Informate';
import Preguntas from '../ui/Preguntas';
import { Link } from "react-router-dom";

const useMountEffect = fun => useEffect(fun, []);

const PreguntasFrecuentes = (props) => {

    const contenedorViewRef = useRef(null);
    const executeScroll = () => contenedorViewRef.current.scrollIntoView();
    useMountEffect(executeScroll);

    return (
        <div ref={contenedorViewRef}>
            <Header />
            <Navigation />

            <section id="preguntas-frecuentes">
                <div className="container espaciador-top">
                    <Tabs tabPosition="left" className="tabpanel-rosa">
                        <Tabs.TabPane tab="Preguntas frecuentes" key="1">
                        <Preguntas {...props} />
                        </Tabs.TabPane>
                      {/*  <Tabs.TabPane tab="Glosario" key="2">
                            <Glosario {...props} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Infórmate para votar" key="3">
                        <Informate {...props} />
                         </Tabs.TabPane>*/}
                        <Tabs.TabPane
                            tab={<Link to="/">Volver a la búsqueda</Link>} 
                            key="4" 
                            disabled
                        />
                    </Tabs>
                </div>
            </section>

            <Footer />
        </div>
    );

}

export default PreguntasFrecuentes;