import React, {useEffect, useRef} from 'react';
import Header from '../ui/Header';
import Navigation from '../ui/Navigation';
import Footer from '../ui/Footer';
import ConsultaPresidente from '../ui/ConsultaPresidente';

const useMountEffect = (fun) => useEffect(fun, []);

const Presidente = (props) => {
	const contenedorViewRef = useRef(null);
	const executeScroll = () => contenedorViewRef.current.scrollIntoView();
	useMountEffect(executeScroll);

	return (
		<div ref={contenedorViewRef}>
			<Header />
			<Navigation />
			<ConsultaPresidente />
			<Footer />
		</div>
	);
};

export default Presidente;
