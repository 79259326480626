import React, { useEffect, useRef } from 'react';
import Header from '../ui/Header'
import Navigation from '../ui/Navigation'
import Footer from '../ui/Footer'
import ConsultaPresidenteMunicipal from '../ui/ConsultaPresidenteMunicipal'

const useMountEffect = fun => useEffect(fun, []);

const PresidenteMunicipal = (props) => {

    const contenedorViewRef = useRef(null);
    const executeScroll = () => contenedorViewRef.current.scrollIntoView();
    useMountEffect(executeScroll);

    return (
        <div ref={contenedorViewRef}>
            <Header />
            <Navigation />
            <ConsultaPresidenteMunicipal />
            <Footer />
        </div>
    );
    

}

export default PresidenteMunicipal;