import React from "react";
import LogoFacebook from '../../assets/img/FB-circle.svg';
import LogoTwitter from '../../assets/img/X-circle.svg';
import LogoYouTube from '../../assets/img/YT-circle.svg';
import LogoInstagram from '../../assets/img/IG-circle.svg';
import LogoTikTok from '../../assets/img/TT-circle.svg';

const Header = (props) => {
    return (
        <div id="ligas-interes" className="container">
            <div className="row">
                <div className="col-xs-7 col-sm-4 col-md-3">
                    <div className="social-icons social-icons-colored-hover">
                        <ul>
                            <li className="social-facebook"><a href="https://www.facebook.com/INEMexico/"><img src={LogoFacebook} height={40} width={40} /></a></li>
                            <li className="social-twitter"><a href="https://twitter.com/INEMexico"><img src={LogoTwitter} height={40} width={40} /></a></li>
                            <li className="social-youtube"><a href="https://www.youtube.com/user/IFETV"><img src={LogoYouTube} height={40} width={40} /></a></li>
                            <li className="social-instagram"><a href="https://www.instagram.com/inemexico/?hl=es"><img src={LogoInstagram} height={40} width={40} /></a></li>
                            <li className="social-tiktok"><a href="https://www.tiktok.com/@inemexico?lang=es"><img src={LogoTikTok} height={40} width={40} /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-xs-5 col-sm-8 col-md-9">
                    <div className="ligas-header">
                        <ul className="nav navbar-nav">
                            <a href="https://portal.ine.mx/agenda-consejeros-electorales/" target="_blank">
                                <i className="fa fa-calendar hidden-lg"></i>
                                <span className="hidden-xs">Agenda Consejeros (as)</span>
                            </a>
                            <a href="https://portal.ine.mx/transmisiones-en-vivo/" target="_blank">
                                <i className="fa fa-video-camera hidden-lg"></i>
                                <span className="hidden-xs">Transmisiones en vivo</span>
                            </a>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Header;