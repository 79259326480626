import React, {useState, useEffect, createContext} from 'react';

export const BusquedaContext = createContext();

export default function BusquedaProvider(props) {
	const {children} = props;

	const [tipoCandidatura, setTipoCandidatura] = useState(null);
	const [descTipoCandidatura, setDescTipoCandidatura] = useState(null);
	const [entidad, setEntidad] = useState(null);
	const [circunscripcion, setCircunscripcion] = useState(null);
	const [seccionElectoral, setSeccionElectoral] = useState(null);
	const [estadosCircunscripcion, setEstadosCircunscripcion] = useState(null);
	const [gradoAcademico, setGradoAcademico] = useState(null);
	const [rangoEdad, setRangoEdad] = useState(null);
	const [genero, setGenero] = useState('');
	const [actorPolitico, setActorPolitico] = useState('');
	const [consultar, setConsultar] = useState(false);
	const [limpiarFiltros, setLimpiarFiltros] = useState(true);
	const [paginacion, setPaginacion] = useState({position: ['bottomCenter'], pageSize: 10, showSizeChanger: false, current: 1});
	const [paginacionSup, setPaginacionSup] = useState({position: ['bottomCenter'], pageSize: 10, showSizeChanger: false, current: 1});
	const [tipoCandidatosBusqueda, setTipoCandidatosBusqueda] = useState('P');
	const [tabSeleccionada, setTabSeleccionada] = useState('1');
	const [cargadoExcel, setCargandoExcel] = useState(false);
	const [nombrePropietario, setNombrePropietario] = useState(null);
	const [nombreSuplente, setNombreSuplente] = useState(null);
	const [entidadDetalle, setEntidadDetalle] = useState(null);
	const [descEntidadDetalle, setDescEntidadDetalle] = useState(null);
	const [deshabilitar, setDeshabilitar] = useState(false);
	const [deshabilitarSeccion, setDeshabilitarSeccion] = useState(true);
	const [deshabilitarCircunscripcion, setDeshabilitarCircunscripcion] = useState(true);
	const [idTipoCandidatura, setIdTipoCandidatura] = useState(null);
	const [municipio, setMunicipio] = useState(null);

	useEffect(() => {
		console.log('CHILDREN', children);
		//console.log("Entro al provider");
	}, []);
	//Se le manda la información del usuario a todos los componentes hijos
	return (
		<BusquedaContext.Provider
			value={{
				tipoCandidatura,
				setTipoCandidatura,
				descTipoCandidatura,
				setDescTipoCandidatura,
				entidad,
				setEntidad,
				circunscripcion,
				setCircunscripcion,
				seccionElectoral,
				setSeccionElectoral,
				estadosCircunscripcion,
				setEstadosCircunscripcion,
				gradoAcademico,
				setGradoAcademico,
				rangoEdad,
				setRangoEdad,
				genero,
				setGenero,
				actorPolitico,
				setActorPolitico,
				consultar,
				setConsultar,
				paginacion,
				setPaginacion,
				tipoCandidatosBusqueda,
				setTipoCandidatosBusqueda,
				tabSeleccionada,
				setTabSeleccionada,
				paginacionSup,
				setPaginacionSup,
				cargadoExcel,
				setCargandoExcel,
				limpiarFiltros,
				setLimpiarFiltros,
				nombrePropietario,
				setNombrePropietario,
				nombreSuplente,
				setNombreSuplente,
				entidadDetalle,
				setEntidadDetalle,
				descEntidadDetalle,
				setDescEntidadDetalle,
				deshabilitar,
				setDeshabilitar,
				deshabilitarSeccion,
				setDeshabilitarSeccion,
				deshabilitarCircunscripcion,
				setDeshabilitarCircunscripcion,
				idTipoCandidatura,
				setIdTipoCandidatura,
				municipio,
				setMunicipio,
			}}
		>
			{children}
		</BusquedaContext.Provider>
	);
}
