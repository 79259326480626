import React, {useEffect, useState, useRef} from 'react';
import {useParams} from 'react-router';
import {/*Icon,*/ LeftOutlined} from '@ant-design/icons';
import {Table, Row, Col, Button, Card} from 'antd';

import {Link} from 'react-router-dom';

import useBusquedaContext from '../../context/useBusquedaContext';
import Spin from './Spin';
import useDataContext from '../../context/useDataContext';

const ConsultaDiputados = (props) => {
	const [dataCandidatosDMR, setDataCandidatosDMR] = useState([]);
	const [dataCandidatosDRP, setDataCandidatosDRP] = useState([]);
	const [consultar, setConsultar] = useState(true);

	const {actoresPoliticos, actoresPoliticosDMR, actoresPoliticosDRP, candidatosDMR, candidatosDRP, jsonCargados} = useDataContext();

	let {idAsociacion, tipoAsociacion} = useParams();

	const {tipoCandidatosBusqueda} = useBusquedaContext();

	useEffect(() => {
		//buscar('7', idAsociacion, tipoAsociacion);
		buscar('4', idAsociacion, tipoAsociacion);
	}, [candidatosDMR]);

	useEffect(() => {
		//buscar('8', idAsociacion, tipoAsociacion);
		buscar('5', idAsociacion, tipoAsociacion);
	}, [candidatosDRP]);

	const buscar = (tipoCandidatura, asociacion, tipoAsociacion) => {
		let candidatosFiltrado;
		if (tipoCandidatura === '4') candidatosFiltrado = candidatosDMR;
		if (tipoCandidatura === '5') candidatosFiltrado = candidatosDRP;

		candidatosFiltrado = candidatosFiltrado.filter((candidato) => candidato.tipoCandidato === tipoCandidatosBusqueda);

		if (asociacion != null && asociacion != undefined && tipoAsociacion != null && tipoAsociacion != null)
			candidatosFiltrado = candidatosFiltrado.filter((candidato) => candidato.idAsociacion == asociacion && candidato.tipoAsociacion == tipoAsociacion);
		else candidatosFiltrado = [];

		if (tipoCandidatura === '4') setDataCandidatosDMR(candidatosFiltrado);
		if (tipoCandidatura === '5') setDataCandidatosDRP(candidatosFiltrado);
		setConsultar(false);
	};

	const columnsBase = [
		{
			title: 'Actor Político',
			align: 'center',
			dataIndex: 'idAsociacion',
			key: 'idAsociacion',
			render: (_, record) => {
				let actorPolitico = actoresPoliticos.find(
					(actorPolitico) => actorPolitico.idAsociacion == record.idAsociacion && actorPolitico.idTipoAsociacion == record.tipoAsociacion,
				);
				if (actorPolitico != null)
					return (
						<img
							key={`emb-${record.idCandidato}-${actorPolitico.idAsociacion}-${actorPolitico.idTipoAsociacion}`}
							src={`${process.env.REACT_APP_API_URL}img/${actorPolitico.emblema}`}
							className={
								actorPolitico.idTipoAsociacion == 3 || actorPolitico.idTipoAsociacion == 4 || actorPolitico.idTipoAsociacion == 5
									? 'coalicion'
									: ''
							}
							style={
								actorPolitico.idTipoAsociacion == 3 || actorPolitico.idTipoAsociacion == 4 || actorPolitico.idTipoAsociacion == 5
									? {width: '105px', textAlign: 'center', margin: 'auto', display: 'block'}
									: {width: '35px', textAlign: 'center', margin: 'auto', display: 'block'}
							}
						/>
					);
				return <p></p>;
			},
			width: '15%',
		},
		{
			render: (_, record) => {
				return (
					<div /*style={{ textAlign: "left" }}*/>
						<div className="div-FConsulta">
							<div className="FConsulta">¡Consulta!</div>
						</div>
						<Link
							key={record.idCandidato}
							to={{
								pathname: `/detalleCandidato/${record.idCandidato}/${record.idTipoCandidatura}`,
								state: {idCandidato: record.idCandidato, idTipoCandidatura: record.idTipoCandidatura},
							}}
							target="_blank"
							//onClick={() => setConsultar(true)}
						>
							{_}
						</Link>
					</div>
				);
			},

			title: tipoCandidatosBusqueda === 'P' ? 'Propietaria/o' : 'Suplente',
			align: 'center',
			dataIndex: 'nombreCandidato',
			key: 'nombreCandidato',
			width: '28%',
			//sorter: (a, b) => (a.nombreCandidato.first > b.nombreCandidato.first ? 1 : -1),
		},
	];

	const columnsBaseDMR = [
		{
			title: 'Entidad',
			dataIndex: 'nombreEstado',
			align: 'center',
			key: 'nombreEstado',
			//sorter: (a, b) => (a.idEstadoEleccion.first > b.idEstadoEleccion.first ? 1 : -1),
			//fixed: 'left',
			width: '15%',
		},
		{
			title: 'Distrito',
			align: 'center',
			dataIndex: 'nombreDistrito',
			key: 'nombreDistrito',
			width: '14%',
		},
		...columnsBase,
	];

	const columnsBaseDRP = [
		{
			title: 'Circunscripción',
			dataIndex: 'idCircunscripcionEleccion',
			key: 'idCircunscripcionEleccion',
			align: 'center',
			width: '15%',
		},
		{
			title: 'No. Lista',
			dataIndex: 'numListaDiputado',
			key: 'numListaDiputado',
			align: 'center',
			width: '14%',
		},
		...columnsBase,
	];

	const columnSuplente = {
		render: (_, record) => {
			return (
				<div /*style={{ textAlign: "left" }}*/>
					<div className="div-FConsulta">
						<div className="FConsulta">¡Consulta!</div>
					</div>
					<Link
						key={record.idCandidato}
						to={{
							pathname: `/detalleCandidato/${record.idCandidatoSup}/${record.idTipoCandidatura}`,
							state: {idCandidato: record.idCandidatoSup, idTipoCandidatura: record.idTipoCandidatura},
						}}
						target="_blank"
						//onClick={() => setConsultar(true)}
					>
						{_}
					</Link>
				</div>
			);
		},
		title: tipoCandidatosBusqueda === 'P' ? 'Suplente' : 'Propietaria/o',
		align: 'center',
		dataIndex: 'nombreSuplente',
		key: 'nombreSuplente',
		width: '28%',
	};

	const columnsDMR = [...columnsBaseDMR, columnSuplente];

	const columnsDRP = [...columnsBaseDRP, columnSuplente];

	return (
		<>
			<div className="container">
				<div className="clearfix ">&nbsp;</div>
				<div className="row">
					<div className=" col-xs-12 ">
						<div className="breadcrumb" style={{backgroundColor: 'white'}}>
							<li>Inicio</li>
							<li> Elecciones </li>
							<li>Candidatas y Candidatos, Conóceles</li>
							<li className="active">Ficha candidatura</li>
							<div className="divider"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<Card className="cardResponsabilidadUTTyPDP">
						<Row>
							<Col>
								<span className="alineacion-justificada">
									<span className="gris2-mensaje-consulta">
										{' '}
										Consulta la información
										<span className="negro2-mensaje-consulta"> proporcionada de manera obligatoria</span> por las y los candidatos que
										participan en las contiendas electorales 2024.
										<span className="negro2-mensaje-consulta"> La información es responsabilidad de los actores políticos. </span>
										El Instituto únicamente apoya para su difusión.
									</span>
								</span>
							</Col>
						</Row>
					</Card>
				</div>
			</div>
			<div className="container">
				<div className="clearfix ">&nbsp;</div>
				<Row>
					<Col xs={{span: 12}}>
						<div style={{display: 'flex', justifyContent: 'flex-start'}}>
							<Link to="/">
								<Button key="regresar" type="primary" className="secondary-button">
									<LeftOutlined />
									Nueva búsqueda
								</Button>
							</Link>
						</div>
					</Col>
					<Col xs={{span: 12}}>
						<div style={{display: 'flex', justifyContent: 'flex-end'}}>
							<Link to="/preguntasFrecuentes">
								<Button key="regresar" type="primary" className="descarga-button">
									Preguntas frecuentes
								</Button>
							</Link>
						</div>
					</Col>
				</Row>
			</div>
			{jsonCargados ? (
				<div>
					<div className="container">
						{
							//console.log("datos", dataCandidatosDMR)
						}
						{dataCandidatosDMR && dataCandidatosDMR.length > 0 && (
							<div id="contenedor-resultados" className="col-xs-12 espaciador-top">
								<div className="row">
									<div className="col-xs-12">
										<p id="titulo-resultados" className="info">
											Resultados para diputaciones de mayoría relativa:
										</p>
									</div>
								</div>
								<Table
									rowKey={(record) => record.idCandidato}
									columns={columnsDMR}
									dataSource={dataCandidatosDMR}
									pagination={false}
									scroll={{x: 900}}
									loading={{
										spinning: consultar,
										indicator: <Spin spinning={consultar} />,
									}}
								/>
							</div>
						)}
						{tipoAsociacion == 1 && (
							<div>
								<div id="contenedor-resultados" className="col-xs-12 espaciador-top">
									<div className="row">
										<div className="col-xs-12">
											<p id="titulo-resultados" className="info">
												Resultados para diputaciones de representación proporcional:
											</p>
										</div>
									</div>
									<Table
										rowKey={(record) => record.idCandidato}
										columns={columnsDRP}
										dataSource={dataCandidatosDRP}
										pagination={false}
										scroll={{x: 900}}
										loading={{
											spinning: consultar,
											indicator: <Spin spinning={consultar} />,
										}}
									/>
								</div>
								<div className="clearfix">&nbsp;</div>
							</div>
						)}
					</div>
				</div>
			) : (
				<div>
					<div id="imprimir" className="container candidatos">
						<div className="clearfix">&nbsp;</div>
						<div id="contenedor-cargando" className="col-xs-12 text-center">
							<Spin spinning={true} />
						</div>
						<div className="clearfix">&nbsp;</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ConsultaDiputados;
