import React from 'react';
import {Row} from 'antd';
import useDataContext from '../../context/useDataContext';

const Preguntas = (props) => {

	const {
		jsonLigasConsulta
	} = useDataContext();

	return (
		<section id="Preguntas">
			{
				//<Row > gutter={[16, 16]}>
			}
			<Row>
				<div className="TituloPF">
					<p>Preguntas frecuentes</p>
				</div>
			</Row>
			<Row>
				<div className="SubtituloPF">
					<p>1. ¿Qué objetivo tiene el sistema Candidatas y Candidatos, Conóceles?</p>
				</div>
			</Row>
			<Row>
				<div className="TextoPF">
					<p>
						Que la ciudadanía conozca información general de la vida política, laboral y pública de las personas candidatas del Proceso Electoral
						Federal del 2 de junio del 2024, con la finalidad de que cuenten con la información suficiente al momento de emitir su voto.{' '}
					</p>
				</div>
			</Row>
			<Row>
				<div className="SubtituloPF">
					<p>2. ¿Quiénes proporcionan la información del Cuestionario curricular de cada persona candidata? </p>
				</div>
			</Row>
			<Row>
				<div className="TextoPF">
					<p>La información es proporcionada por las personas candidatas contendientes.</p>
				</div>{' '}
			</Row>
			<Row>
				<div className="SubtituloPF">
					{' '}
					<p>3. ¿Puedo compartir la información de las personas candidatas en redes sociales? </p>
				</div>
			</Row>
			<Row>
				<div className="TextoPF">
					<p>
						Sí. Busca el nombre de la persona candidata, elige la red social que deseas usar, y comparte. La información que se encuentra en el
						sistema es pública y puede ser compartida.
					</p>
				</div>
			</Row>
			<Row>
				<div className="SubtituloPF">
					{' '}
					<p>
						4. ¿Es una obligación de la Unidad Técnica de Transparencia y Protección de Datos Personales (UTTyPDP), del INE, publicar el
						Cuestionario curricular de una persona candidata?{' '}
					</p>
				</div>
			</Row>
			<Row>
				<div className="TextoPF">
					{' '}
					<p>
						No. Esta obligación corresponde a las personas candidatas y el Instituto Nacional Electoral, a través de la Unidad Técnica de
						Transparencia y Protección de Datos Personales le corresponde la supervisión y verificación de la captura de contenidos del cuestionario
						curricular.
					</p>
				</div>
			</Row>
			<Row>
				<div className="SubtituloPF">
					{' '}
					<p>5. ¿Cuál es el Aviso de Privacidad de la Información que proporcionan las personas candidatas? </p>
				</div>
			</Row>
			<Row>
				<div className="LinksPF">
					<span style={{color: '#000'}}>Puedes consultar el </span>{' '}
					<a href={jsonLigasConsulta && jsonLigasConsulta.avisoPrivacidad} target="_blank">
						{' '}
						Aviso de Privacidad del Sistema Candidatas y Candidatos, Conóceles 2023-2024
					</a>
				</div>
			</Row>
			<Row>
				<div className="SubtituloPF">
					{' '}
					<p>6. ¿A qué cargos se están postulando las personas candidatas a nivel federal? </p>
				</div>
			</Row>
			<Row style={{marginTop: '-25px'}}>
				<div className="LinksPF">
					{' '}
					<span style={{color: '#000'}}>
						<br />- Presidencia de la República
						<br />- Senaduría Federal de Mayoría Relativa
						<br />- Senaduría Federal de Representación Proporcional
						<br />- Diputación Federal de Mayoría Relativa
						<br />- Diputación Federal de Representación Proporcional{' '}
					</span>{' '}
				</div>
			</Row>
			{/* 			<Row>
				<div className="SubtituloPF">
					{' '}
					<p>7. ¿Cómo se integra la Cámara de Senadores?</p>
				</div>
			</Row>
			<Row>
				<div className="LinksPF">
					<span style={{color: '#000'}}>
						La Cámara de Senadores se integra por 128 senadoras y senadores, de los cuales:
						<br />• 2 serán elegidos en cada entidad federativa según el principio de votación mayoritaria relativa.
						<br />• 1 de cada entidad federativa será asignado por el principio de primera minoría.
						<br />• 32 por el principio de representación proporcional (una lista a nivel nacional).{' '}
					</span>{' '}
				</div>
			</Row> */}
		</section>
	);
};

export default Preguntas;
