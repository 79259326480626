import React from "react";
import Header from '../ui/Header'
import Navigation from '../ui/Navigation'
import Bienvenida from '../ui/Bienvenida'
import Tema from '../ui/Tema'
import Footer from '../ui/Footer'
import Error404 from '../ui/Error404'


const Home = (props) => {

    return (
        <div>
            <Header />
            <Navigation />
            <Bienvenida />
            <Tema />
            <Error404 />
            <Footer />
        </div>

    );

}

export default Home;